import { Currency } from "../models";

export const CURRENCY_ID_PER_CURRENCY: { [key: string]: number } = {
    EUR: 1,
    USD: 2,
    JPY: 3,
    GBP: 4,
    CHF: 8,
    CAD: 24,
}

export const CURRENCY_PER_CURRENCY_IDS: { [key: number]: Currency } = {
    1: 'EUR',
    2: 'USD',
    3: 'JPY',
    4: 'GBP',
    8: 'CHF',
    24: 'CAD',
}


export const getCurrencyId = (currency: string): number => CURRENCY_ID_PER_CURRENCY[currency] || 1;

export const getCurrency = (currencyId: number): Currency => CURRENCY_PER_CURRENCY_IDS[currencyId] || 'EUR'