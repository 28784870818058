import styled, { css } from "styled-components";

export const StepLabel = styled.label`
`;

export const StepperWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
`;


export const StepperItem = styled.div<{ active?: boolean, complete?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    color: rgba(0,0,0,0.7);
    font-weight: 400;
    font-size: 1em;
    text-align: center;

    &::before {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: -50%;
        z-index: 2;
    }


    &::after {
        position: absolute;
        content: "";
        border-bottom: 3px solid #ccc;
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 2;
    }

    &:first-child::before{
        content: none
    }

    &:last-child::after{
        content: none
    }

    ${props => props.active && css`
            font-weight: 600;
            color: #F7C200;
        `
    }

    ${props => props.complete && css`
        font-weight: 600;
        color: #F7C200;


        & ${StepCounter} {
            border-color: #F7C200;
        }

        &::after{
             position: absolute;
            content: "";
            border-bottom: 3px solid #F7C200;
            width: 100%;
            top: 20px;
            left: 50%;
            z-index: 3;
        }
        `
    }
`;


export const StepCounter = styled.div`
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    border: 3px solid #ccc;
    margin-bottom: 6px;
    font-size: 1em;
`;