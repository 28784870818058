import { getRateCategory, getTax } from '../services/clientService';
import { Currency } from './currency';
import { Locale } from './locale';
import { TariffCategory } from './tariffCategory';
import { Contact } from './user';

export type ClientType = 'corporation' | 'person';

export interface Address {
	zip: string;
	city: string;
	country: string;
	street: string;
}

export interface Client extends ClientDTO {
	id: number;
	deliveryAddressId: number;
	invoicingAddressId: number;
	language: Locale;
	name: string;
	email: string;
	country: string;
	siren?: string;
	ape?: string;
	website?: string;
	invoicingAddress: Address;
	deliveryAddress: Address;
	contact?: Contact;
	vat?: string;
	legalStatus?: string;
	rateCategory?: {
		id: number;
		name: TariffCategory;
		currency: Currency;
		taxRate: number;
		includesTaxes: boolean;
	}
	customFields: ClientCustomFields;
}

export interface ClientDTO {
	id?: number;
	name?: string;
	language?: Locale;
	email?: string;
	country?: string;
	siren?: string;
	ape?: string;
	website?: string;
	invoicingAddress: Address;
	deliveryAddress: Address;
	contact?: Contact;
	vat?: string;
	legalStatus?: string;
	customFields: ClientCustomFields;
}

export interface ClientCustomFields {
	'company-unsold-items-reselling': {
		id?: number,
		value?: boolean,
	},
	'company-eco-friendly-delivery': {
		id?: number,
		value?: boolean,
	},
	'company-preferred-delivery-day': {
		id?: number,
		value: number[],
	},
	'company-trade-type': {
		id?: number,
		value?: number,
	},
	'company-status': {
		id?: number,
		value?: number,
	},
	'company-national-id': {
		id?: number,
		value?: string,
	},
	'company-glassmaker': {
		id?: number,
		value?: number,
	},
	'company-payment-method': {
		id?: number,
		value?: number,
	},
	'company-sign': {
		id?: number,
		value?: string,
	},
	'company-language': {
		id?: number,
		value?: number,
	},
	'company-glassmaker-membership-number': {
		id?: number,
		value?: string,
	},
	'company-shipping-company': {
		id?: number,
		value: number[],
	},
	'company-registration-date': {
		id?: number,
		value?: string,
	},
	'company-currency': {
		id?: number,
		value?: number,
	},
	'company-creation-date': {
		id?: number,
		value?: string,
	},
	'company-buying-group': {
		id?: number,
		value?: number,
	},
	'company-buying-group-membership-number': {
		id?: number,
		value?: string,
	},
	'company-billing-email': {
		id?: number,
		value?: string,
	},
	'company-gc-mandate-id': {
		id?: number,
		value?: string,
	}
}

export const initClientDTO = (): ClientDTO => ({
	invoicingAddress: {
		zip: '',
		city: '',
		country: '',
		street: '',
	},
	deliveryAddress: {
		zip: '',
		city: '',
		country: '',
		street: '',
	},
	customFields: {
		'company-unsold-items-reselling': {
		},
		'company-eco-friendly-delivery': {
		},
		'company-preferred-delivery-day': {
			value: [],
		},
		'company-trade-type': {
		},
		'company-status': {
		},
		'company-national-id': {
		},
		'company-glassmaker': {
		},
		'company-payment-method': {
		},
		'company-sign': {
		},
		'company-language': {
		},
		'company-glassmaker-membership-number': {
		},
		'company-shipping-company': {
			value: [],
		},
		'company-registration-date': {
		},
		'company-currency': {
		},
		'company-creation-date': {
		},
		'company-buying-group': {
		},
		'company-buying-group-membership-number': {
		},
		'company-billing-email': {
		},
		'company-gc-mandate-id': {
		}
	},
});



// export interface ClientDTO {
// 	id: number;
// 	name: string;
// 	email: string;
// 	website: string;
// 	legal_france: {
// 		siret: string;
// 		ape_naf_code: string;
// 		vat: string;
// 		company_type: string;
// 	}
// 	created: Date;
// 	_embed: {
// 		invoicing_address: {
// 			id: number;
// 			name: string;
// 			address_line_1: string;
// 			address_line_2: string;
// 			postal_code: string;
// 			city: string;
// 			country: string;
// 		}
// 		delivery_address: {
// 			id: number;
// 			name: string;
// 			address_line_1: string;
// 			address_line_2: string;
// 			postal_code: string;
// 			city: string;
// 			country: string;
// 		}
// 	}
// }

export const initClient = (options?: Partial<Client>): Client => ({
	...initClientDTO(),
	id: 0,
	name: '',
	language: 'fr-FR',
	email: '',
	country: '',
	siren: '',
	ape: '',
	invoicingAddressId: 0,
	deliveryAddressId: 0,
	...options,
});

export const initClientFromSellsyData = async (client: any, token: string): Promise<Client> => {
	const { custom_fields, delivery_address, invoicing_address } = client._embed;

	return getRateCategory(client.rate_category_id, token)
		.then((res) => res.json())
		.then((rateCategoryData) => {
			return getTax(rateCategoryData.tax_id, token)
				.then((res) => res.json())
				.then((taxData) => {
					return {
						id: client.id,
						name: client.name,
						language: custom_fields.find((field: any) => field.name === 'company-language').value,
						website: client.website,
						email: client.email,
						country: invoicing_address.country_code,
						siren: client.legal_france.siren,
						ape: client.legal_france.ape_naf_code,
						vat: client.legal_france.vat,
						legalStatus: client.legal_france.company_type,
						invoicingAddressId: client.invoicing_address_id,
						deliveryAddressId: client.delivery_address_id,
						invoicingAddress: {
							zip: invoicing_address.postal_code,
							city: invoicing_address.city,
							country: invoicing_address.country_code,
							street: invoicing_address.address_line_1,
						},
						deliveryAddress: {
							zip: delivery_address.postal_code,
							city: delivery_address.city,
							country: delivery_address.country_code,
							street: delivery_address.address_line_1,
						},
						rateCategory: {
							id: rateCategoryData.id,
							name: rateCategoryData.label,
							currency: rateCategoryData.currency,
							taxRate: taxData.rate,
							includesTaxes: rateCategoryData.includes_taxes,
						},
						customFields: custom_fields.reduce((acc: any, field: any) => {
							acc[field.name] = {
								id: field.id,
								value: field.value,
							};
							return acc;
						}
							, {}),
					}
				})
		});
}