import { Button } from 'aaastyle';
import { Link } from 'react-router-dom';
import styled from "styled-components";

export const MainButton = styled(Button)`
    border-radius: 4px;
	width: 100%;
	margin: 0;
	height: unset;
    font-size: 1em;
	min-width: 0;
	font-weight: 600;
`;


export const MainButtonLink = styled(Link)`
	text-decoration: none;
	background-color: ${({ theme }) => theme.mainColor};
	border-radius: 4px;
	width: 100%;
	margin: 0;
	height: unset;
	font-size: 1em;
	min-width: 0;
	font-weight: 600;
	color: ${({ theme }) => theme.blackColor};
	padding: 12px 24px;
	text-align: center;
	box-sizing: border-box;
	white-space: nowrap;
`;

export const IconButton = styled.button`
	border-radius: 200px;
	width: fit-content;
	height: fit-content;
	padding: 0;
	margin: 0;
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`;