import { ComponentPropsWithRef } from 'react';
import icons from '../../assets/icons/icons.svg';

export type IconName = 'plus' | 'minus' | 'check-circle' | 'error' | 'file-download' | 'receipt' | 'shipping' | 'paid' | 'check' | 'search' | 'email' | 'website' | 'back-arrow' | 'close' | 'home' | 'account-circle' | 'cart' | 'favorite' | 'favorite-border';

interface IconProps extends Omit<ComponentPropsWithRef<'svg'>, 'name'> {
	name: IconName;
	color?: string;
	size?: number;
}

const Icon = ({ name, color, size, ref, ...rest }: IconProps) => (
	<svg ref={ref} {...rest} className={`icon icon-${name}`} fill={color || '#000000'} width={size || 48} height={size || 48}>
		<use xlinkHref={`${icons}#icon-${name}`} />
	</svg>
);
export default Icon;
