import { useClickOutside } from 'aaastyle';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { OrderInfo } from '../../../models/orderInfo';
import { MainButton } from '../../../styles/buttons';
import { Container, StyledBox } from '../../../styles/containers';
import { RegisterTextInput, RequiredPlaceholder } from '../../../styles/inputs/register';
import { H2, HTLabel, HTPrice, Muted, StyledSpan } from '../../../styles/texts';
import { InputLabel, SectionTitle } from '../../../styles/texts/register';
import { getCitiesByZip } from '../../../utils/getCitiesByZip';
import { useUserAuth } from '../../../utils/hooks';
import { useSessionSelector } from '../../../utils/hooks/useSessionSelector';
import { CitiesDropdown } from '../../Account/Informations/style';
import WindowLoading from '../../other/WindowLoading';
import { AddressP, AddressSpan, LineSeparator } from '../style';
import { DeliveryField, DeliveryForm, EditBillingAddressP } from './style';

interface DeliveryProps {
	orderInfo: OrderInfo;
	setOrderInfo: (orderInfo: OrderInfo) => void;
}

const Delivery = ({ orderInfo, setOrderInfo }: DeliveryProps) => {
	const { isMobile } = useSessionSelector();
	const { t, i18n } = useTranslation();
	const language = i18n.language;
	const { user } = useUserAuth();
	const [editDeliveryAddress, setEditDeliveryAddress] = useState<boolean>(false);
	const navigate = useNavigate();
	const [cityChoices, setCityChoices] = useState<string[]>([]);
	const [showCityChoices, setShowCityChoices] = useState<boolean>(false);
	const cityFieldRef = useRef<HTMLDivElement>(null);
	const formRef = useRef<HTMLFormElement>(null);
	const timeoutRef = useRef<NodeJS.Timeout>();
	useClickOutside(cityFieldRef, () => setShowCityChoices(false));

	useEffect(() => {
		if (Object.values(orderInfo.cartRows).length === 0) {
			navigate('/cart', { replace: true });
		}
	}, [orderInfo]);

	const handleZipChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (timeoutRef.current) clearTimeout(timeoutRef.current);
		setTimeout(() => {
			const zip = e.target.value;
			getCitiesByZip(zip, orderInfo.invoicingAddress.country).then((cities) => {
				setCityChoices([...cities]);
				const city = formRef.current?.elements.namedItem('deliveryCity') as HTMLInputElement;
				if (city && cities.length > 0) city.value = cities[0];
				setShowCityChoices(true);
			});
		}, 1000);
	};

	const handleSubmit = () => {
		if(formRef.current) {
			const formData = new FormData(formRef.current);
			const formObject = Object.fromEntries(formData.entries());
			setOrderInfo({
				...orderInfo,
				deliveryAddress: {
					...orderInfo.deliveryAddress,
					zip: formObject.shippingZip.toString(),
					city: formObject.shippingCity.toString(),
					street: formObject.shippingAddress.toString(),
				},
			});
		}
		navigate('../confirmation');
	};

	return (
		<>
			{user === undefined && <WindowLoading />}
			{user === null && <Navigate replace to='/login' />}
			{user && user.currentCompany && (
				<>
					<H2>{t('delivery.title')}</H2>
					<Container width='100%' flexWrap='wrap' gap={isMobile ? 16 : 60} justify='space-between'>
						<Container flex='0 1 500px' direction='column' gap={32}>
							<Container direction='column'>
								<SectionTitle>{t('delivery.billingAddress')}</SectionTitle>
								<Container direction='column' gap={8}>
									<AddressSpan>{user.currentCompany.name}</AddressSpan>
									<AddressP>{t('global.formattedAddress', { street: user.currentCompany?.invoicingAddress.street, zip: user.currentCompany?.invoicingAddress.zip, city: user.currentCompany?.invoicingAddress.city })}</AddressP>
									<Container direction='column'>
										<AddressSpan>{user.currentCompany.country === 'FR' ? t('delivery.siren', { siren: user.currentCompany.siren }) : t('delivery.nationalId', { nationalId: user.currentCompany.customFields['company-national-id']?.value })}</AddressSpan>
										<AddressSpan>{t('delivery.vat', { vat: user.currentCompany.vat })}</AddressSpan>
									</Container>
								</Container>
								<EditBillingAddressP>
									<Muted>{t('delivery.editBillingAddressMessage.text')}</Muted>
									<Muted as={Link} to='/profile/company'>
										{t('delivery.editBillingAddressMessage.link')}
									</Muted>
								</EditBillingAddressP>
							</Container>
							<Container direction='column'>
								<SectionTitle>{t('delivery.shippingAddress')}</SectionTitle>
								{editDeliveryAddress ? (
									<Container direction='column' gap={8}>
										<AddressSpan>{user.currentCompany.name}</AddressSpan>
										<DeliveryForm ref={formRef}>
											<Container gap={8}>
												<DeliveryField flex='0 0 180px'>
													<InputLabel>{t('clientRegister.zipCode')}</InputLabel>
													<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
													<RegisterTextInput autoComplete='off' autoCorrect='off' spellCheck='false' type='text' name='shippingZip' defaultValue={orderInfo.deliveryAddress.zip} onChange={handleZipChange} placeholder={t('clientRegister.placeholders.zipCode')} />
												</DeliveryField>
												<DeliveryField ref={cityFieldRef}>
													<InputLabel>{t('clientRegister.city')}</InputLabel>
													<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
													<RegisterTextInput autoComplete='off' autoCorrect='off' spellCheck='false' onFocus={() => setShowCityChoices(true)} defaultValue={orderInfo.deliveryAddress?.city} type='text' name='shippingCity' placeholder={t('clientRegister.placeholders.city')} />
													{cityChoices.length > 0 && showCityChoices && (
														<CitiesDropdown>
															{cityChoices.map((city, index) => (
																<Container
																	onClick={() => {
																		(formRef.current?.elements.namedItem('shippingCity') as HTMLInputElement).value = city;
																		setShowCityChoices(false);
																	}}
																	key={index}>
																	{city}
																</Container>
															))}
														</CitiesDropdown>
													)}
												</DeliveryField>
											</Container>

											<DeliveryField>
												<InputLabel>{t('clientRegister.address')}</InputLabel>
												<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
												<RegisterTextInput autoComplete='off' autoCorrect='off' spellCheck='false' type='text' defaultValue={orderInfo.deliveryAddress.street} name='shippingAddress' placeholder={t('clientRegister.placeholders.address')} />
											</DeliveryField>
										</DeliveryForm>
										<Container direction='column'>
											<AddressSpan>{t('delivery.shippingContact', { firstname: user.firstName, lastname: user.lastName })}</AddressSpan>
											<AddressSpan>{t('delivery.shippingContactPhone', { phone: user.mobilePhone })}</AddressSpan>
										</Container>
									</Container>
								) : (
									<Container direction='column' gap={8}>
										<AddressSpan>{user.currentCompany.name}</AddressSpan>
										<AddressP>{t('global.formattedAddress', { street: user.currentCompany?.deliveryAddress.street, zip: user.currentCompany?.deliveryAddress.zip, city: user.currentCompany?.deliveryAddress.city })}</AddressP>
										<Container direction='column'>
											<AddressSpan>{t('delivery.shippingContact', { firstname: user.firstName, lastname: user.lastName })}</AddressSpan>
											<AddressSpan>{t('delivery.shippingContactPhone', { phone: user.mobilePhone })}</AddressSpan>
										</Container>
									</Container>
								)}
								<Container padding='8px 0' marginTop='16px'>
									{editDeliveryAddress ? (
										<MainButton variant='text' onClick={() => setEditDeliveryAddress(false)}>
											{t('global.cancel')}
										</MainButton>
									) : (
										<MainButton onClick={() => setEditDeliveryAddress(true)}>{t('global.edit')}</MainButton>
									)}
								</Container>
							</Container>
						</Container>
						<Container flex='0 1 500px' direction='column' gap={32}>
							{/* <Container direction='column'>
								<SectionTitle>{t('delivery.estimatedDeliveryDate')}</SectionTitle>
								<Muted>{t('delivery.estimatedDate', { date: new Date().toLocaleDateString(), carrier: customFields && (customFields['company-shipping-company'].values.find((v) => v.id === orderInfo.shippingCompany)?.value.split('_')[1] || '') })} </Muted>
							</Container> */}
							<Container direction='column'>
								<SectionTitle>{t('delivery.summaryArticleCount', { count: orderInfo.totalQuantity })}</SectionTitle>
								{orderInfo.client.rateCategory && (
									<StyledBox vertical>
										<Container width='100%' justify='space-between' align='center'>
											<StyledSpan>{t('delivery.htAmount')}</StyledSpan>
											<Container align='baseline' gap={4} margin='8px 0'>
												<HTPrice>
													{t('global.price', {
														value: orderInfo.totalPrice,
														formatParams: {
															value: {
																currency: orderInfo.client.rateCategory.currency,
																locale: language,
															},
														},
													})}
												</HTPrice>
												{!user?.currentCompany?.rateCategory?.includesTaxes && <HTLabel>{t('global.ht')}</HTLabel>}
											</Container>
										</Container>
										<LineSeparator />
										{orderInfo.client.rateCategory.taxRate > 0 && (
											<Container width='100%' justify='space-between' align='center'>
												<StyledSpan>{t('cart.vat', { amount: 20 })}</StyledSpan>
												<Container align='baseline' gap={4} margin='8px 0'>
													<HTPrice>
														{t('global.price', {
															value: (orderInfo.client.rateCategory.taxRate / 100) * orderInfo.totalPrice,
															formatParams: {
																value: {
																	currency: orderInfo.client.rateCategory.currency,
																	locale: language,
																},
															},
														})}
													</HTPrice>
												</Container>
											</Container>
										)}
										<Container width='100%' justify='space-between' align='center'>
											<StyledSpan style={{ fontWeight: 600, fontSize: '1.1em' }}>{t('delivery.totalAmount')}</StyledSpan>
											<Container align='baseline' gap={4} margin='8px 0'>
												<HTPrice style={{ fontWeight: 600, fontSize: '1.1em' }}>
													{t('global.price', {
														value: orderInfo.totalPrice + (orderInfo.client.rateCategory.taxRate / 100) * orderInfo.totalPrice,
														formatParams: {
															value: {
																currency: orderInfo.client.rateCategory.currency,
																locale: language,
															},
														},
													})}
												</HTPrice>
											</Container>
										</Container>
										<LineSeparator />
										<MainButton onClick={handleSubmit}>{t('delivery.pay')}</MainButton>
									</StyledBox>
								)}
							</Container>
						</Container>
					</Container>
				</>
			)}
		</>
	);
};

export default Delivery;
