import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut as firebaseSignOut, UserCredential } from 'firebase/auth';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ExtUser } from '../../models/extUser';
import { sessionSlice } from '../../reducers';
import { auth } from '../../services/firebaseService';
import { useSellsy, useToast } from '../../utils/hooks';
import { useNavigate } from 'react-router-dom';

interface UserAuthContextProps {
	user: ExtUser | null | undefined;
	signIn: (email: string, password: string) => Promise<UserCredential>;
	signUp: (email: string, password: string) => Promise<UserCredential>;
	signOut: () => Promise<void>;
}

export const UserAuthContext = createContext<UserAuthContextProps>(undefined!);
interface UserAuthContextProviderProps {
	children: React.ReactNode;
}

export const UserAuthContextProvider = ({ children }: UserAuthContextProviderProps) => {
	const [user, setUser] = useState<ExtUser | null | undefined>();
	const dispatch = useDispatch();
	const { getContact, getContactCompanies } = useSellsy();
	const { toast } = useToast();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const signIn = (email: string, password: string): Promise<UserCredential> => {
		return signInWithEmailAndPassword(auth, email, password);
	};
	const signUp = (email: string, password: string): Promise<UserCredential> => {
		return createUserWithEmailAndPassword(auth, email, password);
	};
	const signOut = (): Promise<void> => {
		return firebaseSignOut(auth);
	};

	useEffect(() => {
		dispatch(sessionSlice.actions.setClientId(user?.currentCompany?.id || null));
	}, [user?.currentCompany]);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
			if (!currentUser) {
				setUser(null);
				navigate('/login');
				return;
			}

			getContact(currentUser.uid)
				.then((contact) => {
					setUser({
						id: contact.id,
						role: 'contact',
						firstName: contact.first_name,
						lastName: contact.last_name,
						mobilePhone: contact.mobile_number,
						homePhone: contact.phone_number,
						function: contact.position,
						otherCompanies: [],
						...currentUser,
					});
				})
				.catch((err) => {
					setUser(null);
					navigate('/login');
					toast({
						message: t('global.errors.fetchingUser'),
						duration: 5000,
						closable: true,
					});
				});

			getContactCompanies(currentUser.uid)
				.then((companies) => {
					setUser((user) => {
						if (!user) return null;
						return {
							...user,
							currentCompany: companies[0],
							otherCompanies: companies.slice(1),
						};
					});
				})
				.catch((err) => {
					setUser(null);
					toast({
						message: t('global.errors.fetchingUser'),
						duration: 5000,
						closable: true,
					});
				});
		});
		return () => {
			unsubscribe();
		};
	}, []);

	return <UserAuthContext.Provider value={{ user, signIn, signUp, signOut }}>{children}</UserAuthContext.Provider>;
};
