import { ProductImage } from "../models/product"

type SellsyProductImage = {
    id: string,
    isDefault: boolean,
    file: {
        file_fullname: string,
        public_path: string,
    }
}

export const getProductImage = (image: SellsyProductImage): ProductImage => {
    const url = `https://file.sellsy.com/${image.file.public_path}`;
    return {
        filename: image.file.file_fullname,
        url: url,
        isDefault: image.isDefault
    }
}