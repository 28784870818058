import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '../../../models/product';
import { Container } from '../../../styles/containers';
import { HTLabel, HTPrice, Muted, StyledSpan } from '../../../styles/texts';
import { useLanguage, useUserAuth } from '../../../utils/hooks';
import { useClientPricing } from '../../../utils/hooks/useClientPricing';
import { MutedUpper } from '../../ProductDetails.tsx/style';
import { DeleteButton, ProductImage, StyledCartItemRow } from './style';

interface CartItemRowProps {
	product: Product;
	quantity: number;
	onClick?: () => void;
	onRemove?: () => void;
}

const CartItemRow = ({ product, quantity, onClick, onRemove }: CartItemRowProps) => {
	const { t, i18n } = useTranslation();
	const { user } = useUserAuth();
	const language = i18n.language;
	const { getTranslation } = useLanguage();
	const { getProductPrice } = useClientPricing();

	const handleDeleteClick = (e: MouseEvent) => {
		e.stopPropagation();
		onRemove && onRemove();
	};

	const price = getProductPrice(product);

	return (
		<StyledCartItemRow onClick={onClick}>
			<ProductImage src={product.defaultImage.url} />
			<Container direction='column' width='100%'>
				<Container justify='space-between' width='100%'>
					<StyledSpan style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '1em', width: 'fit-content', whiteSpace: 'nowrap' }}>{product.name}</StyledSpan>
					<Container align='baseline' gap={4}>
						{price && (
							<>
								<HTPrice>
									{t('global.price', {
										value: price.exclTax,
										formatParams: {
											value: {
												currency: price.currency,
												locale: language,
											},
										},
									})}
								</HTPrice>
								{!user?.currentCompany?.rateCategory?.includesTaxes && <HTLabel>{t('global.ht')}</HTLabel>}
							</>
						)}
					</Container>
				</Container>
				<MutedUpper style={{ fontSize: '0.75em', whiteSpace: 'nowrap' }}>{getTranslation(product.colorName)}</MutedUpper>
				<Muted style={{ marginTop: '4px' }}>{t('navbar.cartMenu.quantity', { quantity })}</Muted>
				<DeleteButton onClick={handleDeleteClick}>{t('global.remove')}</DeleteButton>
			</Container>
		</StyledCartItemRow>
	);
};

export default CartItemRow;
