import { Administrative } from "./Administrative";
import Logistics from "./Logistics";
import User from "./User";

const Informations = {
    Administrative,
    Logistics,
    User,
};

export default Informations;