import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Product } from '../../../models/product';
import { Container } from '../../../styles/containers';
import { HTLabel, HTPrice, StyledSpan } from '../../../styles/texts';
import { useLanguage, useUserAuth } from '../../../utils/hooks';
import { useClientPricing } from '../../../utils/hooks/useClientPricing';
import { Icon } from '../../other';
import { MutedUpper } from '../../ProductDetails.tsx/style';
import { CartIncrementButton, CartIncrementWrapper, GlassesImage, ProductTotalLabel, ProductTotalPrice, QuantityInput, StyledGlassesRow } from '../style';

interface GlassesRowProps {
	glasses: Product;
	quantity: number;
	onQuantityChange: (quantity: number) => void;
	editableQuantity?: boolean;
}

const GlassesRow = ({ glasses, quantity, onQuantityChange, editableQuantity }: GlassesRowProps) => {
	const { t, i18n } = useTranslation();
	const { getTranslation } = useLanguage();
	const { getProductPrice } = useClientPricing();
	const language = i18n.language;
	const navigate = useNavigate();
	const price = getProductPrice(glasses);
	const { user } = useUserAuth();

	const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
		let quantity = parseInt(e.target.value) || undefined;
		if (quantity) {
			if (quantity < 1) {
				quantity = 0;
			} else if (quantity > 9999) {
				quantity = 9999;
			}
		}
		onQuantityChange(quantity || 0);
	};

	const handleQuantityButtonClick = (add: boolean) => {
		if (quantity === 0 && !add) return;
		onQuantityChange(add ? quantity + 1 : quantity - 1);
	};

	const handleOpenClick = () => {
		if (!editableQuantity) return;
		navigate(`/products/${glasses.id}`);
	};

	return (
		<StyledGlassesRow onClick={handleOpenClick} style={{ cursor: editableQuantity ? 'pointer' : 'default' }}>
			<GlassesImage src={glasses.defaultImage.url} />
			<Container direction='column' width='100%'>
				<StyledSpan style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '1em', width: 'fit-content', whiteSpace: 'nowrap' }}>{glasses.name}</StyledSpan>

				<MutedUpper style={{ fontSize: '0.75em', whiteSpace: 'nowrap' }}>{getTranslation(glasses.colorName)}</MutedUpper>
				{price && (
					<Container align='baseline' gap={4} margin='8px 0'>
						<HTPrice>
							{t('global.price', {
								value: price?.exclTax,
								formatParams: {
									value: {
										currency: price.currency,
										locale: language,
									},
								},
							})}
						</HTPrice>
						{!user?.currentCompany?.rateCategory?.includesTaxes && <HTLabel>{t('global.ht')}</HTLabel>}
					</Container>
				)}
				<Container marginTop='auto' width='100%' justify='space-between' align='center' gap={16}>
					{editableQuantity ? (
						<CartIncrementWrapper onClick={(e) => e.stopPropagation()}>
							<CartIncrementButton onClick={() => handleQuantityButtonClick(false)} variant='text'>
								<Icon name='minus' color='#F7C200' size={16} />
							</CartIncrementButton>
							<QuantityInput type='text' value={quantity} onChange={handleQuantityChange} />
							<CartIncrementButton onClick={() => handleQuantityButtonClick(true)} variant='text'>
								<Icon name='plus' color='#F7C200' size={16} />
							</CartIncrementButton>
						</CartIncrementWrapper>
					) : (
						<Container direction='column'>
							<StyledSpan style={{ fontSize: '1em', fontWeight: 500 }}>{t('global.quantity')}</StyledSpan>
							<Container gap={4} align='baseline'>
								<StyledSpan>{quantity}</StyledSpan>
							</Container>
						</Container>
					)}
					<Container direction='column' align='flex-end'>
						<StyledSpan style={{ fontSize: '1em', fontWeight: 500 }}>{t('cart.totalPrice')}</StyledSpan>
						<Container gap={4} align='baseline'>
							{price && (
								<>
									<ProductTotalPrice>
										{t('global.price', {
											value: price.exclTax * quantity,
											formatParams: {
												value: {
													currency: price.currency,
													locale: language,
												},
											},
										})}
									</ProductTotalPrice>
									{!user?.currentCompany?.rateCategory?.includesTaxes && <ProductTotalLabel>{t('global.ht')}</ProductTotalLabel>}
								</>
							)}
						</Container>
					</Container>
				</Container>
			</Container>
		</StyledGlassesRow>
	);
};

export default GlassesRow;
