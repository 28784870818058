import styled from "styled-components";
import { devices } from "../../constants/mediaQueries";

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    width: 100vw;
    align-self: center;
    background-color: #f7c200;
    padding: 32px 0 0 0;
    box-sizing: border-box;
`;

export const NotFoundIconContainer = styled.div`
    margin: 96px 0 64px 0;
    position: relative;
    width: 300px;

    @media ${devices.tablet} {
        width: 400px;
    }
`;