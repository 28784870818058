import { useClickOutside } from 'aaastyle';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { I18N_AVAILABLE_LOCALES } from '../../../constants/i18nLocales';
import { Muted } from '../../../styles/texts';
import { ButtonItem, DropdownMenu, MenuItem, MenuItems } from '../ProfileMenu/style';

interface LanguageMenuProps {
	onClose: () => void;
}

const LanguageMenu = ({ onClose }: LanguageMenuProps) => {
	const { t, i18n } = useTranslation();
	const dropdownRef = useRef<HTMLDivElement>(null);
	useClickOutside(dropdownRef, () => onClose());

	const handleItemClick = (lang: string) => {
		i18n.changeLanguage(lang);
		onClose();
	};

	return (
		<DropdownMenu ref={dropdownRef}>
			<MenuItems>
				<MenuItem>
					<Muted>{t('navbar.languageMenu.title')}</Muted>
				</MenuItem>
				{I18N_AVAILABLE_LOCALES.map((language) => (
					<ButtonItem isActive={+(i18n.language === language)} key={language} onClick={() => handleItemClick(language)}>
						{t(`navbar.languageMenu.${language}`)}
					</ButtonItem>
				))}
			</MenuItems>
		</DropdownMenu>
	);
};

export default LanguageMenu;
