import { motion } from "framer-motion";
import styled from "styled-components";

export const GlassesTileContainer = styled(motion.div)`
    display: flex;
    position: relative;
    flex: 0 1 300px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    border-radius: 12px;
    box-sizing: border-box;

    /* & span, & label, & img {
        cursor: pointer;
    } */
`;

export const TileProductImageWrapper = styled.div`
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
    border-radius: 12px;
    position: relative;
    width: 100%;
    max-height: 300px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &::after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
`;

export const TileProductImage = styled.img<{ $isZoomable?: boolean }>`
    width: ${({ $isZoomable }) => $isZoomable ? 'auto' : '100%'};
    height: 100%;
    object-fit: cover;

    cursor: ${({ $isZoomable }) => $isZoomable ? 'pointer' : 'default'};
    opacity: ${({ $isZoomable }) => $isZoomable ? 1 : 0.3};
`;