import { useCallback, useMemo } from 'react';
import { Product, ProductPrice } from '../../models/product';
import { TariffCategory } from '../../models/tariffCategory';
import { useUserAuth } from "./useUserAuth";

export const useClientPricing = () => {
    const { user } = useUserAuth();

    const tariffCategory: TariffCategory = useMemo(() => {
        return user?.currentCompany?.rateCategory?.name || 'EUR';
    }, [user]);

    const getProductPrice = useCallback((product: Product): ProductPrice | null => {
        return product.price[tariffCategory] || null;
    }, [user, tariffCategory]);

    return {
        tariffCategory,
        getProductPrice
    };

};