import styled from 'styled-components';
import { MainButton } from '../../../../styles/buttons';

export const GCPaymentButton = styled(MainButton)``;

export const GCPaymentWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    box-sizing: border-box;
    overflow: hidden;
    align-items: stretch;
`;