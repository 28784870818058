import styled from "styled-components";
import { MainContainer } from "../../styles/containers";

export const CheckoutMainContainer = styled(MainContainer)`
    max-width: 1200px;
    box-sizing: content-box;
`;

export const AddressSpan = styled.span`
    font-size: 1.1em;
    font-weight: 500;
    color: ${({ theme }) => theme.blackColor};
`;

export const AddressP = styled.p`
    margin: 0;
    white-space: pre-line;
    font-size: 1.1em;
    font-weight: 500;
    color: ${({ theme }) => theme.blackColor};
`;

export const LineSeparator = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.lightGrayColor};
    margin: 8px 0;
`;