import { Order } from '../models';

export const addOrder = async (order: Order, mails: {client: string, contact?: string}) => {
	return fetch('/api/order', {
		method: 'POST',
		headers: {'Content-Type': 'application/json'},
		body: JSON.stringify({
			order: order,
			mails: mails,
		}),
	});
};

export const getOrder = async (id: string) => {
	return fetch(`/api/order/${id}`)
	.then(res => res.json());
};
