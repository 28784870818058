import styled from "styled-components";

export const FavoritesFilterButton = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    padding: 0;
    margin: 0;
    width: fit-content;
    height: fit-content;
    margin-left: auto;
`;

export const GroupsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;
    width: 100%;
`;

export const ProductsContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 32px;
    
    
    /* flex-direction: row;
    position: relative;
    gap: 32px;
    flex-wrap: wrap;
    justify-content: center; */
`;