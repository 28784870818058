import { useClickOutside } from 'aaastyle';
import { AnimatePresence } from 'framer-motion';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { MainButtonLink } from '../../../styles/buttons';
import { Container } from '../../../styles/containers';
import { Muted } from '../../../styles/texts';
import { useUserAuth } from '../../../utils/hooks';
import Symbol from '../../other/Symbol';
import { BottomMenu, ButtonItem, CurrentCompany, DropdownMenu, LinkItem, MenuItem, MenuItems, NavLinkItem, NewClientLabel, ProfileButton, ProfilePicture, ProfileWrapper, RegisterLink, TopMenu, TopMenuName } from './style';
import LanguageMenu from '../LanguageMenu';

const ProfileMenu = () => {
	const [expand, setExpand] = useState(false);
	const { user } = useUserAuth();
	const { t } = useTranslation();
	const theme = useTheme();
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [showLanguageMenu, setShowLanguageMenu] = useState<boolean>(false);
	useClickOutside(dropdownRef, () => setExpand(false));

	const handleExpand = () => {
		setExpand((expand) => {
			if (!expand) {
				setShowLanguageMenu(false);
			}
			return !expand;
		});
	};

	const handleHide = () => {
		setExpand(false);
	};

	const handleLanguageMenuClick = () => {
		setShowLanguageMenu(true);
		setExpand(false);
	};

	const getMenusItems = useCallback(() => {
		if (user) {
			return (
				<>
					<NavLinkItem onClick={handleHide} to='/profile'>
						{t('navbar.profileMenu.profile')}
					</NavLinkItem>

					<LinkItem onClick={handleHide} to='/profile/orders'>
						{t('navbar.profileMenu.orders')}
					</LinkItem>
					<ButtonItem onClick={handleLanguageMenuClick}>{t('navbar.profileMenu.language')}</ButtonItem>
					<NavLinkItem onClick={handleHide} to='/logout'>
						{t('navbar.profileMenu.logout')}
					</NavLinkItem>
				</>
			);
		} else {
			return (
				<>
					<TopMenu style={{ padding: 0, gap: 0 }}>
						<MenuItem style={{ paddingTop: '16px' }}>
							<MainButtonLink to='/login' onClick={handleHide}>
								{t('navbar.profileMenu.login')}
							</MainButtonLink>
						</MenuItem>
						<MenuItem style={{ paddingBottom: '16px' }}>
							<RegisterLink to='/register' onClick={handleHide}>
								<>
									<NewClientLabel>{t('navbar.profileMenu.newClient')}</NewClientLabel>
									{t('navbar.profileMenu.register')}
								</>
							</RegisterLink>
						</MenuItem>
					</TopMenu>
					<ButtonItem onClick={handleLanguageMenuClick}>{t('navbar.profileMenu.language')}</ButtonItem>
				</>
			);
		}
	}, [user, t]);

	return (
		<ProfileWrapper>
			<ProfileButton onClick={handleExpand}>
				<Symbol name='account_circle' size={36} color={theme.blackColor} weight={300} />
			</ProfileButton>
			<AnimatePresence>
				{expand && (
					<DropdownMenu ref={dropdownRef}>
						{user && (
							<TopMenu>
								<Container direction='row' gap={16}>
									<ProfilePicture>
										{user.firstName?.[0]}
										{user.lastName?.[0]}
									</ProfilePicture>
									<Container gap={4} direction='column'>
										<TopMenuName>{`${user.firstName} ${user.lastName}`}</TopMenuName>
										<Muted>{user.email}</Muted>
									</Container>
								</Container>

								{user.currentCompany && <CurrentCompany>{user.currentCompany.name}</CurrentCompany>}
							</TopMenu>
						)}
						<MenuItems>{getMenusItems()}</MenuItems>
						{user && user.otherCompanies.length > 0 && (
							<BottomMenu>
								<Muted>{t('navbar.profileMenu.otherCompanies')}</Muted>
								<Container margin='0 -16px' width='100%'>
									{user.otherCompanies.map((company) => (
										<NavLinkItem key={company.id} to={`/switch-company/${company.id}`}>
											{company.name}
										</NavLinkItem>
									))}
								</Container>
							</BottomMenu>
						)}
					</DropdownMenu>
				)}
				{showLanguageMenu && <LanguageMenu onClose={() => setShowLanguageMenu(false)} />}
			</AnimatePresence>
		</ProfileWrapper>
	);
};

export default ProfileMenu;
