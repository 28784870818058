import { Select } from 'aaastyle';
import styled from "styled-components";

export const Form = styled.form``;

export const TextInput = styled.input<{ invalid?: boolean }>`
	display: flex;
	border: 1px solid;
	outline: none;
	border-radius: 4px;
	font-size: 1em;
	font-weight: 500;
	padding: 4px 12px;
	box-sizing: border-box;
	background-color: rgba(0,0,0,0.04);
	border-color: ${(props) => props.invalid ? '#e34850' : 'rgba(144,144,144,0.3)'};

	&[type=email], &[type=url] {
        text-transform: lowercase;
    }
	
	&::placeholder{
		font-weight: 400;
		font-size: 0.9em;
		color: #a9a9a9;
	}
`;


export const SelectInput = styled.select`
	display: flex;
	border: 1px solid;
	outline: none;
	border-radius: 4px;
	font-size: 1em;
	font-weight: 500;
	padding: 4px 12px;
	box-sizing: border-box;
	background-color: rgba(0,0,0,0.04);
	border-color: rgba(144,144,144,0.3);
	& > option {
		font-weight: 500;
	}
`;

export const StyledSelect = styled(Select) <{ showPlaceholder?: boolean }>`
	font-size: 1em;
	box-sizing: border-box;

	& span {
			font-size: ${(props) => props.showPlaceholder ? '0.9em' : '1em'};
			font-weight: ${(props) => props.showPlaceholder ? '400' : '500'};
			color: ${(props) => props.showPlaceholder ? '#a9a9a9' : '#000'};
		}

	& li {
		margin: 0;
		padding: 4px 12px;
	}

	& svg {
		flex: 0 0 auto;
		width: 22px;
		height: 22px;
	}
	& > div:nth-child(1) {
		padding: 4px 12px;
	}

	& > div:nth-child(2) {
		box-sizing: border-box;
		z-index: 100;
		background: white;
	}

	& ul {
		max-height: 400px;
		overflow-y: auto;
	}

	& input {
		outline: none;
		font-size: 1em;
		padding: 4px 8px;
	}

`;

export const SearchInput = styled(TextInput)`
	width: 100%;
	margin: 8px 0;
	padding: 8px 42px 8px 12px;
	font-weight: 600;

	&::placeholder{
		font-weight: 400;
		font-size: 1em;
		color: #a9a9a9;
	}

	&:focus{
		&::placeholder{
			font-size: 0;
		}
	}

	&::-webkit-search-cancel-button {
		display: none;
	}
`;

export const LoginInput = styled(TextInput)`
	background-color: #fff;
	padding: 8px;

	&:focus{
		border: 1px solid #F7C200;
	}
`;

export const DateInput = styled.input.attrs({ type: 'date' })`
	display: flex;
	border: 1px solid;
	outline: none;
	border-radius: 4px;
	font-size: 1em;
	font-weight: 500;
	padding: 4px 12px;
	box-sizing: border-box;
	background-color: rgba(0,0,0,0.04);
	border-color: rgba(144,144,144,0.3);
	font-family: 'Roboto', sans-serif;
`;