import { MainButton } from './../../styles/buttons/index';
import { Muted, Tag } from './../../styles/texts/index';
import styled from 'styled-components';
import { devices } from '../../constants/mediaQueries';
import { Swiper } from 'swiper/react';
export const ImagesColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 400px;
    position: relative;
    box-sizing: border-box;
`;

export const DetailsColumns = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(100% - 410px);
    position: relative;
    box-sizing: border-box;
`;

export const SelectedImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    margin-bottom: 8px;
    max-width: 400px;
    max-height: min(calc(100vw - 32px), 400px);
    box-sizing: border-box;
    cursor: zoom-in;
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
`;

export const ImageSlide = styled.img`
    width: calc(100vw - 32px);
    height: calc(100vw - 32px);
    object-fit: cover;
`;

export const HeaderImage = styled.img`
    width: 100vw;
    top: -12px;
    left: -16px;
    position: absolute;
    max-height: 400px;
    object-fit: cover;
    align-self: center;
`;

export const ProductImage = styled.img`
    width: 100px;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
`;

export const MutedUpper = styled(Muted)`
    text-transform: uppercase;
    font-weight: 500;
`

export const DetailTag = styled(Tag)`
    font-size: 0.75em;
    font-weight: 500;
    background-color: ${({ theme }) => theme.mainColor};
    width: fit-content;
    padding: 4px 16px;
`;

export const DetailDescription = styled(Muted)`
    font-size: 0.875em;
    font-weight: 300;
`;

export const DetailsTitle = styled.span`
    font-size: 1.2em;
    font-weight: 600;
    text-decoration: underline;
`;

export const ProductDetailsMainContainer = styled.div`
    width: 100%;
    max-width: 1200px;
    
    gap: 10px;
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    box-sizing: border-box;

    @media ${devices.tablet} {
        align-self: center;
        flex-direction: row;
        padding: 16px 0;
    }
`;

export const StyledSwiper = styled(Swiper)`
    width: 100vw;

    & .swiper-pagination-bullet-active {
        background-color: ${({ theme }) => theme.mainColor};
    }
`;

export const AddToCartButton = styled(MainButton)`
    width: fit-content;
    min-width: 240px;
    border-radius: 200px;
`;