import { Link } from 'react-router-dom';
import styled from 'styled-components';
import solamorLogo from '../../assets/images/solamor.png';

export const StyledNavbar = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	width: 100%;
	height: 64px;
    box-sizing: border-box;
	padding: 8px 16px;
	box-sizing: border-box;
	background-color: #fff;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
	z-index: 100;
	position: sticky;
	top: 0;
	left: 0;
`;


export const LogoLink = styled(Link)`
    height: 100%;
    /* width: 200px; */
`;

export const SolamorLogo = styled.img.attrs({
    src: solamorLogo,
})`
    height: 100%;
    object-fit: contain;
`;