import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadString } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
    apiKey: 'AIzaSyA9OS30pFlcWOm8lOGAfCUcj8zj8zi40fU',
    authDomain: 'solamor-ddf94.firebaseapp.com',
    projectId: 'solamor-ddf94',
    storageBucket: 'solamor-ddf94.appspot.com',
    messagingSenderId: '780876393257',
    appId: '1:780876393257:web:e5ab5af9381627773fa538',
    measurementId: 'G-LXFZXZF92Y',
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app)
export const auth = getAuth(app);
export const db = getFirestore(app);

export const uploadImage = (contactId: string, image: any) => {
    console.log(`Try uploading with id : ${contactId}`);
    
    const storageRef = ref(storage, contactId);
    uploadString(storageRef, image)
        .then(() => {
            console.log('Uploaded a blob or file!');
        })
        .catch((err) => {
            console.log('Error uploading file', err);
        });
}