import { Box, View } from 'aaastyle';
import styled from 'styled-components';
import { devices } from '../../constants/mediaQueries';

export const Container = styled.div<{
	direction?: string;
	align?: string;
	justify?: string;
	gap?: number;
	padding?: string;
	margin?: string;
	width?: string;
	height?: string;
	maxWidth?: string;
	maxHeight?: string;
	flex?: string;
	justifySelf?: string;
	alignSelf?: string;
	flexWrap?: string;
	position?: string;
	top?: string;
	right?: string;
	bottom?: string;
	left?: string;
	overflowX?: string;
	overflowY?: string;
	cursor?: string;
	marginTop?: string;
	marginBottom?: string;
	marginLeft?: string;
	marginRight?: string;
	borderRadius?: string;
}>`
	position: ${(props) => props.position || 'relative'};
	display: flex;
	gap: ${(props) => props.gap && `${props.gap}px`};
	box-sizing: border-box;
	flex-direction: ${(props) => props.direction};
	align-items: ${(props) => props.align};
	justify-content: ${(props) => props.justify};
	padding: ${(props) => props.padding};
	margin: ${(props) => props.margin};
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	max-width: ${(props) => props.maxWidth};
	max-height: ${(props) => props.maxHeight};
	flex: ${(props) => props.flex};
	justify-self: ${(props) => props.justifySelf};
	align-self: ${(props) => props.alignSelf};
	flex-wrap: ${(props) => props.flexWrap};
	overflow-x: ${(props) => props.overflowX};
	overflow-y: ${(props) => props.overflowY};
	cursor: ${(props) => props.cursor};
	top: ${(props) => props.top};
	right: ${(props) => props.right};
	bottom: ${(props) => props.bottom};
	left: ${(props) => props.left};
	margin-top: ${(props) => props.marginTop};
	margin-bottom: ${(props) => props.marginBottom};
	margin-left: ${(props) => props.marginLeft};
	margin-right: ${(props) => props.marginRight};
	border-radius: ${(props) => props.borderRadius};
`;



export const StyledView = styled(View)`
	background-size: 300px;
	background-repeat: repeat;
	box-sizing: border-box;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	background-color: #fff;

	@media ${devices.laptop} {
		justify-content: center;
		align-items: flex-start;
	}
`;

export const MainContainer = styled.div`
	display: flex;
	background-color: #ffffff;
	position: relative;
	height: fit-content;
	justify-content: flex-start;
	padding: 16px;
	flex-direction: column;
	margin: 0;
	border-radius: 0;
	box-sizing: border-box;
	width: 100%;
	min-height: calc(100svh - 64px);

	@media ${devices.tablet} {
		padding: 16px 32px 32px 32px;
	}

	@media ${devices.laptop} {
		min-height: calc(100svh - 64px);
		border-radius: 12px;
		padding: 16px 32px 32px 32px;
	}
`;

export const ShrinkedContainer = styled(MainContainer)`
	flex: 0 1 800px;


	@media ${devices.laptop} {
		flex: 0 1 1000px;
	}

	@media ${devices.laptopL} {
		flex: 0 1 1200px;
	}
`;

export const FormGroup = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 12px;
	padding: 12px 0;
`;

export const FormRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 24px;
	width: 100%;
	flex-wrap: wrap;
`;


export const FormField = styled.div<{ flex?: string }>`
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 100%;
	flex: ${(props) => props.flex || '1 1 300px'};
	position: relative;
`;

export const HomeContainer = styled.div`
	width: 100vw;
	min-height: 100vh;
	max-height: 1000vh;
	display: flex;
	background-color: #f7c200;
	overflow-x: hidden;
	flex-flow: row wrap;
	justify-content: space-evenly;
	box-sizing: border-box;
	padding: 0;

	@media ${devices.tablet} {
		padding: 0;
		overflow-y: hidden;
		padding: 30px 0;
	}
`;

export const HomeFooter = styled.div`
	position: relative;
	height: 100px;
	width: 100%;
	display: flex;
	background-color: #f7c200;
	border-top: 2px solid #bf2625;
	box-sizing: border-box;
	justify-content: flex-end;
	align-items: flex-end;
	padding: 8px 16px;
`;

export const BackArrowWrapper = styled.div<{ $clickable?: boolean }>`
	display: flex;
	align-items: center;
	gap: 4px;
	cursor: ${(props) => props.$clickable ? 'pointer' : 'default'};
	width: 200px;
	overflow: hidden;

	& > button, & > h1 {
		transition: 0.2s;
		transform:  ${(props) => props.$clickable ? 'translateX(0)' : 'translateX(-28px)'};
	}

	& > button {
		opacity: ${(props) => props.$clickable ? 1 : 0};
	}


	& > h1 {
		display: none;
	}

	@media ${devices.tablet} {
		& > h1 {
			display: flex;
		}
	}
`;

export const StyledBox = styled(Box)`
	margin: 0;
	padding: 16px;
`;