import { useTranslation } from 'react-i18next';
import { ItemSeparator, LinkItem, SidebarItem, StyledSidebar } from './style';

const Sidebar = () => {
	const { t } = useTranslation();

	return (
		<StyledSidebar>
			<SidebarItem>
				<LinkItem to='company'>{t('profile.company')}</LinkItem>
			</SidebarItem>
			<ItemSeparator />
			<SidebarItem>
				<LinkItem to='logistics'>{t('profile.logistics')}</LinkItem>
			</SidebarItem>
			<ItemSeparator />
			<SidebarItem>
				<LinkItem to='account'>{t('profile.account')}</LinkItem>
			</SidebarItem>
			<ItemSeparator />
			<SidebarItem>
				<LinkItem to='orders'>{t('profile.orders')}</LinkItem>
			</SidebarItem>
		</StyledSidebar>
	);
};

export default Sidebar;
