import { devices } from './../../../../constants/mediaQueries';
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Container, MainContainer } from '../../../../styles/containers';

export const ItemSeparator = styled.div`
    flex-shrink: 0;
    width: 1px;
    height: 1em;
    background-color: rgba(0,0,0,0.2);
    margin: 0 16px;
`;

export const StyledSidebar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 16px 0 16px;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    @media ${devices.tablet}{
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
        justify-content: flex-start;
        flex: 0 0 250px;
        position: fixed;  
        width: unset;
        top: 64px;
        margin: 0;
        & > ${ItemSeparator} {
            display: none;
        }
    }
`;

export const SidebarItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 8px 0;

    @media ${devices.tablet}{
        width: 100%;
        justify-content: flex-start;
    }
`;

export const LinkItem = styled(NavLink) <{ isActive?: boolean }>`
    text-decoration: none;
    font-size: 1em;
    font-weight: 600;
    color: ${(props) => props.theme.blackColor};
    &.active{
        color: ${(props) => props.theme.mainColor};
    }
    white-space: nowrap;
`;

export const ProfileMainContainer = styled(MainContainer)`
    flex-direction: column;
    align-items: center;
    padding: 0;

    @media ${devices.tablet}{
        flex-direction: row;
        align-items: flex-start;
    }
`;

export const ProfileViews = styled(Container)`
    flex: 0 1 1200px;
    padding: 8px 16px;


    @media ${devices.tablet}{
        margin: 0 250px;
    }
`;

