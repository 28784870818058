import { Toast } from 'aaastyle';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Mille946 from './components/1946';
import Profile from './components/Account/Profile/Profile';
import Register from './components/Account/Register/Register';
import { Cart } from './components/Cart';
import Checkout from './components/Checkout';
import Login from './components/Client/Login';
import Logout from './components/Client/Logout';
import Contact from './components/Contact';
import { SellsyContextProvider } from './components/context/SellsyContext';
import ToastContextProvider from './components/context/ToastContext';
import { UserAuthContextProvider } from './components/context/UserAuthContextProvider';
import Navbar from './components/Navbar/Navbar';
import { Icon, ProtectedRoute } from './components/other';
import Footer from './components/other/Footer';
import NotFound from './components/other/NotFound';
import SMD from './components/other/SimpleMarkdown';
import { Products } from './components/Products';
import THEME from './constants/aaaStyleTheme';
import { sizes } from './constants/mediaQueries';
import { ReducerState, SalesPerson } from './models';
import { sessionSlice } from './reducers';
import { getAdminInfos } from './services';
import { getTokensFromRefreshToken } from './services/adminService';
import { StyledView } from './styles/containers';
const App = () => {
	const dispatch = useDispatch();
	const { toast, isMobile } = useSelector((state: ReducerState) => state.session);
	const [adminLoaded, setAdminLoaded] = useState<boolean>(false);
	const viewRef = useRef<HTMLDivElement>(null);
	const toastRef = useRef<any>(null);
	const location = useLocation();

	const handleWindowResize = () => {
		dispatch(sessionSlice.actions.setIsMobile(window.innerWidth < sizes.tablet));
	};

	useEffect(() => {
		viewRef.current?.scrollTo(0, 0);
	}, [location]);

	useEffect(() => {
		const accessToken = localStorage.getItem('accessToken');
		const refreshToken = localStorage.getItem('refreshToken');

		if (accessToken) {
			getAdminInfos(accessToken)
				.then((admin: SalesPerson) => {
					dispatch(sessionSlice.actions.setAdmin(admin));
					setAdminLoaded(true);
				})
				.catch((err) => {
					getTokensFromRefreshToken(refreshToken || '')
						.then((res) => res.json())
						.then((data) => {
							localStorage.setItem('accessToken', data.access_token);
							localStorage.setItem('refreshToken', data.refresh_token);
							getAdminInfos(data.access_token)
								.then((admin) => {
									dispatch(sessionSlice.actions.setAdmin(admin));
									setAdminLoaded(true);
								})
								.catch((err) => {})
								.finally(() => {
									setAdminLoaded(true);
								});
						});
				});
		} else {
			setAdminLoaded(true);
		}
		handleWindowResize();
		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		toastRef.current?.toast();
	}, [toast]);

	return (
		<ThemeProvider theme={THEME}>
			<StyledView id='mainview' ref={viewRef} theme={THEME}>
				<ToastContextProvider>
					<SellsyContextProvider>
						<UserAuthContextProvider>
							{toast && (
								<Toast style={{ width: 'fit-content', padding: '8px 24px', gap: '8px' }} ref={toastRef} onClose={() => {}} closable duration={5000} position={isMobile ? 'bottom-center' : 'top-center'}>
									{toast.icon && <Icon name={toast.icon} size={24} color={toast.color || '#f7c200'} />}
									{toast.message}
								</Toast>
							)}
							{adminLoaded && (
								<>
									<Navbar />
									<Routes>
										<Route path='/*' element={<Navigate replace to='/products' />} />
										<Route path='/legals' element={<SMD filename='CGU' />} />
										<Route path='/contact' element={<Contact />} />
										<Route path='/1946' element={<Mille946 />} />
										<Route path='/2023' element={<SMD filename='2023' />} />
										<Route path='/profile/*' element={<Profile />} />
										<Route path='/register/*' element={<Register />} />
										<Route path='/login' element={<Login />} />
										<Route path='/logout' element={<Logout />} />
										<Route path='/products' element={<Outlet />}>
											<Route
												index
												element={
													<ProtectedRoute>
														<Products />
													</ProtectedRoute>
												}
											/>
											<Route
												path='favorites'
												element={
													<ProtectedRoute>
														<Products showFavorites />
													</ProtectedRoute>
												}
											/>
											{/* <Route path=':id' element={<ProductDetails />} /> */}
										</Route>
										<Route path='/cart' element={<Cart />} />
										<Route path='/checkout/*' element={<Checkout />} />
										<Route path='*' element={<NotFound />} />
									</Routes>
									<Footer />
								</>
							)}
						</UserAuthContextProvider>
					</SellsyContextProvider>
				</ToastContextProvider>
			</StyledView>
		</ThemeProvider>
	);
};

export default App;
