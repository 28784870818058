import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import styled from 'styled-components';
import { BackArrowButton } from '../../styles/buttons/other';
import { BackArrowWrapper } from '../../styles/containers';
import Icon, { IconName } from './Icon';

const hideBackArrowPaths = ['/', '/login', '/register'];

interface BackArrowProps {
	color?: string;
	title?: string;
	titleColor?: string;
	path?: string;
	icon?: IconName;
}

const BackArrow = ({ color, title, titleColor, path, icon }: BackArrowProps) => {
	const navigate = useNavigate();
	const buttonRef = useRef<HTMLButtonElement>(null);
	const location = useLocation();
	const [pushCounter, setPushCounter] = useState(1);
	const navType = useNavigationType();

	const handleClick = () => {
		if (pushCounter <= 0 || hideBackArrowPaths.includes(location.pathname)) {
			return;
		}
		path ? navigate(path) : navigate(-1);
	};

	useEffect(() => {
		switch (navType) {
			case 'PUSH':
				setPushCounter(pushCounter + 1);
				break;
			case 'POP':
				setPushCounter(pushCounter - 1);
				break;
			default:
				break;
		}
	}, [location]);

	return (
		<BackArrowWrapper $clickable={pushCounter > 0 && !hideBackArrowPaths.includes(location.pathname)} onClick={handleClick}>
			<BackArrowButton ref={buttonRef}>
				<Icon name={icon || 'back-arrow'} size={28} color={color || '#F7C200'} />
			</BackArrowButton>
			{title && <Title color={titleColor || color}>{title}</Title>}
		</BackArrowWrapper>
	);
};

const Title = styled.h1<{ color?: string }>`
	color: ${({ color }) => color};
	font-size: 1.5em;
`;

export default BackArrow;
