import { useRef } from 'react';
import { PaymentEntryLabel, PaymentEntryWrapper, PaymentMethodRadio } from './style';

interface PaymentEntryProps {
	value: number;
	label: string;
    defaultChecked: boolean;
}

const PaymentEntry = ({ value, label, defaultChecked }: PaymentEntryProps) => {
    const radioRef = useRef<HTMLInputElement>(null);

	return (
		<PaymentEntryWrapper onClick={() => radioRef.current?.click()}>
			<PaymentMethodRadio defaultChecked={defaultChecked} ref={radioRef} name='paymentMethod' value={value} id={`paymentMethod-${value}`} />
			<PaymentEntryLabel htmlFor={`paymentMethod-${value}`}>{label}</PaymentEntryLabel>
		</PaymentEntryWrapper>
	);
};

export default PaymentEntry;
