export const companyInfosUrls: { [country: string]: string } = {
    'AF': '',
    'AX': '',
    'AL': '',
    'DZ': '',
    'AS': '',
    'AD': '',
    'AO': '',
    'AI': '',
    'AQ': '',
    'AG': '',
    'AR': '',
    'AM': '',
    'AW': '',
    'AU': '',
    'AT': '',
    'AZ': '',
    'BS': '',
    'BH': '',
    'BD': '',
    'BB': '',
    'BY': '',
    'BE': 'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?searchWord=:name&_oudeBenaming=on&pstcdeNPRP=&postgemeente1=&ondNP=true&_ondNP=on&ondRP=true&_ondRP=on&rechtsvormFonetic=ALL&vest=true&_vest=on&filterEnkelActieve=true&_filterEnkelActieve=on&actionNPRP=Rechercher',
    'BZ': '',
    'BJ': '',
    'BM': '',
    'BT': '',
    'BO': '',
    'BA': '',
    'BW': '',
    'BV': '',
    'BR': '',
    'IO': '',
    'BN': '',
    'BG': '',
    'BF': '',
    'BI': '',
    'KH': '',
    'CM': '',
    'CA': 'https://beta.registresdentreprisesaucanada.ca/chercher/resultats?search=%7B:name%7D&status=Active',
    'CV': '',
    'KY': '',
    'CF': '',
    'TD': '',
    'CL': '',
    'CN': '',
    'CX': '',
    'CC': '',
    'CO': '',
    'KM': '',
    'CG': '',
    'CD': '',
    'CK': '',
    'CR': '',
    'CI': '',
    'HR': '',
    'CU': '',
    'CY': '',
    'CZ': '',
    'DK': '',
    'DJ': '',
    'DM': '',
    'DO': '',
    'EC': '',
    'EG': '',
    'SV': '',
    'GQ': '',
    'ER': '',
    'EE': '',
    'ET': '',
    'FK': '',
    'FO': '',
    'FJ': '',
    'FI': '',
    'FR': 'https://www.societe.com/cgi-bin/search?champs=:name',
    'GF': '',
    'PF': '',
    'TF': '',
    'GA': '',
    'GM': '',
    'GE': '',
    'DE': 'https://www.unternehmensregister.de/',
    'GH': '',
    'GI': '',
    'GR': '',
    'GL': '',
    'GD': '',
    'GP': '',
    'GU': '',
    'GT': '',
    'GG': '',
    'GN': '',
    'GW': '',
    'GY': '',
    'HT': '',
    'HM': '',
    'VA': '',
    'HN': '',
    'HK': '',
    'HU': '',
    'IS': '',
    'IN': '',
    'ID': '',
    'IR': '',
    'IQ': '',
    'IE': '',
    'IM': '',
    'IL': '',
    'IT': '',
    'JM': '',
    'JP': '',
    'JE': '',
    'JO': '',
    'KZ': '',
    'KE': '',
    'KI': '',
    'KP': '',
    'KR': '',
    'KW': '',
    'KG': '',
    'LA': '',
    'LV': '',
    'LB': '',
    'LS': '',
    'LR': '',
    'LY': '',
    'LI': '',
    'LT': '',
    'LU': 'https://www.lbr.lu/mjrcs/jsp/DisplayConsultDocumentsActionNotSecured.action?FROM_MENU=true&time=1663160343286&currentMenuLabel=menu.item.companyconsultation',
    'MO': '',
    'MK': '',
    'MG': '',
    'MW': '',
    'MY': '',
    'MV': '',
    'ML': '',
    'MT': '',
    'MH': '',
    'MQ': '',
    'MR': '',
    'MU': '',
    'YT': '',
    'MX': '',
    'FM': '',
    'MD': '',
    'MC': '',
    'MN': '',
    'MS': '',
    'MA': '',
    'MZ': '',
    'MM': '',
    'NA': '',
    'NR': '',
    'NP': '',
    'NL': 'https://www.kvk.nl/zoeken/handelsregister/?handelsnaam=:name&kvknummer=&straat=&postcode=&huisnummer=&plaats=&hoofdvestiging=1&rechtspersoon=1&nevenvestiging=1&zoekvervallen=0&zoekuitgeschreven=1&start=0',
    'AN': '',
    'NC': '',
    'NZ': '',
    'NI': '',
    'NE': '',
    'NG': '',
    'NU': '',
    'NF': '',
    'MP': '',
    'NO': '',
    'OM': '',
    'PK': '',
    'PW': '',
    'PS': '',
    'PA': '',
    'PG': '',
    'PY': '',
    'PE': '',
    'PH': '',
    'PN': '',
    'PL': '',
    'PT': '',
    'PR': '',
    'QA': '',
    'RE': '',
    'RO': '',
    'RU': '',
    'RW': '',
    'SH': '',
    'KN': '',
    'LC': '',
    'PM': '',
    'VC': '',
    'WS': '',
    'SM': '',
    'ST': '',
    'SA': '',
    'SN': '',
    'CS': '',
    'SC': '',
    'SL': '',
    'SG': '',
    'SK': '',
    'SI': '',
    'SB': '',
    'SO': '',
    'ZA': '',
    'GS': '',
    'ES': '',
    'LK': '',
    'SD': '',
    'SR': '',
    'SJ': '',
    'SZ': '',
    'SE': '',
    'CH': 'https://www.zefix.ch/fr/search/entity/list?mainSearch=:name&searchTypeExact=true',
    'SY': '',
    'TW': '',
    'TJ': '',
    'TZ': '',
    'TH': '',
    'TL': '',
    'TG': '',
    'TK': '',
    'TO': '',
    'TT': '',
    'TN': '',
    'TR': '',
    'TM': '',
    'TC': '',
    'TV': '',
    'UG': '',
    'UA': '',
    'AE': '',
    'GB': 'https://find-and-update.company-information.service.gov.uk/search?q=:name',
    'US': '',
    'UM': '',
    'UY': '',
    'UZ': '',
    'VU': '',
    'VE': '',
    'VN': '',
    'VG': '',
    'VI': '',
    'WF': '',
    'EH': '',
    'YE': '',
    'ZM': '',
    'ZW': '',
}