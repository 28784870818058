export const LANGUAGE_PER_COUNTRY = {
    FR: 'fr-FR',
    GB: 'en-GB',
    US: 'en-US',
    ES: 'es-ES',
    CH: 'fr-CH',
    IT: 'it-IT',
    JP: 'ja-JP',
}

// export const LANGUAGE_PER_COUNTRY = {
//     AD: 'ca',
//     AE: 'ar',
//     AF: 'fa',
//     AG: 'en',
//     AI: 'en',
//     AL: 'sq',
//     AM: 'hy',
//     AN: 'nln',
//     AO: 'pt',
//     AR: 'es',
//     AS: 'en',
//     AT: 'de',
//     AU: 'en',
//     AW: 'nl',
//     AX: 'sv',
//     BA: 'bs',
//     BB: 'en',
//     BD: 'bn',
//     BE: 'fr',
//     BF: 'fr',
//     BH: 'ar',
//     BI: 'fr',
//     BJ: 'fr',
//     BL: 'fr',
//     BM: 'en',
//     BN: 'ms',
//     BO: 'es',
//     BR: 'pt',
//     BS: 'en',
//     BT: 'dz',
//     BV: 'no',
//     BW: 'en',
//     BY: 'be',
//     BZ: 'en',
//     CA: 'en',
//     CC: 'en',
//     CD: 'fr',
//     CF: 'fr',
//     CG: 'fr',
//     CH: 'de',
//     CI: 'fr',
//     CK: 'en',
//     CL: 'es',
//     CM: 'fr',
//     CN: 'zh',
//     CO: 'es',
//     CR: 'es',
//     CU: 'es',
//     CV: 'pt',
//     CX: 'en',
//     CY: 'el',
//     CZ: 'cs',
//     DJ: 'fr',
//     DK: 'da',
//     DM: 'en',
//     DO: 'es',
//     DZ: 'ar',
//     EC: 'es',
//     EE: 'et',
//     EG: 'ar',
//     EH: 'ar',
//     ER: 'ti',
//     ES: 'es',
//     ET: 'am',
//     FI: 'fi',
//     FJ: 'en',
//     FK: 'en',
//     FM: 'en',
//     GA: 'fr',
//     GB: 'en',
//     GD: 'en',
//     GE: 'ka',
//     GF: 'fr',
//     GG: 'en',
//     GH: 'en',
//     GI: 'en',
//     GL: 'kl',
//     GM: 'en',
//     GN: 'fr',
//     GP: 'fr',
//     GQ: 'es',
//     GR: 'el',
//     GS: 'en',
//     GT: 'es',
//     GU: 'en',
//     GW: 'pt',
//     GY: 'en',
//     HK: 'zh',
//     HM: 'en',
//     HN: 'es',
//     HT: 'fr',
//     IE: 'en',
//     IL: 'he',
//     IM: 'en',
//     IN: 'hi',
//     IO: 'en',
//     IQ: 'ar',
//     IR: 'fa',
//     IT: 'it',
//     JE: 'en',
//     JM: 'en',
//     JO: 'ar',
//     JP: 'ja',
//     KE: 'sw',
//     KG: 'ky',
//     KH: 'km',
//     KI: 'en',
//     KM: 'ar',
//     KN: 'en',
//     KP: 'ko',
//     KR: 'ko',
//     KW: 'ar',
//     KY: 'en',
//     KZ: 'kk',
//     LA: 'lo',
//     LB: 'ar',
//     LC: 'en',
//     LI: 'de',
//     LK: 'si',
//     LR: 'en',
//     LS: 'en',
//     LU: 'lb',
//     LY: 'ar',
//     MA: 'ar',
//     MC: 'fr',
//     MD: 'ru',
//     ME: 'sq',
//     MF: 'fr',
//     MG: 'mg',
//     MH: 'en',
//     ML: 'fr',
//     MM: 'my',
//     MO: 'zh',
//     MP: 'ch',
//     MQ: 'fr',
//     MR: 'ar',
//     MS: 'en',
//     MT: 'mt',
//     MU: 'mfe',
//     MV: 'dv',
//     MW: 'en',
//     MX: 'es',
//     MY: 'ms',
//     MZ: 'pt',
//     NA: 'en',
//     NC: 'fr',
//     NE: 'fr',
//     NF: 'en',
//     NG: 'en',
//     NI: 'es',
//     NO: 'nb',
//     NP: 'ne',
//     NR: 'na',
//     NU: 'niu',
//     NZ: 'mi',
//     OM: 'ar',
//     PA: 'es',
//     PE: 'es',
//     PF: 'fr',
//     PG: 'en',
//     PH: 'en',
//     PK: 'en',
//     PM: 'fr',
//     PN: 'en',
//     PR: 'es',
//     PS: 'ar',
//     PW: 'en',
//     PY: 'es',
//     QA: 'ar',
//     RE: 'fr',
//     RS: 'sr',
//     RW: 'rw',
//     SA: 'ar',
//     SB: 'en',
//     SC: 'fr',
//     SD: 'ar',
//     SE: 'sv',
//     SG: 'en',
//     SH: 'en',
//     SI: 'sl',
//     SJ: 'no',
//     SL: 'en',
//     SM: 'it',
//     SN: 'fr',
//     SO: 'so',
//     SR: 'nl',
//     ST: 'pt',
//     SS: 'en',
//     SV: 'es',
//     SY: 'ar',
//     SZ: 'en',
//     TC: 'en',
//     TD: 'fr',
//     TF: 'fr',
//     TG: 'fr',
//     TJ: 'tg',
//     TK: 'tkl',
//     TL: 'pt',
//     TM: 'tk',
//     TN: 'ar',
//     TO: 'en',
//     TT: 'en',
//     TV: 'en',
//     TW: 'zh',
//     TZ: 'sw',
//     UA: 'uk',
//     UG: 'en',
//     UM: 'en',
//     US: 'en',
//     UY: 'es',
//     UZ: 'uz',
//     VA: 'it',
//     VC: 'en',
//     VE: 'es',
//     VG: 'en',
//     VI: 'en',
//     VN: 'vi',
//     VU: 'bi',
//     WF: 'fr',
//     WS: 'sm',
//     YE: 'ar',
//     YT: 'fr',
//     ZA: 'zu',
//     ZM: 'en',
//     ZW: 'en',
// } as const;