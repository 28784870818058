import { SalesPerson } from "../models";

export const getAdminInfos = async (token: string) => {
    return fetch('https://api.sellsy.com/v2/staffs/me', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((res) => {
            if (res.status === 401) {
                throw new Error('Unauthorized');
            }
            return res.json();
        }
        )
        .then((data) => {
            return {
                id: data.id,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phone: data.phone_number,
                role: 'salesperson',
                token: token,
            } as SalesPerson;
        })
        .catch((err) => {
            throw err;
        });
}


export const getTokensFromCode = async (code: string, codeVerifier: string) => {
    return fetch('/api/sellsy/tokens', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            code: code,
            codeVerifier: codeVerifier,
            grantType: 'authorization_code',
        }),
    })
}


export const getTokensFromRefreshToken = async (refreshToken: string) => {
    return fetch('/api/sellsy/tokens', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            refreshToken: refreshToken,
            grantType: 'refresh_token',
        }),
    })
}
