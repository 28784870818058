import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { Order } from '../models';
import { OrderDTO } from '../models/order';

export const orderApi = createApi({
    reducerPath: 'orderApi',
    tagTypes: ['Order'],
    baseQuery: fakeBaseQuery(),
    endpoints: (builder) => ({
        getClientOrders: builder.query<OrderDTO[], string>({
            queryFn: async (clientId) => {                
                return fetch(`/api/client/${clientId}/orders`)
                    .then((response) => response.json())
                    .then((data) => {                                                
                        return {
                            data: data as OrderDTO[],
                        }
                    })
                    .catch((error) => {
                        return {
                            error: error.message,
                        };
                    });
            },
            providesTags: (result) => result ? result.map(({ id }) => ({ type: 'Order' as const, id: id })) : [],
        }),
        getOrder: builder.query<Order | null, string>({
            queryFn: async (id) => {
                return {
                    data: null,
                };
            },
            providesTags: (result) => result ? [{ type: 'Order', id: result.document.thirdid }] : [],
        }),
    }),
});

export const { useGetClientOrdersQuery, useGetOrderQuery } = orderApi;