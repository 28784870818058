import { Client } from '../models';
import { RegisterStatus } from '../models/registerStatus';
import { parseClient } from '../utils/parseClient';
import { parseClientFromSearch } from '../utils/parseClientFromSearch';
import { post } from './baseService';

export const getAllClients = async (token: string, offset: string) => {
	return fetch(
		`https://api.sellsy.com/v2/companies?order=created_at&embed[]=cf.178794&embed[]=cf.181433&embed[]=cf.181434&embed[]=cf.181435&embed[]=cf.181436&embed[]=cf.181438&embed[]=cf.181439&embed[]=cf.181440&embed[]=cf.181441&embed[]=cf.181508&embed[]=cf.181509&embed[]=cf.184437&embed[]=invoicing_address&embed[]=delivery_address&embed[]=main_contact&direction=desc&limit=50&offset=${offset}`,
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	)
		.then((response) => response.json())
		.then((data) => {
			return {
				clients: data.data.map((client: any) => parseClient(client)),
				pagination: data.pagination,
			};
		})
		.catch((error) => console.log('error', error));
};

export const createClient = async (client: any, status: RegisterStatus) => {
	return post('/api/client', { client, status });
};

export const getClientCustomFields = async (token: string): Promise<any> => {
	return fetch('https://api.sellsy.com/v2/custom-fields/search?field[]=id&field[]=code&field[]=parameters[].items&field[]=mandatory&field[]=type', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify({
			filters: {
				related_object: 'client',
			},
		}),
	});
};

export const getRateCategory = (id: string, token: string) => {
	return fetch(`https://api.sellsy.com/v2/rate-categories/${id}`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export const getTax = (id: string, token: string) => {
	return fetch(`https://api.sellsy.com/v2/taxes/${id}`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export const searchClients = async (name: string) => {
	return fetch(`/api/search/${name}`)
		.then((res) => {
			if (res.ok) {
				console.log('[clientSearch]: OK');
				return res.json();
			}
			console.log('[clientSearch]: KO');
			return res.text().then((text) => {
				console.log(text);
				throw new Error('Error');
			});
		})
		.then((res) => {
			console.log('SEARCH : ', res);

			return res.map((client: any) => parseClientFromSearch(client));
		});
	// .catch((err) => {
	// 	console.log(err);
	// })
};

export const updateCommercialStatus = async (clientId: number, statusId: number, token: string) => {
	return fetch(`https://api.sellsy.com/v2/companies/${clientId}/custom-fields`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify([
			{
				id: 181433,
				value: statusId,
			},
		]),
	}).then((res) => {
		if (res.ok) {
			return res.json();
		}
		throw new Error('Error updating commercial status');
	});
};

export const clientExists = async (token: string, email?: string, siret?: string, name?: string): Promise<Client | null> => {
	const url = 'https://api.sellsy.com/v2/companies/search?limit=1&embed[]=main_contact&embed[]=invoicing_address&embed[]=delivery_address&embed[]=cf.178794&embed[]=cf.181433&embed[]=cf.181434&embed[]=cf.181435&embed[]=cf.181436&embed[]=cf.181438&embed[]=cf.181439&embed[]=cf.181440&embed[]=cf.181441&embed[]=cf.181508&embed[]=cf.181509&embed[]=cf.184437';
	const config = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};

	if (!siret && !email && !name) {
		return null;
	}

	return fetch(url, {
		...config,
		body: JSON.stringify({
			filters: {
				type: ['client'],
				...(siret ? { siret: siret } : email ? { email: email } : { name: name }),
			},
		}),
	})
		.then((res) => res.json())
		.then((res: any) => {
			if (res.data[0]) {
				// return parseClient(res.data[0]) as Client;
			}
			return null;
		})
		.catch(() => {
			return null;
		})
};
