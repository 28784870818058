import { devices } from './../../constants/mediaQueries';
import styled from 'styled-components';

export const LogoImg = styled.img`
	width: 150px;

	@media ${devices.tablet} {
		width: 200px;
	}
`;

export const CartIncrementWrapper = styled.div`
	display: flex;
	align-items: center;
	border-radius: 25px;
	background-color: rgba(0, 0, 0, 0.06);
	color: #f7c200;
	justify-self: flex-end;
	align-self: flex-end;
	padding: 0;
	font-size: 1.1em;
	overflow: hidden;
	width: 100%;
	height: 44px;
	max-width: 200px;
	justify-content: space-between;

	@media ${devices.tablet} {
		margin-top: unset;
	}
`;


export const Insert = styled.div`
	display: flex;
	position: relative;
	align-self: center;
	border-radius: 8px;
	padding: 12px 24px;	
	margin-top: 16px;
	width: 100%;
`;

export const ImagesSliderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 130;
	backdrop-filter: blur(5px);

	.carousel-root{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.control-arrow {
		background: none !important;
		opacity: 1 !important;
		height: 50px;
		top: 50% !important;
		transform: translateY(-50%); 
	}

	.dot {
		background: #f7c200 !important;
	}

	.control-next{
		right: 16px !important;
	}

	.control-prev{
		left: 16px !important;
	}


	.carousel .control-next.control-arrow:before {
		content: '';
		border: solid #f7c200;
		border-width: 0 4px 4px 0;
		display: inline-block;
		padding: 14px;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
	}

	.carousel .control-prev.control-arrow:before {
		content: '';
		border: solid #f7c200;
		border-width: 0 4px 4px 0;
		display: inline-block;
		padding: 14px;
		transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
	}
`;