import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isUserAndCompany } from '../../models/extUser';
import { useGetCartQuery, useSetSavedForLaterMutation, useUpdateCartMutation } from '../../reducers/cartApi';
import { useGetProductsByIdsQuery } from '../../reducers/productApi';
import { Container, MainContainer } from '../../styles/containers';
import { H2, HTLabel, HTPrice, Muted, StyledSpan } from '../../styles/texts';
import { useToast, useUserAuth } from '../../utils/hooks';
import { useClientPricing } from '../../utils/hooks/useClientPricing';
import { useDebounce } from '../../utils/hooks/useDebounce';
import { useSessionSelector } from '../../utils/hooks/useSessionSelector';
import { EmptyCardSpan, SeeProductsButton } from '../Navbar/CartMenu/style';
import WindowLoading from '../other/WindowLoading';
import { GlassesRow } from './GlassesRow';
import { BorderBottom, OrderButton, RecapTitle, SubTotalSpan, TotalContainer, TotalPrice, TotalSpan } from './style';

const Cart = () => {
	const { user } = useUserAuth();
	const { t, i18n } = useTranslation();
	const language = i18n.language;
	const navigate = useNavigate();
	const { data: cart, isLoading: isLoadingCart, isSuccess: isSuccessCart } = useGetCartQuery((user?.currentCompany?.id || '').toString());
	const { data: products, isLoading: isLoadingProducts, isSuccess: isSuccessProducts } = useGetProductsByIdsQuery(Object.keys(cart || {}));
	const [currentCart, setCurrentCart] = useState(cart || {});
	const [updateCart, result] = useUpdateCartMutation();
	const [setSavedForLater] = useSetSavedForLaterMutation();
	const { isMobile } = useSessionSelector();
	const debounce = useDebounce();
	const { toast } = useToast();
	const { getProductPrice } = useClientPricing();
	const { htTotal, vatAmount } = useMemo(() => {
		const total = (products || []).reduce((acc, p) => {
			const price = isUserAndCompany(user) ? getProductPrice(p) : null;
			return acc + (price?.[user?.currentCompany?.rateCategory?.includesTaxes ? 'inclTax' : 'exclTax'] || 0) * (currentCart?.[p.id]?.quantity || 0);
		}, 0);
		const vat = user?.currentCompany?.rateCategory?.includesTaxes ? 0 : ((user?.currentCompany?.rateCategory?.taxRate || 0) / 100) * total;
		return { htTotal: total, vatAmount: vat };
	}, [products, currentCart, user?.currentCompany?.country]);

	useEffect(() => {
		if (user === null) navigate('/login', { replace: true });
	}, [user]);

	useEffect(() => {
		if (!currentCart || !user) return;
		if (JSON.stringify(cart) === JSON.stringify(currentCart)) return;
		debounce(() => {
			const savedForLater = Object.entries(cart || {})
				.filter(([key]) => !Object.keys(currentCart).includes(key))
				.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

			if (Object.keys(savedForLater).length > 0) {
				setSavedForLater({ clientId: user?.currentCompany?.id?.toString(), cart: savedForLater });
			}
			updateCart({ cart: currentCart, clientId: user?.currentCompany?.id?.toString() });
		}, 1000);
	}, [currentCart]);

	useEffect(() => {
		setCurrentCart(cart || {});
	}, [cart]);

	useEffect(() => {
		if (result.isError) {
			setCurrentCart(cart || {});
			toast({
				message: t('global.errors.updatingCart'),
				duration: 4000,
				closable: true,
			});
		}
	}, [result]);

	const handleQuantityChange = (productId: string, quantity: number) => {
		setCurrentCart((prevCart) => {
			const newCart = { ...prevCart };
			if (quantity > 0) {
				newCart[productId] = {
					addingDate: Date.now(),
					quantity: quantity,
				};
			} else {
				delete newCart[productId];
			}
			return newCart;
		});
	};

	return (
		<MainContainer>
			{(isLoadingCart || isLoadingProducts) && <WindowLoading />}
			{isSuccessCart && isSuccessProducts && (
				<Container direction='column' gap={16} width='100%' maxWidth='1200px' alignSelf='center' padding='0 0 16px 0'>
					<Container align='baseline' gap={8}>
						<H2>{t('cart.title')}</H2>
						<StyledSpan style={{ fontWeight: '400', fontSize: '1.1em', width: 'fit-content' }}>{t('cart.numberOfItems', { count: Object.values(currentCart).reduce((acc, curr) => acc + curr.quantity, 0) })}</StyledSpan>
					</Container>
					{products.length > 0 ? (
						<Container width='100%' flexWrap='wrap' gap={isMobile ? 16 : 60} justify='space-between'>
							<Container flex='1 1 450px' direction='column' gap={32} margin='0 auto'>
								{products.map((product) => (
									<Container key={product.id} gap={32}>
										<GlassesRow editableQuantity glasses={product} quantity={currentCart?.[product.id]?.quantity || 0} onQuantityChange={(n: number) => handleQuantityChange(product.id, n)} />
									</Container>
								))}
							</Container>
							<Container flex='0 1 440px' margin='0 auto'>
								{user?.currentCompany?.rateCategory && (
									<TotalContainer>
										<RecapTitle>{t('cart.totalPrice')}</RecapTitle>
										<Container width='100%' justify='space-between' align='center'>
											<SubTotalSpan>{t('cart.subTotal')}</SubTotalSpan>
											<Container align='baseline' gap={4} margin='8px 0'>
												<HTPrice>
													{t('global.price', {
														value: htTotal,
														formatParams: {
															value: {
																currency: user.currentCompany.rateCategory.currency,
																locale: language,
															},
														},
													})}
												</HTPrice>
												{!user?.currentCompany?.rateCategory?.includesTaxes && <HTLabel>{t('global.ht')}</HTLabel>}
											</Container>
										</Container>
										{!user.currentCompany.rateCategory.includesTaxes && user.currentCompany.rateCategory.taxRate > 0 && (
											<Container width='100%' justify='space-between' align='center'>
												<SubTotalSpan>{t('cart.vat', { amount: user.currentCompany.rateCategory.taxRate })}</SubTotalSpan>
												<Container align='baseline' gap={4} margin='8px 0'>
													<HTPrice>
														{t('global.price', {
															value: vatAmount,
															formatParams: {
																value: {
																	currency: user.currentCompany.rateCategory.currency,
																	locale: language,
																},
															},
														})}
													</HTPrice>
												</Container>
											</Container>
										)}
										<BorderBottom />
										<Container width='100%' justify='space-between' align='center'>
											<TotalSpan>{t('cart.totalTtc')}</TotalSpan>
											<Container align='baseline' gap={4} margin='8px 0'>
												<TotalPrice>
													{t('global.price', {
														value: htTotal + vatAmount,
														formatParams: {
															value: {
																currency: user.currentCompany.rateCategory.currency,
																locale: language,
															},
														},
													})}
												</TotalPrice>
											</Container>
										</Container>
										<OrderButton to='/checkout'>{t('cart.confirmOrder')}</OrderButton>
									</TotalContainer>
								)}
							</Container>
						</Container>
					) : (
						<Container direction='column' justify='flex-start' gap={4}>
							<EmptyCardSpan>{t('navbar.cartMenu.emptyCart')}</EmptyCardSpan>
							<Muted style={{ whiteSpace: 'nowrap' }}>Remplissez le avec la nouvelle collection !</Muted>
							<SeeProductsButton to='/products'>{t('navbar.cartMenu.seeProducts')}</SeeProductsButton>
						</Container>
					)}
				</Container>
			)}
		</MainContainer>
	);
};

export default Cart;
