import styled from 'styled-components';

export const Skeleton = styled.div<{ width?: string, height?: string, duration?: number }>`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '100%'};
    flex-shrink: 0;
    box-sizing: border-box;
    background-color: #f2f2f2;
    animation: pulse ${(props) => props.duration || 2}s infinite;
    @keyframes pulse {
        0% {
            background-color: #e3e3e3;
        }
        50% {
            background-color: #f7f7f7;
        }
        100% {
            background-color: #e3e3e3;
        }
    }
`;

export const SkeletonLine = styled(Skeleton) <{ borderRadius?: string }>`
    border-radius: ${(props) => props.borderRadius || '2px'};
    height: ${(props) => props.height || '1em'};
`;

export const SkeletonRectangle = styled(Skeleton) <{ borderRadius?: string }>`
    border-radius: ${(props) => props.borderRadius || '4px'};
`;

export const SkeletonCircle = styled(Skeleton)`
    border-radius: 50%;
`;

export const SkeletonButton = styled(Skeleton)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #f2f2f2;
    color: rgba(0, 0, 0, 0);
    padding: 12px 24px;
    height: 40px;
    white-space: nowrap;
    width: ${(props) => props.width || 'fit-content'};
`;
