import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../../../utils/hooks';

const Logout = () => {
	const { signOut } = useUserAuth();
	const navigate = useNavigate();

	useEffect(() => {
		signOut().then(() => navigate('/login'));
		// eslint-disable-next-line
	}, []);

	return <></>;
};

export default Logout;
