import { useRef } from "react";

export const useDebounce = () => {
    const timer = useRef<NodeJS.Timeout>();

    const debounce = (callback: Function, delay: number) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            callback();
        }, delay);
    };

    return debounce;
};