import styled from "styled-components";

export const DeliveryForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
`;

export const DeliveryField = styled.div<{flex?: string}>`
    display: flex;
	flex-direction: column;
	gap: 4px;
	width: 100%;
	position: relative;
    flex: ${props => props.flex || '1'};
`;

export const EditBillingAddressP = styled.p`
    margin: 0;
`;