import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();


  return (
    <div style={{
      backdropFilter:'blur(400px)',
      background:'#ae141c',
      color:'#f7c200',
      padding:"1em",width:"100vw", boxSizing: 'border-box', marginTop: 'auto',display: 'flex',flexFlow:'row wrap',justifyContent:'space-between'
      }}>

      <h1 style={{textAlign:'left',fontSize:'1em'}}>SolAmor Paris - Optical frames and Sunglasses</h1>
      <footer style={{textAlign:'right',display:'flex',flexFlow:'column wrap'}}>
        <small>&copy; Copyright {new Date().getFullYear()}, SOLAMOR SAS. {t("footer.allrights")}</small>
        <small>{t("footer.byAgree")} <Link to="/legals" style={{color:"#f7c200"}}>{t("footer.termOfUse")}</Link>.</small>
      </footer>
      
    </div>
  )
}

export default Footer