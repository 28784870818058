import { useClickOutside } from 'aaastyle';
import { ChangeEvent, ComponentPropsWithoutRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CustomFieldItem } from '../../../models';
import { ClientDTO } from '../../../models/client';
import { Container, FormField, FormGroup, FormRow } from '../../../styles/containers';
import { RegisterCheckboxInput, RegisterForm, RegisterTextInput, RequiredPlaceholder } from '../../../styles/inputs/register';
import { ErrorTitle, InputLabel, SectionTitle, SubSectionTitle } from '../../../styles/texts/register';
import { getCitiesByZip } from '../../../utils/getCitiesByZip';
import { validateAndFormatClientField } from '../../../utils/validateAndFormatClientField';
import { Option } from '../../other/MultipleChoiceSelect';
import { ButtonBar, NextButton, PrevButton } from '../Register/style';
import { CitiesDropdown } from './style';

export interface LogisticsProps extends ComponentPropsWithoutRef<'form'> {
	customFields?: { [name: string]: CustomFieldItem };
	client: ClientDTO;
	setClient: (client: ClientDTO) => void;
	updating?: boolean;
	onSubmit?: (data?: any) => void;
}

const Logistics = ({ customFields, client, setClient, updating, onSubmit, ...rest }: LogisticsProps) => {
	const formRef = useRef<HTMLFormElement>(null);
	const errorTitleRef = useRef<HTMLHeadingElement>(null);
	const [messages, setMessages] = useState<{ [name: string]: string | undefined }>({});
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [useSameAddress, setUseSameAddress] = useState<boolean>(true);
	const [cityChoices, setCityChoices] = useState<string[]>([]);
	const [showCityChoices, setShowCityChoices] = useState<boolean>(false);
	const cityFieldRef = useRef<HTMLDivElement>(null);
	useClickOutside(cityFieldRef, () => setShowCityChoices(false));
	const timeoutRef = useRef<NodeJS.Timeout>();

	const [deliveryDays, setDeliveryDays] = useState<Option[]>(
		(customFields?.['company-preferred-delivery-day'].values || []).map(({ value, id }) => ({
			value: id,
			label: t(`global.days.${value}`),
			checked: true,
		})),
	);

	const [shippingCompaniesOptions, setShippingCompaniesOptions] = useState<Option[]>(
		(customFields?.['company-shipping-company'].values || [])
			.filter((company) => company.value.split('_')[0] === client.invoicingAddress.country)
			.map((company) => ({
				label: company.value.split('_')[1],
				value: company.id,
				checked: true,
			})),
	);

	useEffect(() => {
		setShippingCompaniesOptions(() => [
			...(customFields?.['company-shipping-company'].values || [])
				.filter((company) => company.value.split('_')[0] === client.invoicingAddress.country)
				.map((company) => ({
					label: company.value.split('_')[1],
					value: company.id,
					checked: (client.customFields['company-shipping-company'].value || []).includes(company.id),
				})),
		]);
	}, [customFields, client.invoicingAddress.country]);

	useEffect(() => {
		setDeliveryDays(() => [
			...(customFields?.['company-preferred-delivery-day'].values || []).map(({ value, id }) => ({
				value: id,
				label: t(`global.days.${value}`),
				checked: (client.customFields['company-preferred-delivery-day'].value || []).includes(id),
			})),
		]);
	}, [customFields]);

	const handleZipChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (timeoutRef.current) clearTimeout(timeoutRef.current);
		setTimeout(() => {
			const zip = e.target.value;
			getCitiesByZip(zip, client.deliveryAddress.country).then((cities) => {
				setCityChoices([...cities]);
				const city = formRef.current?.elements.namedItem('deliveryCity') as HTMLInputElement;
				if (city && cities.length > 0) city.value = cities[0];
				setShowCityChoices(true);
			});
		}, 1000);
	};

	const handleSubmit = () => {
		setMessages({});
		if (formRef.current) {
			let isFormValid: boolean = true;
			let errorMessages: any = {};
			const body: any = {};

			Array.from(formRef.current.elements).forEach((element: any) => {
				const { isValid, message, value } = validateAndFormatClientField({ name: element.name, value: element.type === 'checkbox' ? element.checked : element.value });
				if (!isValid) {
					isFormValid = false;
					errorMessages = { ...errorMessages, [element.name]: message };
				}

				body[element.name] = value;
			});

			if (!isFormValid) {
				setMessages(errorMessages);
				errorTitleRef.current?.scrollIntoView({ behavior: 'smooth' });
				return;
			}
			const finalClient: ClientDTO = {
				...client,
				...body,

				deliveryAddress: {
					...client.deliveryAddress,
					city: body.shippingCity,
					zip: body.shippingZip,
					street: body.shippingAddress,
					country: client.invoicingAddress.country,
					...(body.useSameAddress && { ...client.invoicingAddress }),
				},
				customFields: {
					...client.customFields,
					'company-preferred-delivery-day': {
						...client.customFields['company-preferred-delivery-day'],
						value: deliveryDays.filter((day) => day.checked).map((day) => day.value),
					},
					'company-shipping-company': {
						...client.customFields['company-shipping-company'],
						value: shippingCompaniesOptions.filter((company) => company.checked).map((company) => company.value),
					},
					'company-eco-friendly-delivery': {
						...client.customFields['company-eco-friendly-delivery'],
						value: body.ecoFriendlyDelivery,
					},
					'company-unsold-items-reselling': {
						...client.customFields['company-unsold-items-reselling'],
						value: body.unsoldItemsReselling,
					},
				},
			};
			setClient(finalClient);
			onSubmit && onSubmit(finalClient);
		}
	};

	const handleUpdate = () => {
		setMessages({});
		if (formRef.current) {
			let isFormValid: boolean = true;
			let errorMessages: any = {};
			const body: any = {};

			Array.from(formRef.current.elements).forEach((element: any) => {
				const { isValid, message, value } = validateAndFormatClientField({ name: element.name, value: element.type === 'checkbox' ? element.checked : element.value });
				if (!isValid) {
					isFormValid = false;
					errorMessages = { ...errorMessages, [element.name]: message };
				}

				body[element.name] = value;
			});

			if (!isFormValid) {
				setMessages(errorMessages);
				errorTitleRef.current?.scrollIntoView({ behavior: 'smooth' });
				return;
			}

			onSubmit &&
				onSubmit({
					...client,
					...body,
					deliveryAddress: {
						...client.deliveryAddress,
						city: body.shippingCity,
						zip: body.shippingZip,
						street: body.shippingAddress,
						country: client.invoicingAddress.country,
					},
					customFields: {
						...client.customFields,
						'company-preferred-delivery-day': {
							...client.customFields['company-preferred-delivery-day'],
							value: deliveryDays.filter((day) => day.checked).map((day) => day.value),
						},
						'company-shipping-company': {
							...client.customFields['company-shipping-company'],
							value: shippingCompaniesOptions.filter((company) => company.checked).map((company) => company.value),
						},
						'company-eco-friendly-delivery': {
							...client.customFields['company-eco-friendly-delivery'],
							value: body.ecoFriendlyDelivery,
						},
						'company-unsold-items-reselling': {
							...client.customFields['company-unsold-items-reselling'],
							value: body.unsoldItemsReselling,
						},
					},
				});
		}
	};

	return (
		<Container direction='column'>
			<RegisterForm noValidate {...rest} ref={formRef}>
				{Object.entries(messages).length > 0 && <ErrorTitle ref={errorTitleRef}>{t('clientRegister.invalidFormTitle')}</ErrorTitle>}
				<SectionTitle>{t('clientRegister.section.shippingInformation')}</SectionTitle>
				<FormGroup>
					<SubSectionTitle>{t('clientRegister.shippingAddress', 'Adresse de livraison')}</SubSectionTitle>
					{updating || (
						<Container gap={4} flexWrap='nowrap' align='flex-start'>
							<RegisterCheckboxInput onChange={(e) => setUseSameAddress(e.target.checked)} defaultChecked={true} id='same-address-input' name='useSameAddress' type='checkbox' />
							<InputLabel htmlFor='same-address-input'>{t('clientRegister.useSameAddress')}</InputLabel>
						</Container>
					)}
					{(!updating && useSameAddress) || (
						<>
							<FormRow>
								<FormField flex='1 1 180px'>
									<InputLabel>{t('clientRegister.zipCode')}</InputLabel>
									<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
									<RegisterTextInput autoComplete='off' autoCorrect='off' spellCheck='false' invalid={'shippingZip' in messages} type='text' name='shippingZip' defaultValue={client.deliveryAddress.zip} onChange={handleZipChange} placeholder={t('clientRegister.placeholders.zipCode')} />
								</FormField>
								<FormField ref={cityFieldRef} flex='4 1 300px'>
									<InputLabel>{t('clientRegister.city')}</InputLabel>
									<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
									<RegisterTextInput autoComplete='off' autoCorrect='off' spellCheck='false' invalid={'shippingCity' in messages} onFocus={() => setShowCityChoices(true)} defaultValue={client.deliveryAddress?.city} type='text' name='shippingCity' placeholder={t('clientRegister.placeholders.city')} />
									{cityChoices.length > 0 && showCityChoices && (
										<CitiesDropdown>
											{cityChoices.map((city, index) => (
												<Container
													onClick={() => {
														(formRef.current?.elements.namedItem('shippingCity') as HTMLInputElement).value = city;
														setShowCityChoices(false);
													}}
													key={index}>
													{city}
												</Container>
											))}
										</CitiesDropdown>
									)}
								</FormField>
							</FormRow>
							<FormRow>
								<FormField>
									<InputLabel>{t('clientRegister.address')}</InputLabel>
									<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
									<RegisterTextInput autoComplete='off' autoCorrect='off' spellCheck='false' invalid={'shippingAddress' in messages} type='text' defaultValue={client.deliveryAddress.street} name='shippingAddress' placeholder={t('clientRegister.placeholders.address')} />
								</FormField>
							</FormRow>
						</>
					)}
					{/* <Container gap={4} flexWrap='nowrap' align='flex-start'>
						<RegisterCheckboxInput defaultChecked={client.customFields['company-eco-friendly-delivery'].value} id='ecoFriendlyDelivery' name='ecoFriendlyDelivery' type='checkbox' />
						<InputLabel htmlFor='ecoFriendlyDelivery'>{t('clientRegister.ecoFriendlyDelivery')}</InputLabel>
					</Container>
					<Container gap={4} flexWrap='nowrap' align='flex-start'>
						<RegisterCheckboxInput defaultChecked={client.customFields['company-unsold-items-reselling'].value} id='unsoldItemsReselling' name='unsoldItemsReselling' type='checkbox' />
						<InputLabel htmlFor='unsoldItemsReselling'>{t('clientRegister.unsoldItemsReselling')}</InputLabel>
					</Container> */}
				</FormGroup>
			</RegisterForm>

			<ButtonBar>
				{updating ? (
					<NextButton onClick={handleUpdate} type='submit'>
						{t('global.save')}
					</NextButton>
				) : (
					<>
						<PrevButton type='button' onClick={() => navigate(-1)}>
							{t('global.previous')}
						</PrevButton>
						<NextButton onClick={handleSubmit}>{t('clientRegister.createAccount')}</NextButton>
					</>
				)}
			</ButtonBar>
		</Container>
	);
};

export default Logistics;
