import { useTranslation } from 'react-i18next';
import { MainButton } from '../../../styles/buttons';
import { Container, StyledBox } from '../../../styles/containers';
import { Muted, StyledSpan } from '../../../styles/texts';
import { useSellsy } from '../../../utils/hooks';
import { FormEvent, useMemo } from 'react';
import { PaymentMethodForm } from './style';
import PaymentEntry from './PaymentEntry';

interface PaymentProps {
	onPaymentConfirmed: (paymentMethod: number) => void;
	defaultPaymentMethod?: number;
}

const Payment = ({ onPaymentConfirmed, defaultPaymentMethod }: PaymentProps) => {
	const { t } = useTranslation();
	const { customFields } = useSellsy();
	const paymentOptions = useMemo(() => {
		return (customFields?.['company-payment-method'].values || []).map(({ value, id }) => ({
			value: id,
			label: t(`clientRegister.paymentMethods.${value}`, value),
		}));
	}, []);

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const value = e.currentTarget.paymentMethod.value;
		onPaymentConfirmed(value);
	};

	return (
		<StyledBox vertical>
			<Container direction='column' gap={8} width='100%'>
				<StyledSpan style={{ fontWeight: 600, fontSize: '1.1em' }}>{t('confirmation.selectPaymentMethod')}</StyledSpan>
				<PaymentMethodForm onSubmit={handleSubmit}>
					{paymentOptions.map(({ value, label }) => (
						<PaymentEntry key={value} value={value} label={label} defaultChecked={defaultPaymentMethod === value} />
					))}
					<Container margin='16px 0 8px 0'>
						<Muted>{t('confirmation.paymentDetails')}</Muted>
					</Container>
					<MainButton type='submit'>{t('confirmation.confirmOrder')}</MainButton>
				</PaymentMethodForm>
			</Container>
		</StyledBox>
	);
};

export default Payment;
