import styled from "styled-components";
import { Form, StyledSelect, TextInput } from ".";
import SelectWithSearch from "../../components/other/SelectWithSearch/SelectWithSearch";

export const RegisterForm = styled(Form)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const RegisterTextInput = styled(TextInput)`
    width: 100%;
    font-weight: 500;
    font-size: 1em;
    font-family: "Roboto";

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &[type=number] {
        -moz-appearance: textfield;
    }
`;

export const PhoneTextInput = styled(RegisterTextInput)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;

export const RegisterCheckboxInput = styled.input`
    cursor: pointer;
	width: 18px;
	height: 18px;
    flex: 0 0 18px;
	accent-color: #F7C200;
    margin: 0;
`;


export const RegisterSelect = styled(StyledSelect) <{ invalid?: boolean, maxHeight?: string }>`
    width: 100%;
    font-size: 1em;

     & > div:nth-child(1) {
		background-color: rgba(0,0,0,0.04);
        border-color: ${(props) => props.invalid ? '#e34850' : 'rgba(144,144,144,0.3)'};
        white-space: nowrap;
        & > span {
            font-size: 1em;
        }
        & > svg {
            height: 1em;
        }
	}

    & > div:nth-child(2) {
        position: absolute;
		width: 100%;
		box-sizing: border-box;
		z-index: 100;
		background: white;
        
        & > ul {
            max-height: ${(props) => props.maxHeight ? props.maxHeight : '240px'};
        }

        & li {
            font-size: 1em;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 8px;
        }
	}
`;


export const DialCodeCountry = styled.span`
    max-width: 160px;
    overflow: hidden;
    height: fit-content;
    text-overflow: ellipsis;
    display: none;
`;

export const DialCodeNumber = styled.span`
    
`;

export const StyledDialCodeSelect = styled(SelectWithSearch) <{ invalid?: boolean, maxHeight?: string, showPlaceholder?: boolean }>`
    font-size: 1em;
	box-sizing: border-box;

	& span {
			font-size: ${(props) => props.showPlaceholder ? '0.9em' : '1em'};
			font-weight: ${(props) => props.showPlaceholder ? '400' : '500'};
			color: ${(props) => props.showPlaceholder ? '#a9a9a9' : '#000'};
		}

	& li {
		margin: 0;
		padding: 4px 12px;
	}

	& svg {
		flex: 0 0 auto;
		width: 22px;
		height: 22px;
	}
	& > div:nth-child(1) {
		padding: 4px 12px;
	}

	& > div:nth-child(2) {
		box-sizing: border-box;
		z-index: 100;
		background: white;
	}

	& ul {
		max-height: 400px;
		overflow-y: auto;
	}

	& input {
		outline: none;
		font-size: 1em;
		padding: 4px 8px;
	}


    width: 100%;
    font-size: 1em;

     & > div:nth-child(1) {
		background-color: rgba(0,0,0,0.04);
        border-color: ${(props) => props.invalid ? '#e34850' : 'rgba(144,144,144,0.3)'};
        white-space: nowrap;
        & > span {
            font-size: 1em;
        }
        & > svg {
            height: 1em;
        }
	}

    & > div:nth-child(2) {
        position: absolute;
		width: 100%;
		box-sizing: border-box;
		z-index: 100;
		background: white;
        
        & > ul {
            max-height: ${(props) => props.maxHeight ? props.maxHeight : '240px'};
        }

        & li {
            font-size: 1em;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 8px;
        }
	}

    left: 2px;

    width: 120px;

    & > div:nth-child(1) {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        overflow: hidden;
        justify-content: flex-end;
    }

    & > div:nth-child(2) {
        width: 250px;
    }

    & li {
        overflow: visible;
        white-space: nowrap;
        display: flex;
        justify-content: space-between;
        gap: 16px;
        & ${DialCodeCountry} {
           display: block; 
        }

        & ${DialCodeNumber} {
            font-weight: 400 !important;
            color: ${(props) => props.theme.grayColor};
        }
    }

`;


export const RequiredPlaceholder = styled.span`
    color: rgba(0, 0, 0, 0.4);
    font-size: 0.8em;
    font-weight: 400;
    position: absolute;
    top: 2px;
    right: 4px;
`;