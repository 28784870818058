import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Cart } from '../models/list';
import { db } from '../services/firebaseService';


export const cartApi = createApi({
    reducerPath: 'cartApi',
    tagTypes: ['Cart', 'SavedForLater'],
    baseQuery: fakeBaseQuery(),
    endpoints: (builder) => ({
        getCart: builder.query<Cart, string | undefined>({
            async queryFn(clientId) {
                if (!clientId) return { data: undefined } as any;
                return getDoc(doc(db, 'customers', clientId, 'lists', 'cart'))
                    .then((querySnapshot) => {
                        return {
                            data: querySnapshot.data() as Cart,
                        };
                    }
                    )
                    .catch((error) => {
                        return {
                            error: error.message,
                        };
                    }
                    );
            },
            providesTags: ['Cart'],
        }),
        getSavedForLater: builder.query<Cart, string | undefined>({
            async queryFn(clientId) {
                if (!clientId) return { data: undefined } as any;
                return getDoc(doc(db, 'customers', clientId, 'lists', 'savedForLater'))
                    .then((querySnapshot) => {
                        return {
                            data: querySnapshot.data() as Cart,
                        };
                    }
                    )
                    .catch((error) => {
                        return {
                            error: error.message,
                        };
                    }
                    );
            },
            providesTags: (result) => result ? Object.keys(result).map((id) => ({ type: 'SavedForLater' as const, id })) : []
        }),
        setSavedForLater: builder.mutation({
            async queryFn({ cart, clientId }) {
                return setDoc(doc(db, 'customers', clientId, 'lists', 'savedForLater'), cart)
                    .then(() => {
                        return {
                            data: cart,
                        };
                    }
                    )
                    .catch((error) => {
                        console.log(error);

                        return {
                            error: error.message,
                        };
                    }
                    );
            },
            invalidatesTags: ['SavedForLater']
        }),
        updateCart: builder.mutation({
            async queryFn({ cart, clientId }) {
                return setDoc(doc(db, 'customers', clientId, 'lists', 'cart'), cart)
                    .then(() => {
                        return {
                            data: cart,
                        };
                    }
                    )
                    .catch((error) => {
                        return {
                            error: error.message,
                        };
                    }
                    );
            },
            invalidatesTags: ['Cart']
        }),
        addToCart: builder.mutation({
            async queryFn({ productId, clientId }) {
                const docRef = doc(db, 'customers', clientId, 'lists', 'cart');
                return getDoc(docRef)
                    .then((querySnapshot) => {
                        const cart = querySnapshot.data() as Cart;
                        if (cart[productId]) {
                            cart[productId].quantity++;
                        } else {
                            cart[productId] = {
                                quantity: 1,
                                addingDate: Date.now(),
                            };
                        }
                        return setDoc(docRef, cart)
                            .then(() => {
                                return {
                                    data: cart,
                                };
                            }
                            )
                            .catch((error) => {
                                return {
                                    error: error.message,
                                };
                            }
                            );
                    }
                    )
                    .catch((error) => {
                        return {
                            error: error.message,
                        };
                    }
                    );
            },
            invalidatesTags: ['Cart']
        }),
    }),
});

export const { useGetCartQuery, useUpdateCartMutation, useGetSavedForLaterQuery, useSetSavedForLaterMutation, useAddToCartMutation } = cartApi;