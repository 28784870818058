import { Button } from 'aaastyle';
import styled from "styled-components";

export const RegisterTitle = styled.h1`
    font-size: 1.5em;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.9);
    margin: 12px 0;
    padding: 0;
    text-transform: uppercase;
`;

export const SectionTitle = styled.h3`
    font-size: 1.2em;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
    margin: 12px 0 8px 0;
    padding: 0;
`;

export const SubSectionTitle = styled.h4`
    font-size: 1em;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    margin: 8px  0 -8px 0;
    padding: 0;
`;

export const InputLabel = styled.label`
    font-size: 1em;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    position: relative;
`;

export const CompanyInfosLink = styled.a`
    font-size: 1.1em;
    font-weight: 600;
    color: ${props => props.theme.mainColor};
    text-decoration: none;
    margin: 4px 0;


    &:hover{
        opacity: 0.8;
    }
`;

export const ErrorTitle = styled.h2`
    font-size: 0.9em;
    font-weight: 500;
    color: #e34850;
    margin: 0;
    padding: 0;
    text-transform: uppercase;

`;

export const ExistsDialogButton = styled(Button)`
    min-width: 0;
    width: 100%;
    padding: 0;
    margin: 4px 0;
    border-radius:  4px;
    border-width: 2px;
`;