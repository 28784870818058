import { useTranslation } from 'react-i18next';
import logo from '../../../assets/images/solamor.png';
import { Container } from '../../../styles/containers';
import { LogoImg } from '../../../styles/containers/cart';
import { ConfirmationBox, ConfirmationBoxHeader, SummaryContainer } from '../../../styles/containers/orderConfirmation';
import { StyledSpan } from '../../../styles/texts';
import { MailingMessage, ThanksMessage } from '../../../styles/texts/orderConfirmation';
import { useSessionSelector } from '../../../utils/hooks/useSessionSelector';
import { Icon } from '../../other';

const ConfirmRegistration = () => {
	const { t } = useTranslation();
	const { isMobile } = useSessionSelector();

	return (
		<Container width='100%' padding={isMobile ? '0' : '30px 0'} justify='center'>
			<ConfirmationBox>
				<ConfirmationBoxHeader>
					<Icon style={{ margin: '24px 0' }} name='check-circle' size={96} color='#f7c200' />
					<ThanksMessage>{t('clientRegister.confirmation.title')}</ThanksMessage>
				</ConfirmationBoxHeader>

				<Container margin='24px 0' gap={24} direction='column' width='100%'>
					<SummaryContainer>
						<StyledSpan>{t('clientRegister.confirmation.text')}</StyledSpan>
					</SummaryContainer>
				</Container>
				<MailingMessage>{t('clientRegister.confirmation.mailingMessage')}</MailingMessage>
				<LogoImg style={{ alignSelf: 'center', width: '200px', margin: '24px' }} src={logo} />
			</ConfirmationBox>
		</Container>
	);
};

export default ConfirmRegistration;
