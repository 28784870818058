import { Link } from "react-router-dom";
import styled from "styled-components";

export const NotFoundTitle = styled.h1`
    font-size: 1.2em;
    font-weight: 400;
`;

export const RedirectLink = styled(Link)`
    font-size: 1em;
    font-weight: 400;
    color: #000000;
    margin: 0 0 12px 0;
    text-align: center;

    &:hover {
        opacity: 0.8;
    }
`;