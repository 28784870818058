import { useTranslation } from "react-i18next";

export const useLanguage = () => {
	const { i18n } = useTranslation();

	const changeLanguage = (locale?: string): void => {
		if (!locale) return;
		switch (locale?.slice(0, 2)) {
			case 'fr':
				i18n.changeLanguage('fr-FR');
				break;
			case 'en':
				i18n.changeLanguage('en-US');
				break;
			default:
				i18n.changeLanguage('fr-FR');
		}
	}

	const getTranslation = <T>(texts: { [lang: string]: T }) => {
		const lang = i18n.language;
		return texts[lang] || texts['fr-FR'] || texts['en-US'] || texts[0] || undefined;
	}

	return { changeLanguage, getTranslation };
}