import { useEffect} from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import {ReducerState } from '../models'
import ReactGA from "react-ga4";

interface CookiesWrapperProps{
  children: React.PropsWithChildren<any>;
}

// RGPD Complient Directly becasue no cookie and IPs
function AnalyticsWrapper (props: CookiesWrapperProps){
  const location = useLocation()
  const sessionId = useSelector((state: ReducerState) => state.session.sessionId);
  useEffect(() => {
      ReactGA.initialize('G-H70DNQVP86',{  // Debug : G-WFQ8NXMMWW Prod : G-H70DNQVP86
        gtagOptions:{
          client_storage: 'none',
          anonymize_ip: true,
          // debug_mode:true,
          client_id:sessionId,
      }});

    console.log(`🍪 Setup`);
  }, [sessionId]);

  useEffect(() => {
    let path = location.pathname + location.search
    ReactGA.send({ hitType: "pageview", page: path });
    console.log(`🍪 Page View: ${path}`);
  }, [location]);
  return props.children;
}

function analyticsEvent (category:string='nocategory',action:string, label:string|undefined,value:number=0){
  ReactGA.event({
    category: category,
    action: action,
    label: label, // optional
    value: value, // optional, must be a number
    // nonInteraction: true, // optional, true/false
    // transport: "xhr", // optional, beacon/xhr/image
  });
}

export { AnalyticsWrapper , analyticsEvent}

// -------------------------------
// Commented because useless TODAY
// -------------------------------

// import { useTranslation } from "react-i18next";
// import {LOCAL_COOKIES_STATUS} from '../constants/keys'
// import { ExistsDialogFooter, StyledExistsDialog } from "../styles/containers/register";
// import { ExistsDialogButton  } from '../styles/texts/register';
// import { sessionSlice } from '../reducers';

// export type CookieStatus = 'UNDIFINED' | 'REFUSED' | 'ACCEPTED' 
// const CookiesPopup = () => {
//   const dispatch = useDispatch();
// 	const {  isCookieSetup } = useSelector((state: ReducerState) => state.session);

//   const { t } = useTranslation();
//   const [accepted, setAccepted] = useState<CookieStatus>(localStorage.getItem(LOCAL_COOKIES_STATUS) as CookieStatus ?? 'UNDIFINED') ;
//   // useEffect(() => {
//   //   console.log(`🍪 View Init :${accepted} and setup :${isCookieSetup}`)
//   // }, []);
//   useEffect(() => {
//     console.log(`🍪 Update : ${accepted} and setup :${isCookieSetup}`)
//     localStorage.setItem(LOCAL_COOKIES_STATUS,accepted)
    
//     if (accepted === 'ACCEPTED'){
//       if(!isCookieSetup){
//         ReactGA.initialize('G-WFQ8NXMMWW'); // Debug : G-WFQ8NXMMWW Prod : G-H70DNQVP86
//         ReactGA.set({ appVersion: '1.0'});
//         dispatch(sessionSlice.actions.setCookieSetup(true))
//         console.log('🍪 ACCEPTED and Setup');
        
//       } else {
//         console.log('🍪 ACCEPTED and already Setup');
//       }

//     } else {
//       console.log(`🍪  Refused or Undifined - Analytics is disabled`);
//     }
//   }, [accepted,isCookieSetup,dispatch]);

//   return (
      
//       accepted === 'UNDIFINED' ? 
//       <StyledExistsDialog>
//             <ExistsDialogFooter>
//                 <ExistsDialogButton variant='outlined' onClick={() => setAccepted('REFUSED')}>{t('cookies.no')}</ExistsDialogButton>
//                 <ExistsDialogButton onClick={() => setAccepted('ACCEPTED')}>{t('cookies.yes')}</ExistsDialogButton>
//             </ExistsDialogFooter>
//         </StyledExistsDialog>
        
//         : <></>
//   )
// }


// Commented because useless TODAY
// function CookiesWrapper (props: CookiesWrapperProps){
//   const location = useLocation()
//   const {  isCookieSetup } = useSelector((state: ReducerState) => state.session);

//   useEffect(() => {
//     if (!isCookieSetup) {
//       ReactGA.initialize('G-WFQ8NXMMWW'); // Debug : G-WFQ8NXMMWW Prod : G-H70DNQVP86
//       let path = location.pathname + location.search
//       ReactGA.send({ hitType: "pageview", page: path });
//       console.log(`🍪 Page View: ${path}`);
//     }
//   }, [isCookieSetup, location]);
//   return props.children;
// }




