import { motion } from "framer-motion";
import styled from "styled-components";
import { Container } from "../../../styles/containers";

export const CitiesDropdown = styled(motion.div).attrs({
    initial: { opacity: 0, y: -10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -10 },
    transition: { duration: 0.2 },
})`
    display: flex;
    position: absolute;
    top: 58px;
    flex-direction: column;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    z-index: 100;
    max-height: 200px;
    overflow-y: auto;

    & > ${Container} {
        padding: 4px 8px;
        cursor: pointer;
       
        &:hover {
            background: #eee;
        }
    }
`;