import { MaterialSymbol } from "material-symbols";
import { ColorTheme } from "../../../models/colorTheme";
import { StyledSymbol } from "./style";
import { HTMLMotionProps } from "framer-motion";

interface SymbolProps extends HTMLMotionProps<"span"> {
    name: MaterialSymbol;
    color?: string | ColorTheme;
    size?: number;
    weight?: number;
    fill?: boolean;
    variant?: 'outlined' | 'rounded' | 'sharp';
}

const Symbol = (props: SymbolProps) => {
    const { name, variant, ...rest } = props;
    
	return <StyledSymbol {...rest} className={`material-symbols-outlined`}>{name}</StyledSymbol>;
};

export default Symbol;
