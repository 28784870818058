import styled from "styled-components";

export const BackArrowButton = styled.button`
    cursor: pointer;
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;


    &:hover {
        opacity: 0.7;
    };
`;