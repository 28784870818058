import { configureStore } from '@reduxjs/toolkit';
import { cartApi } from './cartApi';
import { favoritesApi } from './favoritesApi';
import { productApi } from './productApi';
import sessionSlice from './sessionSlice';
import { orderApi } from './orderApi';

const store = configureStore({
	reducer: {
		session: sessionSlice.reducer,
		// cart: cartSlice.reducer,
		[productApi.reducerPath]: productApi.reducer,
		[cartApi.reducerPath]: cartApi.reducer,
		[favoritesApi.reducerPath]: favoritesApi.reducer,
		[orderApi.reducerPath]: orderApi.reducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(productApi.middleware, cartApi.middleware, favoritesApi.middleware, orderApi.middleware),
});

export default store;