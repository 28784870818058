import { RegisterTextInput } from './register';
import styled from "styled-components";

export const QuantityInput = styled.input`
	color: #F7C200;
	outline: none;
	border: none;
	width: 60px;
	text-align: center;
	font-size: 1.2em;
	font-weight: 500;
	background-color: transparent;
	text-decoration: none;
	padding: 0;
	text-overflow: ellipsis;

	&:-webkit-outer-spin-button, &:-webkit-inner-spin-button {
		margin: 0;
	}

	&[type=number]{
		-moz-appearance: textfield;
	}
`;

export const ClientPermissionInput = styled.input`
	cursor: pointer;
	width: 18px;
	height: 18px;
	accent-color: #F7C200;
`;

export const PriceFilterInput = styled(RegisterTextInput)`
	width: 4.5em;
	box-sizing: border-box;
`;