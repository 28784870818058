import { Container } from '../../../styles/containers';
import { SkeletonLine, SkeletonRectangle } from '../../../styles/loading/skeleton';
import { CartIncrementWrapper } from '../../Cart/style';
import { GlassesTileContainer } from './style';

const GlassesTileSkeleton = () => {
	return (
		<GlassesTileContainer>
			<Container direction='column' justify='space-between' flex='1 1 170px' padding='8px 0'>
				<SkeletonRectangle borderRadius='12px' width='100%' height='300px' />
				<Container direction='column' gap={8} flex='1' marginTop='12px'>
					<SkeletonLine height='2em' width='120px' />
					<Container flexWrap='wrap' gap={8}>
						<SkeletonLine width={'100px'} borderRadius='200px' height='1.4em' />
						<SkeletonLine width={'100px'} borderRadius='200px' height='1.4em' />
						<SkeletonLine width={'100px'} borderRadius='200px' height='1.4em' />
						<SkeletonLine width={'100px'} borderRadius='200px' height='1.4em' />
						<SkeletonLine width={'100px'} borderRadius='200px' height='1.4em' />
					</Container>

					<Container align='baseline' gap={4} margin='8px 0 16px 0'>
						<SkeletonLine width='130px' height='1.6em' />
					</Container>
				</Container>

				<Container width='100%' justify='flex-start' align='center' gap={16}>
					<SkeletonRectangle width={'42px'} height={'42px'} />
					<CartIncrementWrapper onClick={(e) => e.stopPropagation()}>
						<SkeletonRectangle width='200px' height='44px' />
					</CartIncrementWrapper>
				</Container>
			</Container>
		</GlassesTileContainer>
	);
};

export default GlassesTileSkeleton;
