import { uuidv4 } from '@firebase/util';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SalesPerson, SessionState } from '../models';
import { IconName } from './../components/other/Icon';
import { SellsyData } from './../models/reducerStates';

const initialState: SessionState = {
	isMobile: false,
	sessionId: uuidv4(),
	clientId: null,
	admin: null,
	toast: null,
	sellsyData: {
		customFields: undefined
	}
};

const sessionSlice = createSlice({
	name: 'session',
	initialState: initialState,
	reducers: {
		setIsMobile: (state, action: PayloadAction<boolean>) => {
			state.isMobile = action.payload;
		},

		setClientId: (state, action: PayloadAction<number | null>) => {
			state.clientId = action.payload;
		},

		setAdmin: (state, action: PayloadAction<SalesPerson | null>) => {
			state.admin = action.payload;
		},
		setToast: (state, action: PayloadAction<{ message: string, icon?: IconName, color?: string }>) => {
			state.toast = action.payload;
		},
		setSellsyData: (state, action: PayloadAction<SellsyData>) => {
			state.sellsyData = action.payload;
		}
	},
});

export default sessionSlice;
