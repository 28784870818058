import styled from "styled-components";
import { MainButton } from "../../../styles/buttons";

export const RegisterContainer = styled.div``;

export const ButtonBar = styled.div`
    display: flex;
    gap: 16px;
`;

export const NextButton = styled(MainButton)`
    width: fit-content;
    margin-left: auto;
`;

export const PrevButton = styled(MainButton)`
    width: fit-content;
    margin-right: auto;
`;