import { Product } from '../models/product';
import { getProductsFromData } from '../utils';
import { getProductFromData } from '../utils/getProductsFromData';

// liste de tous les produits
export const getAllProducts = async (): Promise<Product[]> => {
	return fetch('/api/product')
		.then((res) => res.json())
		.then((data: any) => getProductsFromData(data));
};

export const getProduct = async (id: string): Promise<Product> => {
	return fetch(`/api/product/${id}`)
		.then((res) => res.json())
		.then((data: any) => {
			return getProductFromData(data)
		});
}