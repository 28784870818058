import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { deleteField, doc, updateDoc, setDoc, getDoc } from 'firebase/firestore';
import { List } from '../models/list';
import { db } from '../services/firebaseService';
export const favoritesApi = createApi({
    reducerPath: 'favoritesApi',
    baseQuery: fakeBaseQuery(),
    tagTypes: ['Favorites'],
    endpoints: (builder) => ({
        getAllFavorites: builder.query<List, string>({
            async queryFn(clientId) {
                const docRef = doc(db, 'customers', clientId, 'lists', 'favorites');                
                if(!await getDoc(docRef).then((doc) => doc.exists())) {                                                                                
                    return await setDoc(docRef, {})
                        .then(() => {
                            return {
                                data: {},
                            };
                        }
                        )
                        .catch((error) => {
                            console.log(error);

                            return {
                                error: error.message,
                            };
                        }
                        );
                }
                return await getDoc(docRef)
                    .then((querySnapshot) => {
                        return {
                            data: querySnapshot.data() as List,
                        };
                    }
                    )
                    .catch((error) => {
                        return {
                            error: error.message,
                        };
                    }
                    );
            },
            providesTags: ['Favorites'],
        }),
        addFavorite: builder.mutation<string, { clientId: string, productId: string }>({
            async queryFn({ clientId, productId }) {                
                const docRef = doc(db, 'customers', clientId, 'lists', 'favorites');
                if(!await getDoc(docRef).then((doc) => doc.exists())) {                                                            
                    return await setDoc(docRef, {
                        [productId]: {
                            addingDate: Date.now(),
                        },
                    })
                        .then(() => {
                            return {
                                data: productId,
                            };
                        }
                        )
                        .catch((error) => {
                            console.log(error);

                            return {
                                error: error.message,
                            };
                        }
                        );
                }
                return await updateDoc(docRef, {
                    [productId]: {
                        addingDate: Date.now(),
                    },
                })
                    .then(() => {
                        return {
                            data: productId,
                        };
                    }
                    )
                    .catch((error) => {
                        console.log(error);

                        return {
                            error: error.message,
                        };
                    }
                    );
            },
            invalidatesTags: ['Favorites'],
        }),
        removeFavorite: builder.mutation<string, { clientId: string, productId: string }>({
            async queryFn({ clientId, productId }) {
                const docRef = doc(db, 'customers', clientId, 'lists', 'favorites');
                return await updateDoc(docRef, {
                    [productId]: deleteField(),
                })
                    .then(() => {
                        return {
                            data: productId,
                        };
                    }
                    )
                    .catch((error) => {
                        return {
                            error: error.message,
                        };
                    }
                    );
            },
            invalidatesTags: ['Favorites'],
        }),
    }),
})

export const { useGetAllFavoritesQuery, useAddFavoriteMutation, useRemoveFavoriteMutation } = favoritesApi;