import { Button } from 'aaastyle';
import CSS from 'csstype';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import i18n from '../i18nextInit';
import { Locale } from '../models';

let sections = ['press', 'instagram', 'sales', 'helpdesk'];
const ContactList = () => {

	let styleBox: CSS.Properties = {
		display: 'flex',
		flexFlow: 'row wrap',
		justifyContent: 'space-between',
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', maxWidth: '800px' }}>
			{/* <div style={{display:'flex',flexDirection:'row',maxWidth:'800px',color:'#ae141c'}}>
        <BackArrow color='#ae141c' />
        <h1>{t("contact.title")}</h1>
      </div> */}
			<div style={styleBox}>
				{sections.map((section, i) => (
					<Contact section={section} />
				))}
			</div>
		</div>
	);
};

interface ContactProps {
	section: string;
}

const Contact = (props: ContactProps) => {
	const { t } = useTranslation();
	const language = i18n.language as Locale;
	const [markdown, setMarkdown] = useState('');

	useEffect(() => {
		import(`../assets/texts/${language}/contact-${props.section}.md`)
			.then((md) => {
				fetch(md.default)
					.then((response) => response.text())
					.then((text) => setMarkdown(text));
			})
			.catch(() => {
				import(`../assets/texts/en-US/contact-${props.section}.md`).then((md) => {
					fetch(md.default)
						.then((response) => response.text())
						.then((text) => setMarkdown(text));
				});
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let styleBox: CSS.Properties = {
		backgroundColor: '#ffffff',
		position: 'relative',
		height: 'fit-content',
		borderRadius: '12px',
		boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%)',
		margin: '20px',
		flex: '1 0 40%',
		color: 'black',
		textDecoration: 'none',
		display: 'flex',
		flexFlow: 'column nowrap',
		padding: '16px',
	};
	return (
		<a style={styleBox} href={t(`contact.${props.section}.link`)} target='_blank' rel='noopener noreferrer'>
			<h2 style={{ margin: '0px' }}>{t(`contact.${props.section}.title`)}</h2>
			<ReactMarkdown children={markdown} />
			<Button style={{ margin: '0px' }}>{t(`contact.${props.section}.button`)}</Button>
		</a>
	);
};

export default ContactList;
