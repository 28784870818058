import { WindowLoadingSpinner, WindowLoadingWrapper } from '../../styles/loading';

const WindowLoading = () => {
	return (
		<WindowLoadingWrapper>
			<WindowLoadingSpinner />
		</WindowLoadingWrapper>
	);
};

export default WindowLoading;
