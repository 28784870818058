import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NotFoundIcon } from '../../assets/icons/404.svg';
import logo from '../../assets/images/solamor.png';
import { MainContainer, NotFoundIconContainer } from '../../styles/containers/notFound';
import { LogoImg } from '../../styles/images/notFound';
import { NotFoundTitle, RedirectLink } from '../../styles/texts/notFound';

const NotFound = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			navigate('/');
		}, 100000);
		// eslint-disable-next-line
	}, []);

	return (
		<MainContainer>
			<LogoImg src={logo} alt='logo solamor' />
			<NotFoundIconContainer>
				<NotFoundIcon />
			</NotFoundIconContainer>
			<NotFoundTitle>{t('notFound.title')}</NotFoundTitle>
			<RedirectLink to='/'>{t('notFound.link')}</RedirectLink>
		</MainContainer>
	);
};

export default NotFound;
