import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
// import { Glasses } from '../models/product';
import { Product } from '../models/product';
import { getAllProducts } from '../services';
import { getProduct } from '../services/productService';


export const productApi = createApi({
	reducerPath: 'productApi',
	baseQuery: fakeBaseQuery(),
	tagTypes: ['Glasses'],
	keepUnusedDataFor: 600,
	endpoints: (builder) => ({
		getAllProducts: builder.query<Product[], void>({
			async queryFn() {
				return getAllProducts()
					.then((products) => {
						return {
							data: products,
						}
					})
					.catch((error) => {
						return {
							error: error.message,
						}
					});
			},
			providesTags: ['Glasses'],
		}),
		getProduct: builder.query<Product | undefined, string>({
			async queryFn(id) {
				return getProduct(id)
					.then((product) => {
						return {
							data: product,
						}
					})
					.catch((error) => {
						return {
							error: error.message,
						}
					});
			},
			providesTags: (result) => result ? [{ type: 'Glasses' as const, id: result.id }] : [],
		}),
		getProductsByIds: builder.query<Product[], string[]>({
			async queryFn(ids) {
				return Promise.all(ids.map((id) => getProduct(id)))
					.then((products) => {

						return {
							data: products
						}
					})
					.catch((error) => {
						return {
							error: error.message,
						}
					});
			},
			providesTags: (result) => result ? result.map(({ id }) => ({ type: 'Glasses' as const, id })) : [],
		}),
	}),
});


export const { useGetAllProductsQuery, useGetProductQuery, useGetProductsByIdsQuery } = productApi;

