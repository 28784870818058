import { FormEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { devices } from '../../../constants/mediaQueries';
import { MainButton } from '../../../styles/buttons';
import { Container, FormField, FormGroup, FormRow, MainContainer } from '../../../styles/containers';
import { Form, LoginInput } from '../../../styles/inputs';
import { ErrorTitle, InputLabel } from '../../../styles/texts/register';
import { useUserAuth } from '../../../utils/hooks';
import WindowLoading from '../../other/WindowLoading';
import { NewClientLabel, RegisterLink } from '../../Navbar/ProfileMenu/style';

const LoginContainer = styled(MainContainer)`
	min-height: 0;
	height: 100%;
	flex: 0 1 600px;
	display: flex;
	margin: 0;
	box-sizing: border-box;

	& > ${Form} {
		margin: 32px;
	}

	@media ${devices.tablet} {
		top: 10%;
		height: fit-content;
	}
`;

const Login = () => {
	const formRef = useRef<HTMLFormElement>(null);
	const { signIn, user } = useUserAuth();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		if (user) navigate('/');
	}, [user]);

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!formRef.current) return;
		const [email, password] = Array.from(formRef.current.elements)
			.slice(0, 2)
			.map((element: any) => element.value);
		setLoading(true);
		setError(null);
		signIn(email, password)
			.then(() => {
				navigate('/');
			})
			.catch((err) => {
				console.log('Login error : ', err);

				switch (err.code) {
					case 'auth/user-not-found':
					case 'auth/invalid-email':
					case 'auth/wrong-password':
						setError('incorrectEmailOrPassword');
						break;
					case 'auth/user-disabled':
						setError('accountDisabled');
						break;
					default:
						break;
				}
			})
			.finally(() => setLoading(false));
	};

	return (
		<LoginContainer>
			{loading && <WindowLoading />}
			{/* <BackArrow title={t('login.title')} /> */}
			<Form noValidate style={{ height: '100%', display: 'flex', flexDirection: 'column' }} ref={formRef} onSubmit={handleSubmit}>
				<FormGroup>
					<FormRow>
						<FormField>
							<InputLabel>{t('login.email')}</InputLabel>
							<LoginInput invalid={error !== null} autoComplete='true' type='email' name='email' />
						</FormField>
					</FormRow>
					<FormRow>
						<FormField>
							<InputLabel>{t('login.password')}</InputLabel>
							<LoginInput invalid={error !== null} autoComplete='true' type='password' name='password' />
						</FormField>
					</FormRow>
					<Container height='2.5em'>{error && <ErrorTitle>{t(`login.errors.${error}`)}</ErrorTitle>}</Container>
				</FormGroup>
				<Container direction='column' align='flex-end' gap={16}>
					<MainButton type='submit'>{t('login.submit')}</MainButton>
					<RegisterLink to='/register'>
						<>
							<NewClientLabel>{t('navbar.profileMenu.newClient')}</NewClientLabel>
							{t('navbar.profileMenu.register')}
						</>
					</RegisterLink>
				</Container>
			</Form>
		</LoginContainer>
	);
};

export default Login;
