import { UIEvent, useState } from 'react';
import { CloseCarouselButton } from '../../../styles/buttons/cart';
import { Icon } from '../../other';
import { ProductImage } from '../style';
import { FullSceenContainer, FullScreenImage as StyledFullScreenImage, ImageRow } from './styles';

interface FullScreenImageProps {
	defaultImage?: number;
	images: string[];
	onClose?: () => void;
}

const FullScreenImage = ({ images, defaultImage, onClose }: FullScreenImageProps) => {
	const [selectedImage, setSelectedImage] = useState(defaultImage || 0);
	const handleScroll = (e: UIEvent) => {
		e.stopPropagation();
	};

	return (
		<FullSceenContainer onScroll={(e: UIEvent) => handleScroll(e)}>
			{onClose && (
				<CloseCarouselButton onClick={onClose}>
					<Icon name='close' size={28} color='rgba(0, 0, 0, 0.7)' />
				</CloseCarouselButton>
			)}
			<StyledFullScreenImage src={images[selectedImage]} />
			<ImageRow>
				{images.map((image, index) => (
					<ProductImage onMouseOver={() => setSelectedImage(index)} key={index} src={image} alt={`product-${index}`} />
				))}
			</ImageRow>
		</FullSceenContainer>
	);
};

export default FullScreenImage;
