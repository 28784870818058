import { useClickOutside } from 'aaastyle';
import { ChangeEvent, ComponentPropsWithoutRef, useCallback, useEffect, useRef, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { APE_CODES } from '../../../constants/ape';
import { COUNTRIES, FIRST_COUNTRIES } from '../../../constants/countries';
import { CURRENCY_PER_COUNTRY } from '../../../constants/currencyPerCountry';
import { CURRENCY_SYMBOLS } from '../../../constants/currencySymbols';
import { LANGUAGE_PER_COUNTRY } from '../../../constants/languagePerCountry';
import { LEGAL_STATUS } from '../../../constants/legalStatus';
import i18n from '../../../i18nextInit';
import { CustomFieldItem, Locale } from '../../../models';
import { ClientDTO } from '../../../models/client';
import { Container, FormField, FormGroup, FormRow } from '../../../styles/containers';
import { RegisterForm, RegisterSelect, RegisterTextInput, RequiredPlaceholder } from '../../../styles/inputs/register';
import { InputError, Muted, StyledSpan } from '../../../styles/texts';
import { CompanyInfosLink, ErrorTitle, InputLabel, SectionTitle, SubSectionTitle } from '../../../styles/texts/register';
import { getCitiesByZip } from '../../../utils/getCitiesByZip';
import { useLanguage } from '../../../utils/hooks';
import { validateAndFormatClientField } from '../../../utils/validateAndFormatClientField';
import { ButtonBar, NextButton } from '../Register/style';
import { CitiesDropdown } from './style';
import { companyInfosUrls } from '../../../constants/companyInfosUrls';

type SelectOption = {
	value: string | number;
	label: string;
	disabled?: boolean;
};

export type RegisterStatus = 'newClient' | 'newContact' | 'updateClient';

export interface AdministrativeProps extends ComponentPropsWithoutRef<'form'> {
	onSubmit?: (data: any) => void;
	updating?: boolean;
	customFields?: { [code: string]: CustomFieldItem };
	client: ClientDTO;
	setClient: (client: ClientDTO) => void;
}

export const Administrative = (props: AdministrativeProps) => {
	const { onSubmit, updating, customFields, client, setClient, ...rest } = props;
	const { t } = useTranslation();
	const language: Locale = i18n.language as Locale;
	const formRef = useRef<HTMLFormElement>(null);
	const navigate = useNavigate();
	const countryTranslator = new Intl.DisplayNames([language.toString()], { type: 'region' });
	const languageTranslator = new Intl.DisplayNames([language.toString()], { type: 'language' });
	const [messages, setMessages] = useState<{ [name: string]: string | undefined }>({});
	const [countryOptions, setCountryOptions] = useState<SelectOption[]>([]);
	const [apeOptions, setApeOptions] = useState<SelectOption[]>([]);
	const { changeLanguage } = useLanguage();
	const errorTitleRef = useRef<HTMLHeadingElement>(null);
	const [cityChoices, setCityChoices] = useState<string[]>([]);
	const [showCityChoices, setShowCityChoices] = useState<boolean>(false);
	const cityFieldRef = useRef<HTMLDivElement>(null);
	const timeoutRef = useRef<NodeJS.Timeout>();
	const [companyLink, setCompanyLink] = useState<string | null>(null);
	useClickOutside(cityFieldRef, () => setShowCityChoices(false));

	useEffect(() => {
		setApeOptions(APE_CODES.map((code) => ({ value: code.slice(0, 5), label: code })));

		const { countryOpt } = FIRST_COUNTRIES.reduce(
			(acc, { code }) => {
				acc.countryOpt.push({
					value: code,
					label: countryTranslator.of(code) || '',
				});
				return {
					countryOpt: acc.countryOpt.sort((a, b) => a.label.localeCompare(b.label)),
				};
			},
			{
				countryOpt: [] as any[],
			},
		);

		const { countryOpt2 } = COUNTRIES.filter((country) => !FIRST_COUNTRIES.some((c) => c.code === country.code)).reduce(
			(acc, { code, dialCode }) => {
				acc.countryOpt2.push({
					value: code,
					label: countryTranslator.of(code) || '',
				});
				acc.dialCodeOpt2.push({
					value: dialCode,
					label: (
						<>
							{dialCode} <ReactCountryFlag key={`dialCode-${code}`} countryCode={code} title='name' />
						</>
					),
				});
				return {
					countryOpt2: acc.countryOpt2.sort((a, b) => a.label.localeCompare(b.label)),
					dialCodeOpt2: acc.dialCodeOpt2,
				};
			},
			{
				countryOpt2: [] as any[],
				dialCodeOpt2: [] as any[],
			},
		);
		setCountryOptions([{ value: '', label: `- ${t('clientRegister.placeholders.selectMain')} -`, disabled: true }, ...countryOpt, { value: '', label: `- ${t('clientRegister.placeholders.selectOthers')} -`, disabled: true }, ...countryOpt2]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customFields]);

	const handleZipChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (timeoutRef.current) clearTimeout(timeoutRef.current);
		setTimeout(() => {
			const zip = e.target.value;
			getCitiesByZip(zip, client.invoicingAddress.country).then((cities) => {
				setCityChoices([...cities]);
				const city = formRef.current?.elements.namedItem('invoicingCity') as HTMLInputElement;
				if (city) city.value = cities.length > 0 ? cities[0] : '';
				setShowCityChoices(true);
			});
		}, 1000);
	};

	useEffect(() => {
		if (client.invoicingAddress.country === '' || client.customFields['company-language'].value !== null) return;
		setClient({
			...client,
			customFields: {
				...client.customFields,
				'company-currency': {
					...client.customFields['company-currency'],
					value: customFields?.['company-currency'].values.find(({ value, id }) => value === CURRENCY_PER_COUNTRY[client.invoicingAddress.country])?.id,
				},
				'company-language': {
					...client.customFields['company-language'],
					value: customFields?.['company-language'].values.find(({ value, id }) => value === LANGUAGE_PER_COUNTRY[client.invoicingAddress.country])?.id,
				},
			},
		});
		changeLanguage(LANGUAGE_PER_COUNTRY[client.invoicingAddress.country] || 'en-US');

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client.invoicingAddress.country]);

	useEffect(() => {
		if (client.name && client.name.length > 0 && client.invoicingAddress.country) {
			const url = companyInfosUrls[client.invoicingAddress.country];

			setCompanyLink(url ? url.replace(':name', client.name) : '');
		} else {
			setCompanyLink('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client.invoicingAddress.country, client.name]);

	const getPaymentMethodOptions = useCallback(() => {
		return (customFields?.['company-payment-method'].values || []).map(({ value, id }) => ({
			value: id,
			label: t(`clientRegister.paymentMethods.${value}`, value),
		}));
	}, [t, customFields]);

	const handleSubmit = () => {
		setMessages({});
		if (formRef.current) {
			let isFormValid: boolean = true;
			let errorMessages: any = {};
			const body: any = {};
			Array.from(formRef.current.elements).forEach((element: any) => {
				const { isValid, message, value } = validateAndFormatClientField({ name: element.name, value: element.value });
				if (!isValid) {
					isFormValid = false;
					errorMessages = { ...errorMessages, [element.name]: message };
				}

				body[element.name] = value;
			});
			const fields = {
				country: client.invoicingAddress.country,
				legalStatus: client.legalStatus || body['legalStatus'],
				ape: client.ape || body['ape'],
				tradeType: client.customFields['company-trade-type'].value,
				currency: client.customFields['company-currency'].value,
				language: client.customFields['company-language'].value,
			};
			Object.entries(fields).forEach(([key, value]) => {
				const { isValid, message } = validateAndFormatClientField({ name: key, value: value || '' });
				if (!isValid) {
					isFormValid = false;
					errorMessages = { ...errorMessages, [key]: message };
				}
			});

			if (!isFormValid) {
				setMessages(errorMessages);
				errorTitleRef.current?.scrollIntoView({ behavior: 'smooth' });
				return;
			}
			setClient({
				...client,
				...body,
				invoicingAddress: {
					...client.invoicingAddress,
					zip: body.invoicingZip,
					city: body.invoicingCity,
					street: body.invoicingAddress,
				},
				customFields: {
					...client.customFields,
					'company-national-id': {
						...client.customFields['company-national-id'],
						value: body['company-national-id'],
					},
					'company-sign': {
						...client.customFields['company-sign'],
						value: body['company-sign'],
					},
					'company-buying-group-membership-number': {
						...client.customFields['company-buying-group-membership-number'],
						value: body['company-buying-group-membership-number'],
					},
					'company-glassmaker-membership-number': {
						...client.customFields['company-glassmaker-membership-number'],
						value: body['company-glassmaker-membership-number'],
					},
					'company-creation-date': {
						...client.customFields['company-creation-date'],
						value: body['company-creation-date'],
					},
					'company-billing-email': {
						...client.customFields['company-billing-email'],
						value: body['billingEmail'],
					},
				},
			});
			onSubmit && onSubmit({});
			navigate('../user');
		}
	};

	const handleUpdate = () => {
		setMessages({});
		if (formRef.current) {
			let isFormValid: boolean = true;
			let errorMessages: any = {};
			const body: any = {};
			Array.from(formRef.current.elements).forEach((element: any) => {
				const { isValid, message, value } = validateAndFormatClientField({ name: element.name, value: element.value });
				if (!isValid) {
					isFormValid = false;
					errorMessages = { ...errorMessages, [element.name]: message };
				}

				body[element.name] = value;
			});
			const fields = {
				currency: client.customFields['company-currency'].value,
				language: client.customFields['company-language'].value,
			};
			Object.entries(fields).forEach(([key, value]) => {
				const { isValid, message } = validateAndFormatClientField({ name: key, value: value || '' });
				if (!isValid) {
					isFormValid = false;
					errorMessages = { ...errorMessages, [key]: message };
				}
			});

			if (!isFormValid) {
				setMessages(errorMessages);
				errorTitleRef.current?.scrollIntoView({ behavior: 'smooth' });
				return;
			}
			onSubmit &&
				onSubmit({
					...client,
					...body,
					invoicingAddress: {
						...client.invoicingAddress,
						zip: body.invoicingZip,
						city: body.invoicingCity,
						street: body.invoicingAddress,
					},
					email: body.email,
					website: body.website,
					customFields: {
						...client.customFields,
						'company-national-id': {
							...client.customFields['company-national-id'],
							value: body['company-national-id'],
						},
						'company-sign': {
							...client.customFields['company-sign'],
							value: body['company-sign'],
						},
						'company-buying-group-membership-number': {
							...client.customFields['company-buying-group-membership-number'],
							value: body['company-buying-group-membership-number'],
						},
						'company-glassmaker-membership-number': {
							...client.customFields['company-glassmaker-membership-number'],
							value: body['company-glassmaker-membership-number'],
						},
						'company-creation-date': {
							...client.customFields['company-creation-date'],
							value: body['company-creation-date'],
						},
						'company-billing-email': {
							...client.customFields['company-billing-email'],
							value: body['billingEmail'],
						},
					},
				});
		}
	};

	return (
		<Container direction='column'>
			<RegisterForm noValidate {...rest} ref={formRef}>
				{Object.entries(messages).length > 0 && <ErrorTitle ref={errorTitleRef}>{t('clientRegister.invalidFormTitle')}</ErrorTitle>}
				<Container gap={16} justify='space-between' align='baseline'>
					<SectionTitle>{t('clientRegister.section.company')}</SectionTitle>
					{!updating && companyLink && (
						<CompanyInfosLink href={companyLink} target='_blank'>
							{t('clientRegister.companyInfosLink')} <u>{t('clientRegister.companyInfosLinkHere')}</u>.
						</CompanyInfosLink>
					)}
				</Container>
				<FormGroup>
					<FormRow>
						{updating ? (
							<FormField>
								<InputLabel>{t('clientRegister.country')}</InputLabel>
								{client.invoicingAddress.country && <StyledSpan>{countryTranslator.of(client.invoicingAddress.country)}</StyledSpan>}
							</FormField>
						) : (
							<FormField>
								<InputLabel>{t('clientRegister.country')}</InputLabel>
								<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
								<RegisterSelect
									invalid={'country' in messages}
									onChange={(option: any) => setClient({ ...client, invoicingAddress: { ...client.invoicingAddress, country: option.value } })}
									defaultValue={client.invoicingAddress.country}
									showPlaceholder={!client.invoicingAddress || client.invoicingAddress.country === ''}
									placeholder={t('clientRegister.placeholders.country')}
									options={countryOptions}
								/>
								{'country' in messages && <InputError>{t('clientRegister.errors.select')}</InputError>}
							</FormField>
						)}

						{updating ? (
							<FormField>
								<InputLabel>{t('clientRegister.companyName')}</InputLabel>
								<StyledSpan>{client.name}</StyledSpan>
							</FormField>
						) : (
							<FormField>
								<InputLabel>{t('clientRegister.companyName')}</InputLabel>
								<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
								<RegisterTextInput
									autoComplete='off'
									autoCorrect='off'
									spellCheck='false'
									defaultValue={client.name}
									onChange={(e) => {
										setClient({
											...client,
											name: e.target.value,
										});
									}}
									invalid={'name' in messages}
									type='text'
									name='name'
									placeholder={t('clientRegister.placeholders.companyName')}
								/>
								{'name' in messages && <InputError>{t('clientRegister.errors.required')}</InputError>}
							</FormField>
						)}
					</FormRow>
					<FormRow>
						{updating ? (
							<FormField>
								<InputLabel>{t('clientRegister.legalStatus')}</InputLabel>
								<StyledSpan>{client.legalStatus}</StyledSpan>
							</FormField>
						) : (
							<FormField>
								<InputLabel>{t('clientRegister.legalStatus')}</InputLabel>
								<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
								{client.invoicingAddress && LEGAL_STATUS[client.invoicingAddress.country] ? (
									<>
										<RegisterSelect
											invalid={'legalStatus' in messages}
											showPlaceholder={!client.legalStatus}
											onChange={(option: any) => setClient({ ...client, legalStatus: option.value })}
											defaultValue={client.legalStatus || ''}
											placeholder={t('clientRegister.placeholders.legalStatus')}
											options={LEGAL_STATUS[client.invoicingAddress?.country].map((status: string) => ({ value: status, label: status }))}
										/>
										{'legalStatus' in messages && <InputError>{t('clientRegister.errors.select')}</InputError>}
									</>
								) : (
									<>
										<RegisterTextInput
											onChange={(e) => setClient({ ...client, legalStatus: e.target.value })}
											value={client.legalStatus || ''}
											autoComplete='off'
											autoCorrect='off'
											spellCheck='false'
											invalid={'legalStatus' in messages}
											type='text'
											defaultValue={client.legalStatus}
											name='legalStatus'
											placeholder={t('clientRegister.placeholders.legalStatus')}
										/>
										{'legalStatus' in messages && <InputError>{t('clientRegister.errors.required')}</InputError>}
									</>
								)}
							</FormField>
						)}

						{updating ? (
							<FormField>
								<InputLabel>{t('clientRegister.ape')}</InputLabel>
								<StyledSpan>{client.ape}</StyledSpan>
							</FormField>
						) : (
							<FormField>
								<InputLabel>{t('clientRegister.ape')}</InputLabel>
								<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
								{client.invoicingAddress?.country === 'FR' ? (
									<>
										<RegisterSelect invalid={'ape' in messages} showPlaceholder={!client.ape} onChange={(option: any) => setClient({ ...client, ape: option.value })} defaultValue={client.ape} placeholder={t('clientRegister.placeholders.ape')} options={apeOptions} />
										{'ape' in messages && <InputError>{t('clientRegister.errors.select')}</InputError>}
									</>
								) : (
									<>
										<RegisterTextInput
											autoComplete='off'
											autoCorrect='off'
											invalid={'ape' in messages}
											type='text'
											name='ape'
											defaultValue={client.ape}
											onChange={(e: any) => {
												setClient({ ...client, ape: e.target.value });
											}}
											placeholder={t('clientRegister.placeholders.ape')}
										/>
										{'ape' in messages && <InputError>{t('clientRegister.errors.ape')}</InputError>}
									</>
								)}
							</FormField>
						)}
					</FormRow>
					<FormRow>
						{/* {
							<FormField>
								<InputLabel>{t('clientRegister.creationDate')}</InputLabel>
								<DateInput name='company-creation-date' defaultValue={client.customFields['company-creation-date']?.value as string} />
							</FormField>
						} */}

						{updating ? (
							<FormField>
								<InputLabel>{t('clientRegister.tradeType')}</InputLabel>
								<StyledSpan>{t(`clientRegister.tradeTypes.${customFields!['company-trade-type'].values.find((value) => value.id === client.customFields['company-trade-type'].value)?.value}`)}</StyledSpan>
							</FormField>
						) : (
							<FormField>
								<InputLabel>{t('clientRegister.tradeType')}</InputLabel>
								<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
								<RegisterSelect
									invalid={'tradeType' in messages}
									showPlaceholder={!client.customFields['company-trade-type']?.value}
									onChange={(option: any) => {
										setClient({
											...client,
											customFields: {
												...client.customFields,
												'company-trade-type': {
													...client.customFields['company-trade-type'],
													value: option.value,
												},
											},
										});
									}}
									defaultValue={client.customFields['company-trade-type']?.value || ''}
									placeholder={t('clientRegister.placeholders.tradeType')}
									options={(customFields?.['company-trade-type'].values || []).map(({ value, id }) => ({ value: id, label: t(`clientRegister.tradeTypes.${value}`, value) }))}
								/>
								{'tradeType' in messages && <InputError>{t('clientRegister.errors.select')}</InputError>}
							</FormField>
						)}
						<FormField></FormField>
					</FormRow>
					{/* {client.customFields['company-trade-type'].value === customFields?.['company-trade-type'].values.find((value) => value.value === 'buying-group')?.id && (
						<FormRow>
							{updating ? (
								<FormField>
									<InputLabel>{t('clientRegister.buyingGroupMembershipNumber')}</InputLabel>
									<StyledSpan>{client.customFields['company-buying-group-membership-number'].value}</StyledSpan>
								</FormField>
							) : (
								<FormField>
									<InputLabel>{t('clientRegister.buyingGroupMembershipNumber')}</InputLabel>
									<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
									<RegisterTextInput
										autoComplete='off'
										autoCorrect='off'
										spellCheck='false'
										invalid={'company-buying-group-membership-number' in messages}
										defaultValue={client.customFields['company-buying-group-membership-number'].value}
										type='text'
										name='company-buying-group-membership-number'
										placeholder={t('clientRegister.placeholders.buyingGroupMembershipNumber')}
									/>
									{'company-buying-group-membership-number' in messages && <InputError>{t('clientRegister.errors.required')}</InputError>}
								</FormField>
							)}
						</FormRow>
					)} */}
					{client.customFields['company-trade-type'].value === customFields?.['company-trade-type'].values.find((value) => value.value === 'sign')?.id && (
						<FormRow>
							{updating ? (
								<FormField>
									<InputLabel>{t('clientRegister.storeName')}</InputLabel>
									<StyledSpan>{client.customFields['company-sign'].value}</StyledSpan>
								</FormField>
							) : (
								<FormField>
									<InputLabel>{t('clientRegister.storeName')}</InputLabel>
									<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
									<RegisterTextInput autoComplete='off' autoCorrect='off' spellCheck='false' invalid={'company-sign' in messages} defaultValue={client.customFields['company-sign'].value} type='text' name='company-sign' placeholder={t('clientRegister.placeholders.storeName')} />
									{'company-sign' in messages && <InputError>{t('clientRegister.errors.required')}</InputError>}
								</FormField>
							)}
						</FormRow>
					)}
					<FormRow>
						<FormField>
							<InputLabel>{t('clientRegister.website')}</InputLabel>
							<RegisterTextInput autoComplete='off' autoCorrect='off' spellCheck='false' invalid={'website' in messages} type='url' name='website' defaultValue={client.website} placeholder={t('clientRegister.placeholders.website')} />
						</FormField>
						<FormField>
							<InputLabel>{t('clientRegister.email')}</InputLabel>
							<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
							<RegisterTextInput
								autoComplete='off'
								autoCorrect='off'
								invalid={'email' in messages}
								onChange={(e: any) => {
									setClient({ ...client, email: e.target.value });
								}}
								type='email'
								defaultValue={client.email}
								name='email'
								placeholder={t('clientRegister.placeholders.email')}
							/>
							{'email' in messages && <InputError>{t('clientRegister.errors.email')}</InputError>}
						</FormField>
					</FormRow>
					<FormRow>
						<FormField>
							<InputLabel>{t('clientRegister.currency')}</InputLabel>
							<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
							<RegisterSelect
								invalid={'currency' in messages}
								showPlaceholder={!client.customFields['company-currency'].value}
								onChange={(option: any) =>
									setClient({
										...client,
										customFields: {
											...client.customFields,
											'company-currency': {
												...client.customFields['company-currency'],
												value: parseInt(option.value),
											},
										},
									})
								}
								defaultValue={client.customFields['company-currency'].value}
								placeholder={t('clientRegister.placeholders.currency')}
								options={(customFields?.['company-currency'].values || []).map(({ value, id }) => ({ label: CURRENCY_SYMBOLS[value], value: id }))}
							/>
							{'currency' in messages && <InputError>{t('clientRegister.errors.select')}</InputError>}
						</FormField>
						<FormField>
							<InputLabel>{t('clientRegister.language')}</InputLabel>
							<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
							<RegisterSelect
								invalid={'language' in messages}
								showPlaceholder={!client.customFields['company-language'].value}
								onChange={(option: any) =>
									setClient({
										...client,
										customFields: {
											...client.customFields,
											'company-language': {
												...client.customFields['company-language'],
												value: option.value,
											},
										},
									})
								}
								defaultValue={client.customFields['company-language'].value}
								placeholder={t('clientRegister.language')}
								options={(customFields?.['company-language'].values || []).map(({ value, id }) => ({
									value: id,
									label:
										t(`clientRegister.languages.${value}`, languageTranslator.of(value) || '')
											.charAt(0)
											.toUpperCase() + t(`clientRegister.languages.${value}`, languageTranslator.of(value) || '').slice(1),
								}))}
							/>
							{'language' in messages && <InputError>{t('clientRegister.errors.select')}</InputError>}
						</FormField>
					</FormRow>

					<FormRow>
						{client.invoicingAddress?.country === 'FR' ? (
							updating ? (
								<FormField>
									<InputLabel>{t('clientRegister.siren')}</InputLabel>
									<StyledSpan>{client.siren}</StyledSpan>
								</FormField>
							) : (
								<FormField>
									<InputLabel>{t('clientRegister.siren')}</InputLabel>
									<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
									<RegisterTextInput autoComplete='off' autoCorrect='off' invalid={'siren' in messages} type='text' name='siren' defaultValue={client.siren} placeholder={t('clientRegister.placeholders.siren')} />
									{'siren' in messages && <InputError>{t('clientRegister.errors.siren')}</InputError>}
								</FormField>
							)
						) : updating ? (
							<FormField>
								<InputLabel>{t('clientRegister.nationalId')}</InputLabel>
								<StyledSpan>{client.customFields['company-national-id'].value}</StyledSpan>
							</FormField>
						) : (
							<FormField>
								<InputLabel>{t(`clientRegister.nationalId`)}</InputLabel>
								<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
								<RegisterTextInput
									autoComplete='off'
									autoCorrect='off'
									invalid={'company-national-id' in messages}
									type='text'
									name='company-national-id'
									defaultValue={client.customFields['company-national-id'].value as string}
									onChange={(e: any) => {
										setClient({ ...client, customFields: { ...client.customFields, 'company-national-id': { ...client.customFields['company-national-id'], value: e.target.value } } });
									}}
									placeholder={t('clientRegister.placeholders.nationalId')}
								/>
								{'company-national-id' in messages && <InputError>{t('clientRegister.errors.required')}</InputError>}
							</FormField>
						)}
						{updating ? (
							<FormField>
								<InputLabel>{t('clientRegister.vat')}</InputLabel>
								<StyledSpan>{client.vat}</StyledSpan>
							</FormField>
						) : (
							<FormField>
								<InputLabel>{t('clientRegister.vat')}</InputLabel>
								<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
								<RegisterTextInput autoComplete='off' autoCorrect='off' spellCheck='false' invalid={'vat' in messages} type='text' name='vat' defaultValue={client.vat} placeholder={t('clientRegister.placeholders.vat')} />
								{'vat' in messages && <InputError>{t('clientRegister.errors.vat')}</InputError>}
							</FormField>
						)}
					</FormRow>

					<SubSectionTitle>{t('clientRegister.invoicingAddress', 'Adresse de facturation')}</SubSectionTitle>
					<FormRow>
						<FormField flex='1 1 180px'>
							<InputLabel>{t('clientRegister.zipCode')}</InputLabel>
							<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
							<RegisterTextInput autoComplete='off' autoCorrect='off' spellCheck='false' invalid={'invoicingZip' in messages} type='text' name='invoicingZip' onChange={handleZipChange} defaultValue={client.invoicingAddress?.zip} placeholder={t('clientRegister.placeholders.zipCode')} />
							{'invoicingZip' in messages && <InputError>{t('clientRegister.errors.required')}</InputError>}
						</FormField>
						<FormField flex='4 1 300px' ref={cityFieldRef}>
							<InputLabel>{t('clientRegister.city')}</InputLabel>
							<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
							<RegisterTextInput autoComplete='off' autoCorrect='off' spellCheck='false' invalid={'invoicingCity' in messages} type='text' name='invoicingCity' onFocus={() => setShowCityChoices(true)} defaultValue={client.invoicingAddress?.city} placeholder={t('clientRegister.placeholders.city')} />
							{cityChoices.length > 0 && showCityChoices && (
								<CitiesDropdown>
									{cityChoices.map((city, index) => (
										<Container
											onClick={() => {
												(formRef.current?.elements.namedItem('invoicingCity') as HTMLInputElement).value = city;
												setShowCityChoices(false);
											}}
											key={index}>
											{city}
										</Container>
									))}
								</CitiesDropdown>
							)}
							{'invoicingCity' in messages && <InputError>{t('clientRegister.errors.required')}</InputError>}
						</FormField>
					</FormRow>
					<FormRow>
						<FormField>
							<InputLabel>{t('clientRegister.address')}</InputLabel>
							<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
							<RegisterTextInput autoComplete='off' autoCorrect='off' spellCheck='false' invalid={'invoicingAddress' in messages} type='text' name='invoicingAddress' defaultValue={client.invoicingAddress?.street} placeholder={t('clientRegister.placeholders.address')} />
							{'invoicingAddress' in messages && <InputError>{t('clientRegister.errors.required')}</InputError>}
						</FormField>
					</FormRow>
					<SubSectionTitle>{t('clientRegister.others', 'Autres')}</SubSectionTitle>
					<FormRow>
						<FormField>
							<InputLabel>{t('clientRegister.paymentMethod')}</InputLabel>
							<RegisterSelect
								invalid={'company-payment-method' in messages}
								showPlaceholder={!client.customFields['company-payment-method'].value}
								onChange={(option: any) =>
									setClient({
										...client,
										customFields: {
											...client.customFields,
											'company-payment-method': {
												...client.customFields['company-payment-method'],
												value: option.value,
											},
										},
									})
								}
								defaultValue={client.customFields['company-payment-method'].value}
								placeholder={t('clientRegister.paymentMethod')}
								options={getPaymentMethodOptions()}
							/>
						</FormField>
						<FormField>
							<InputLabel>{t('clientRegister.billingEmail')}</InputLabel>
							<RegisterTextInput autoComplete='off' autoCorrect='off' spellCheck='false' invalid={'billingEmail' in messages} type='text' name='billingEmail' defaultValue={client.customFields['company-billing-email'].value} placeholder={t('clientRegister.placeholders.billingEmail')} />
						</FormField>
					</FormRow>
					{/* <FormRow>
						<FormField>
							<InputLabel>{t('clientRegister.glassmaker')}</InputLabel>
							<RegisterSelect
								maxHeight='100px'
								showPlaceholder={!client.customFields['company-glassmaker'].value}
								onChange={(option: any) =>
									setClient({
										...client,
										customFields: {
											...client.customFields,
											'company-glassmaker': {
												...client.customFields['company-glassmaker'],
												value: option.value,
											},
										},
									})
								}
								defaultValue={client.customFields['company-glassmaker'].value}
								placeholder={t('clientRegister.glassmaker')}
								options={(customFields?.['company-glassmaker']?.values || []).map((value) => ({ value: value.id, label: value.value }))}
							/>
						</FormField>

						<FormField>
							{(client.customFields['company-glassmaker'].value?.toString() || '').length > 0 && (
								<>
									<InputLabel>{t('clientRegister.glassmakerMembershipNumber')}</InputLabel>
									<RegisterTextInput
										autoComplete='off'
										autoCorrect='off'
										spellCheck='false'
										invalid={'company-glassmaker-membership-number' in messages}
										type='text'
										name='company-glassmaker-membership-number'
										defaultValue={client.customFields['company-glassmaker-membership-number'].value}
										placeholder={t('clientRegister.glassmakerMembershipNumber')}
									/>
								</>
							)}
						</FormField>
					</FormRow> */}
				</FormGroup>
			</RegisterForm>
			{updating && (
				<Container margin='0 0 16px 0'>
					<Muted>{t('profile.uneditableFieldsMessage')}</Muted>
				</Container>
			)}
			<ButtonBar>
				{updating ? (
					<NextButton onClick={handleUpdate} type='submit'>
						{t('global.save')}
					</NextButton>
				) : (
					<NextButton onClick={handleSubmit} type='submit'>
						{t('global.next')}
					</NextButton>
				)}
			</ButtonBar>
		</Container>
	);
};
