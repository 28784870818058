import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { CHECKOUT_STEPS } from '../../constants/checkoutSteps';
import { isUserAndCompany } from '../../models/extUser';
import { OrderInfo } from '../../models/orderInfo';
import { useGetCartQuery } from '../../reducers/cartApi';
import { useGetProductsByIdsQuery } from '../../reducers/productApi';
import { useUserAuth } from '../../utils/hooks';
import { Stepper } from '../other/Stepper';
import WindowLoading from '../other/WindowLoading';
import Confirmation from './Confirmation';
import Delivery from './Delivery';
import OrderConfirmation from './OrderConfirmation';
import { CheckoutMainContainer } from './style';
import { useClientPricing } from '../../utils/hooks/useClientPricing';

const Checkout = () => {
	const { t } = useTranslation();
	const { user } = useUserAuth();
	const { getProductPrice } = useClientPricing();
	const company = user?.currentCompany;

	const [orderInfo, setOrderInfo] = useState<OrderInfo | undefined>(
		company
			? {
					deliveryAddress: company.deliveryAddress,
					invoicingAddress: company.invoicingAddress,
					client: company,
					contact: company.contact,
					shippingCompany: company.customFields['company-shipping-company']?.value?.[0] || 0,
					cartRows: [],
					totalPrice: 0,
					totalQuantity: 0,
			  }
			: undefined,
	);
	const { data: cart, isLoading: isLoadingCart, isSuccess: isSuccessCart } = useGetCartQuery((user?.currentCompany?.id || '').toString());
	const { data: products, isLoading: isLoadingProducts, isSuccess: isSuccessProducts } = useGetProductsByIdsQuery(Object.keys(cart || {}));
	const location = useLocation();
	const registerSteps = useMemo(() => {
		return CHECKOUT_STEPS.map((step) => {
			return {
				label: t(`checkout.steps.${step}`),
				content: 'index',
			};
		});
	}, [t]);

	useEffect(() => {
		if (!company || !cart || !products || !isUserAndCompany(user)) return;
		const { cartRows, totalPrice, totalQuantity } = products.reduce(
			(acc, p) => {
				const quantity = cart[p.id]?.quantity || 0;
				const unitPrice = getProductPrice(p)?.exclTax || 0;
				acc.cartRows.push({
					product: p,
					quantity: quantity,
					unitPrice: unitPrice,
				});
				acc.totalPrice += quantity * unitPrice;
				acc.totalQuantity += quantity;
				return acc;
			},
			{ cartRows: [] as any[], totalPrice: 0, totalQuantity: 0 },
		);
		setOrderInfo({
			deliveryAddress: company.deliveryAddress,
			invoicingAddress: company.invoicingAddress,
			client: company,

			contact: company.contact,
			shippingCompany: company.customFields['company-shipping-company']?.value?.[0] || 0,
			cartRows,
			totalPrice,
			totalQuantity,
		});
	}, [company, cart, products, user]);

	return (
		<CheckoutMainContainer>
			{(isLoadingCart || isLoadingProducts || !orderInfo) && <WindowLoading />}
			{isSuccessCart && isSuccessProducts && orderInfo && (
				<>
					<Stepper steps={registerSteps} currentStep={CHECKOUT_STEPS.indexOf(location.pathname.split('/').pop()!)} full={location.pathname.includes('order')} />
					<Routes>
						<Route path='/' element={<Navigate replace to='delivery' />} />
						<Route path='/delivery' element={<Delivery orderInfo={orderInfo} setOrderInfo={setOrderInfo} />} />
						<Route path='/confirmation' element={<Confirmation orderInfo={orderInfo} setOrderInfo={setOrderInfo} cart={cart} />} />
						<Route path='/order/:id' element={<OrderConfirmation />} />
					</Routes>
				</>
			)}
		</CheckoutMainContainer>
	);
};

export default Checkout;
