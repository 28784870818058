import styled from "styled-components";

export const DownloadButton = styled.a`
    display: flex;
    font-weight: 700;
    font-size: 1em;
    padding: 4px 8px;
    align-self: center;
    margin: 0;
    gap: 4px;
    align-items: center;
    justify-content: center;
    color: #F7C200;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;