import { motion } from 'framer-motion';
import { Link, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const ProfileWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
`;

export const ProfilePicture = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    border-radius: 50%;
    background: ${(props) => props.theme.mainColor};
    color: #fff;
    font-size: 1.3em;
    font-weight: 600;
    text-transform: uppercase;
`;

export const ProfileButton = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    border-radius: 50%;

    &:hover {
        opacity: 0.8;
    }
`;

export const DropdownMenu = styled(motion.div).attrs({
    initial: { opacity: 0, y: -10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -10 },
    transition: { duration: 0.2 },
})`
    display: flex;
    position: absolute;
    top: 44px;
    flex-direction: column;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
`;

export const TopMenu = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-bottom: 1px solid #eee;
    padding: 16px;
    align-items: center;
`;

export const BottomMenu = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-top: 1px solid #eee;
    padding: 8px 16px;
    align-items: flex-start;
`;

export const MenuItems = styled.div`
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

export const MenuItem = styled.div`
    text-decoration: none;
    width: 100%;
    padding: 8px 16px;
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    white-space: nowrap;
`;

export const NavLinkItem = styled(NavLink)`
    color: #000;
    text-decoration: none;
    width: 100%;
    padding: 8px 16px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    &:hover {
        background: #eee;
    }

    &.active {
        color: ${(props) => props.theme.mainColor};
        font-weight: 600;
        &:hover {
            background: #fff;
        }
    }
`;

export const ButtonItem = styled.span<{ isActive?: number }>`
    color: #000;
    text-decoration: none;
    width: 100%;
    padding: 8px 16px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    &:hover {
        background: #eee;
    }

    ${(props) => props.isActive && css`
        color: ${(props) => props.theme.mainColor};
        font-weight: 600;
        &:hover {
            background: #fff;
        }
    `};
`;

export const LinkItem = styled(Link)`
    color: #000;
    text-decoration: none;
    width: 100%;
    padding: 8px 16px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    &:hover {
        background: #eee;
    }
`;


export const TopMenuName = styled.span`
    font-weight: 600;
`;


export const CurrentCompanyLabel = styled.label`
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.7);
`;

export const CurrentCompany = styled.span`
    position: relative;
    font-size: 1em;
    font-weight: 500;
    color: ${(props) => props.theme.blackColor};
    border: 2px solid ${(props) => props.theme.mainColor};
    border-radius: 200px;
    padding: 2px 24px;
`;

export const RegisterLink = styled(NavLink)`
    text-decoration: none;
    color: ${(props) => props.theme.blackColor};
    font-size: 1em;
    font-weight: 500;
    white-space: nowrap;
    cursor: pointer;
    gap: 8px;
    display: flex;
`;

export const NewClientLabel = styled.label`
    font-size: 1em;
    color: ${(props) => props.theme.mainColor};
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
`;