import styled from "styled-components";
import { rotateAnimation } from "../animations";

export const WindowLoadingWrapper = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10000;
	top: 0;
	left: 0;
`;	

export const WindowLoadingSpinner = styled.div`
	&:after {
		content: '';
		display: block;
		width: 120px;
		height: 120px;
		border-radius: 50%;
		border: 7px solid #f7c200;
		border-color: #f7c200 #f7c200 #f7c200 transparent;
		animation: ${rotateAnimation} 1.8s linear infinite;
	}
`; 

export const LoadingSpinner = styled.div<{size?: string, borderWidth?: string, color?: string}>`
	&:after {
		content: '';
		display: block;
		width: ${(props) => props.size || '60px'};
		height: ${(props) => props.size || '60px'};
		border-radius: 50%;
		border: ${(props) => `${(props.borderWidth || '6px')} solid`};
		border-color: ${(props) => `${props.color || '#f7c200'} ${props.color || '#f7c200'} ${props.color || '#f7c200'} transparent`};
		animation: ${rotateAnimation} 1.8s linear infinite;
	}
`;