import { useEffect, useState } from 'react';
import { MainContainer } from '../../styles/containers';
import { Locale} from '../../models';
import ReactMarkdown from 'react-markdown';
import i18n from '../../i18nextInit';
import BackArrow from '../other/BackArrow';

interface SimpleMDProps {
	filename: string;
}
const SimpleMD = ({ filename }: SimpleMDProps) => {
  const language = i18n.language as Locale;
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
  import(`../../assets/texts/${language}/${filename}.md`)
  .then((md) => {
    fetch(md.default)
      .then((response) => response.text())
      .then((text) => setMarkdown(text));
  })
  .catch(() =>  {
    import(`../../assets/texts/fr-FR/${filename}.md`)
      .then((md) => {
        fetch(md.default)
          .then((response) => response.text())
          .then((text) => setMarkdown(text));
      });
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
  return (
        <MainContainer>
            <BackArrow />
						<ReactMarkdown children={markdown} />
				</MainContainer>
  )
}
export default SimpleMD;


