import { Navigate } from 'react-router-dom';
import { useUserAuth } from '../../utils/hooks';

interface ProtectedRouteProps {
	children: JSX.Element;
	redirect?: string;
}

const ProtectedRoute = ({ children, redirect }: ProtectedRouteProps) => {
	const { user } = useUserAuth();

	if (user === null) return <Navigate replace to={redirect || '/login'} />;

	return children;
};

export default ProtectedRoute;
