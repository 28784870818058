import { ComponentPropsWithRef, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { NoResultItem, OptionStyle, OptionsListStyle, OptionsWrapperStyle, SelectSearch, SelectStyle, SelectWrapperStyle } from './style';
import { useClickOutside } from 'aaastyle';
import { useTranslation } from 'react-i18next';

export type OptionWithSearchProps = {
	value: string | number;
	label: string | JSX.Element;
	searchLabel: string;
	disabled?: boolean;
};

export type SearchProps = {
	placeholder?: string;
	defaultValue?: string | number;
};

interface SelectWithSearchProps extends ComponentPropsWithRef<'div'> {
	placeholder?: string;
	defaultValue?: string | number;
	options: OptionWithSearchProps[];
	onSelect?: (value: any) => void;
	search?: SearchProps | false;
}

const SelectWithSearch = forwardRef<HTMLDivElement, SelectWithSearchProps>((props, ref) => {
	const { placeholder, defaultValue, options, onSelect, search = false } = props;
	const [searchValue, setSearchValue] = useState<string>('');
	const [selectedOption, setSelectedOption] = useState<OptionWithSearchProps | undefined>();
	const { t } = useTranslation();
	const filteredOptions = useMemo(() => {
		if (!searchValue || searchValue === '') {
			return options;
		}

		return options.filter((option) => option.searchLabel.toLowerCase().includes(searchValue.toLowerCase()));
	}, [options, searchValue]);

	const [showOptions, setShowOptions] = useState<boolean>(false);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const searchRef = useRef<HTMLInputElement>(null);

	useClickOutside(wrapperRef, () => {
		setShowOptions(false);
	});

	const getOption = (value: string | number) => {
		return options.find((option) => option.value === value);
	};

	useEffect(() => {
		if (defaultValue) {
			let option = getOption(defaultValue);
			setSelectedOption(option);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValue]);

	useEffect(() => {
		if (showOptions) {
			searchRef.current?.focus();
			return;
		}
		setTimeout(() => {
			setSearchValue('');
		}, 300);
	}, [showOptions]);

	const handleOptionClick = (option: OptionWithSearchProps) => {
		if (option.disabled) return;
		setSelectedOption(option);
		setShowOptions(false);
		if (onSelect) {
			onSelect(option);
		}
	};

	return (
		<SelectWrapperStyle ref={wrapperRef} {...props}>
			<SelectStyle tabIndex={0} rotate={showOptions} ref={ref} onFocus={() => setShowOptions(true)}>
				<span>{(selectedOption && selectedOption.label) || placeholder}</span>
				<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#000000'>
					<path d='M0 0h24v24H0V0z' fill='none' />
					<path d='M7 10l5 5 5-5H7z' />
				</svg>
			</SelectStyle>
			<OptionsWrapperStyle show={showOptions}>
				{search && <SelectSearch type='search' ref={searchRef} placeholder={search.placeholder} value={searchValue} defaultValue={search.defaultValue} onChange={(e) => setSearchValue(e.target.value)} />}
				<OptionsListStyle>
					{filteredOptions.length > 0 ? (
						filteredOptions.map((option, index) => (
							<OptionStyle onClick={() => handleOptionClick(option)} key={index} value={option.value} disabled={option.disabled}>
								{option.label}
							</OptionStyle>
						))
					) : (
						<NoResultItem disabled>{t('global.noResults')}</NoResultItem>
					)}
				</OptionsListStyle>
			</OptionsWrapperStyle>
		</SelectWrapperStyle>
	);
});

export default SelectWithSearch;
