import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Contact } from '../../../models';
import { ClientDTO, initClientDTO } from '../../../models/client';
import { Container } from '../../../styles/containers';
import { LoadingSpinner } from '../../../styles/loading';
import { useSellsy, useToast, useUserAuth } from '../../../utils/hooks';
import WindowLoading from '../../other/WindowLoading';
import Informations from '../Informations';
import Orders from './Orders';
import { Sidebar } from './Sidebar';
import { ProfileMainContainer, ProfileViews } from './Sidebar/style';

const Profile = () => {
	const { user } = useUserAuth();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { customFields } = useSellsy();
	const { toast } = useToast();
	const [client, setClient] = useState<ClientDTO | undefined>();
	const { updateUser: sellsyUpdateUser, updateCompany: sellsyUpdateCompany, updateCompanyLogistic: sellsyUpdateCompanyLogistic } = useSellsy();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (user === null) navigate('/login');
		if (!user) return;

		setClient({
			...initClientDTO(),
			...user?.currentCompany,
			contact: {
				id: user?.id || 0,
				role: 'contact',
				firstName: user?.firstName,
				lastName: user?.lastName,
				email: user?.email || '',
				mobilePhone: user?.mobilePhone,
				function: user?.function,
				homePhone: user?.homePhone,
				currentCompany: user?.currentCompany,
				otherCompanies: user?.otherCompanies || [],
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const updateUser = (contact: Contact) => {
		setLoading(true);
		sellsyUpdateUser(contact, user!.uid)
			.then((res) => {
				if (!res.ok) {
					throw new Error('Error while updating user');
				}
				toast({
					message: t('profile.userUpdatedMessage'),
					closable: true,
					duration: 3000,
				});
			})
			.catch(() => {
				toast({
					message: t('global.errors.default'),
					closable: true,
					duration: 3000,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateCompany = (company: ClientDTO) => {
		setLoading(true);
		sellsyUpdateCompany(company)
			.then((res) => {
				if (!res.ok) {
					throw new Error('Error while updating company');
				}
				toast({
					message: t('profile.companyUpdatedMessage'),
					closable: true,
					duration: 3000,
				});
			})
			.catch(() => {
				toast({
					message: t('global.errors.default'),
					closable: true,
					duration: 3000,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateLogistic = (company: ClientDTO) => {
		setLoading(true);
		sellsyUpdateCompanyLogistic(company)
			.then((res) => {
				if (!res.ok) {
					throw new Error('Error while updating company');
				}
				toast({
					message: t('profile.companyUpdatedMessage'),
					closable: true,
					duration: 3000,
				});
			})
			.catch(() => {
				toast({
					message: t('global.errors.default'),
					closable: true,
					duration: 3000,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<ProfileMainContainer>
			{loading && <WindowLoading />}
			{client ? (
				<>
					<Sidebar />
					<ProfileViews>
						<Routes>
							<Route path='/' element={<Navigate replace to={'account'} />} />
							<Route path='company' element={<Informations.Administrative updating onSubmit={updateCompany} client={client} setClient={setClient} customFields={customFields} />} />
							<Route
								path='account'
								element={
									<Container direction='column'>
										<Informations.User
											updating
											client={client}
											onSubmit={(contact: Contact) => {
												updateUser(contact);
											}}
											setClient={setClient}
										/>
									</Container>
								}
							/>
							<Route path='logistics' element={<Informations.Logistics updating client={client} setClient={setClient} onSubmit={updateLogistic} customFields={customFields} />} />
							<Route path='orders' element={<Orders />} />
						</Routes>
					</ProfileViews>
				</>
			) : (
				<Container width='100%' height='200px' justify='center' align='center'>
					<LoadingSpinner />
				</Container>
			)}
		</ProfileMainContainer>
	);
};

export default Profile;
