import { Button } from "aaastyle";
import styled from "styled-components";

export const CartIncrementButton = styled(Button) <{ justify?: string }>`
	padding: 12px 24px;
	margin: 0;
	border-radius: 0;
	width: 100%;
	display: flex;
	justify-content: ${({ justify }) => justify || 'center'};
`;


export const OrderButton = styled(Button)`
	width: 100%;
	max-width: 400px;
	margin-top: auto;

	&:disabled {
		cursor: not-allowed;
		background-color: rgba(0,0,0,0.2);
		opacity: 1;
		color: rgba(0,0,0,0.4);
	}
`;

export const ResetFiltersButton = styled.button`
	text-decoration: underline;
	border: none;
	outline: none;
	background: none;
	font-weight: 500;
	font-size: 0.9em;
	cursor: pointer;
	display: flex;	
	position: relative;
	align-self: flex-end;
	top: -2px;
	color: ${({ theme }) => theme.mainColor};
`;

export const CloseCarouselButton = styled.button`
	position: absolute;
	top: 0;
	right: 0;
	padding: 16px;
	border: none;
	outline: none;
	background: none;
	cursor: pointer;
	z-index: 500;
`;