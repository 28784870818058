import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { REGISTER_STEPS } from '../../../constants/registerSteps';
import { ClientDTO, initClientDTO } from '../../../models/client';
import { ShrinkedContainer } from '../../../styles/containers';
import { useSellsy, useToast } from '../../../utils/hooks';
import { Stepper } from '../../other/Stepper';
import WindowLoading from '../../other/WindowLoading';
import Informations from '../Informations';
import ConfirmRegistration from './ConfirmRegistration';

const Register = () => {
	const { t } = useTranslation();
	const { createClient } = useSellsy();
	const { toast } = useToast();
	const [client, setClient] = useState<ClientDTO>(initClientDTO());
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const { customFields } = useSellsy();
	const location = useLocation();
	const [maxStep, setMaxStep] = useState<0 | 1 | 2 | 3>(0);
	const step = location.pathname.split('/').pop();
	const registerSteps = useMemo(() => {
		return REGISTER_STEPS.map((step) => {
			return {
				label: t(`register.steps.${step}`),
				content: 'index',
			};
		});
	}, [t]);

	useEffect(() => {
		if (!customFields) return;
		setClient((client) => ({
			...client,
			customFields: {
				...Object.entries(customFields!).reduce((acc, [key, value]) => {
					if (key === 'company-preferred-delivery-day') {
						acc[key] = {
							id: value.id,
							value: value.values.map((v: any) => v.id),
						};
						return acc;
					}
					if (key === 'company-shipping-company') {
						acc[key] = {
							id: value.id,
							value: value.values.map((v: any) => v.id),
						};
						return acc;
					}
					if (key === 'company-eco-friendly-delivery') {
						acc[key] = {
							id: value.id,
							value: true,
						};
						return acc;
					}
					if (key === 'company-unsold-items-reselling') {
						acc[key] = {
							id: value.id,
							value: true,
						};
						return acc;
					}
					acc[key] = {
						id: value.id,
						value: client.customFields[key]?.value,
					};
					return acc;
				}, client.customFields),
			},
		}));
	}, [customFields]);

	const updateStep = (step: 0 | 1 | 2 | 3) => {
		if (step > maxStep) {
			setMaxStep(step);
		}
	};

	const handleSubmit = (client: ClientDTO) => {
		setLoading(true);
		createClient(client)
			.then((res) => {
				if (!res.ok) {
					return res.text().then((text: string) => {
						throw new Error(text);
					});
				}
				updateStep(3);
				navigate('confirm');
			})
			.catch((err) => {
				toast({
					message: t([`clientRegister.toastMessages.${err.message}`, 'clientRegister.toastMessages.default']),
					closable: true,
					duration: 5000,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<ShrinkedContainer>
			{loading && <WindowLoading />}
			{step && REGISTER_STEPS.indexOf(step) !== -1 && <Stepper steps={registerSteps} currentStep={REGISTER_STEPS.indexOf(step)} />}
			<Routes>
				<Route path='/' element={<Navigate replace to={REGISTER_STEPS[0]} />} />
				<Route path='administrative' element={<Informations.Administrative client={client} setClient={setClient} customFields={customFields} onSubmit={() => updateStep(1)} />} />
				<Route path='user' element={maxStep > 0 ? <Informations.User client={client} setClient={setClient} onSubmit={() => updateStep(2)} /> : <Navigate replace to={`../${REGISTER_STEPS[0]}`} />} />
				<Route path='logistics' element={maxStep > 1 ? <Informations.Logistics onSubmit={(client: ClientDTO) => handleSubmit(client)} client={client} setClient={setClient} customFields={customFields} /> : <Navigate replace to={`../${REGISTER_STEPS[1]}`} />} />
				<Route path='confirm' element={maxStep > 2 ? <ConfirmRegistration /> : <Navigate replace to={`../${REGISTER_STEPS[2]}`} />} />
			</Routes>
		</ShrinkedContainer>
	);
};

export default Register;
