import { ReactNode } from 'react';
import { StepCounter, StepLabel, StepperItem, StepperWrapper } from './style';

export interface Step {
	label: string;
	content?: ReactNode | 'index';
}

interface StepperProps {
	steps: Step[];
	currentStep: number;
	full?: boolean;
}

const Stepper = (props: StepperProps) => {
	return (
		<StepperWrapper>
			{props.steps.map((step, index) => {
				const active = index === props.currentStep;
				const complete = index < props.currentStep || props.full;
				return (
					<StepperItem key={index} active={active} complete={complete}>
						{step.content && <StepCounter>{step.content === 'index' ? index + 1 : step.content}</StepCounter>}
						<StepLabel>{step.label}</StepLabel>
					</StepperItem>
				);
			})}
		</StepperWrapper>
	);
};

export default Stepper;
