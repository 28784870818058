import { MainButtonLink } from './../../../styles/buttons/index';
import styled from "styled-components";
import { Button } from 'aaastyle';

export const EmptyCardSpan = styled.span`
    font-size: 1.1em;
    font-weight: 500;
    color: ${(props) => props.theme.blackColor};
    white-space: nowrap;
    text-transform: uppercase;
    letter-spacing: 0.4px;
`;

export const SeeProductsButton = styled(MainButtonLink)`
    text-transform: uppercase;
    margin-top: 16px;
`;

export const ProductImage = styled.img`
    width: 80px;
    height: 80px;
    flex: 0 0 80px;
    object-fit: cover;
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
    border-radius: 4px;
`;

export const StyledCartItemRow = styled.div`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    gap: 8px;
    cursor: pointer;
    min-width: 380px;
`;

export const DeleteButton = styled(Button).attrs({
    variant: 'text',
})`
    padding: 0;
    margin: 0;
    text-decoration: underline;
    text-transform: lowercase;
    height: fit-content;
    width: fit-content;
    color: ${({ theme }) => theme.grayColor};
    font-size: 0.75em;
    font-weight: 300;
    bottom: 2px;
    & > span {
        padding: 2px;
    }
`;

export const TotalSpan = styled.span`
    font-size: 1em;
    font-weight: 500;
    color: ${(props) => props.theme.blackColor};
`;

const getQuantityFontSize = (length: number) => {
    switch (length) {
        case 1:
            return '0.875em';
        case 2:
            return '0.75em';
        case 3:
            return '0.65em';
        case 4:
            return '0.5em';
        default:
            return '0.4em';
    }
}

export const QuantityPin = styled.div<{ length: number }>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
    background-color: ${({ theme }) => theme.mainColor};
    color: ${({ theme }) => theme.whiteColor};
    font-size: ${({ length }) => getQuantityFontSize(length)};
    font-weight: 700;
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    bottom: -2px;
    right: -2px;
    outline: 1px solid #fff;
`;