import { AnimatePresence } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '../../models/product';
import { useGetCartQuery, useSetSavedForLaterMutation, useUpdateCartMutation } from '../../reducers/cartApi';
import { useAddFavoriteMutation, useGetAllFavoritesQuery, useRemoveFavoriteMutation } from '../../reducers/favoritesApi';
import { useGetAllProductsQuery } from '../../reducers/productApi';
import { ResetFiltersButton } from '../../styles/buttons/cart';
import { Container, MainContainer } from '../../styles/containers';
import { RegisterSelect } from '../../styles/inputs/register';
import { SkeletonLine } from '../../styles/loading/skeleton';
import { NoResultsSpan } from '../../styles/texts';
import { GroupTitle } from '../../styles/texts/cart';
import { useToast, useUserAuth } from '../../utils/hooks';
import { useDebounce } from '../../utils/hooks/useDebounce';
import { minify } from '../../utils/minify';
import SearchBar from '../other/SearchBar';
import { GlassesTile, GlassesTileSkeleton } from './GlassesTile';
import { GroupsContainer, ProductsContainer } from './style';

interface Filters {
	frameMaterial: string;
	frameType: string;
	lensBase: string;
	lensWidth: string;
	bridgeWidth: string;
	collection: string;
}

interface FilterOptionsSets {
	frameMaterialOptions: Set<string>;
	frameTypeOptions: Set<string>;
	lensBaseOptions: Set<string>;
	lensWidthOptions: Set<string>;
	bridgeWidthOptions: Set<string>;
	collectionOptions: Set<string>;
}

interface FiltersOptions {
	frameMaterialOptions: {
		value: string | 'all';
		label: string;
	}[];
	frameTypeOptions: {
		value: string | 'all';
		label: string;
	}[];
	lensBaseOptions: {
		value: string | 'all';
		label: string;
	}[];
	lensWidthOptions: {
		value: string | 'all';
		label: string;
	}[];
	bridgeWidthOptions: {
		value: string | 'all';
		label: string;
	}[];
	collectionOptions: {
		value: string | 'all';
		label: string;
	}[];
}

interface ProductsProps {
	showFavorites?: boolean;
}

const Products = ({ showFavorites }: ProductsProps) => {
	const { user } = useUserAuth();
	const { data: products, isLoading, isSuccess } = useGetAllProductsQuery();
	const { data: favorites } = useGetAllFavoritesQuery((user?.currentCompany?.id || '0').toString());
	const [favoritesBuffer, setFavoritesBuffer] = useState(Object.keys(favorites || {}));
	const [addFavorite, addFavoriteResult] = useAddFavoriteMutation();
	const [removeFavorite, removeFavoriteResult] = useRemoveFavoriteMutation();
	const [showReset, setShowReset] = useState(false);
	const [filters, setFilters] = useState<Filters>({
		frameMaterial: 'all',
		frameType: 'all',
		lensBase: 'all',
		lensWidth: 'all',
		bridgeWidth: 'all',
		collection: 'all',
	});
	const { t, i18n } = useTranslation();
	const language = i18n.language;
	const [searching, setSearching] = useState<boolean>(false);
	const [searchedProducts, setSearchedProducts] = useState<Product[]>(products || []);
	const [filteredProducts, setFilteredProducts] = useState<Product[]>(products || []);
	const { data: cart } = useGetCartQuery(user?.currentCompany?.id?.toString());
	const [currentCart, setCurrentCart] = useState(cart);
	const [updateCart, result] = useUpdateCartMutation();
	const { toast } = useToast();
	const debounce = useDebounce();
	const [setSavedForLater] = useSetSavedForLaterMutation();

	const groupedProducts = useMemo<{ [group: string]: Product[] } | null>(() => {
		return searchedProducts.reduce((acc: { [group: string]: Product[] } | null, product) => {
			if (acc === null) {
				acc = {};
			}
			if (showFavorites && !favoritesBuffer.includes(product.id.toString())) return acc;
			if (!filteredProducts.some((p) => p.id === product.id)) {
				return acc;
			}
			if (!acc[product.model]) {
				acc[product.model] = [];
			}
			acc[product.model].push(product);
			return acc;
		}, null);
	}, [searchedProducts, filteredProducts, showFavorites, favoritesBuffer]);

	const filtersOptions = useMemo<FiltersOptions>(() => {
		if (!products)
			return {
				frameMaterialOptions: [],
				frameTypeOptions: [],
				lensBaseOptions: [],
				lensWidthOptions: [],
				bridgeWidthOptions: [],
				collectionOptions: [],
			};
		const { frameMaterialOptions, frameTypeOptions, lensBaseOptions, lensWidthOptions, bridgeWidthOptions, collectionOptions } = products.reduce(
			(acc: FilterOptionsSets, product) => ({
				frameMaterialOptions: product.frameMaterial ? acc.frameMaterialOptions.add(product.frameMaterial) : acc.frameMaterialOptions,
				frameTypeOptions: product.frameType ? acc.frameTypeOptions.add(product.frameType) : acc.frameTypeOptions,
				lensBaseOptions: product.lensBase ? acc.lensBaseOptions.add(product.lensBase) : acc.lensBaseOptions,
				lensWidthOptions: product.size?.lens ? acc.lensWidthOptions.add(product.size.lens) : acc.lensWidthOptions,
				bridgeWidthOptions: product.size?.bridge ? acc.bridgeWidthOptions.add(product.size.bridge) : acc.bridgeWidthOptions,
				collectionOptions: product.collection ? acc.collectionOptions.add(product.collection) : acc.collectionOptions,
			}),
			{
				frameMaterialOptions: new Set<string>(),
				frameTypeOptions: new Set<string>(),
				lensBaseOptions: new Set<string>(),
				lensWidthOptions: new Set<string>(),
				bridgeWidthOptions: new Set<string>(),
				collectionOptions: new Set<string>(),
			},
		);
		return {
			frameMaterialOptions: [
				{
					value: 'all',
					label: t('cart.filters.frameMaterial.all'),
				},
				...Array.from(frameMaterialOptions).map((option) => ({
					value: option,
					label: t(`cart.filters.frameMaterial.${option}`),
				})),
			],
			frameTypeOptions: [
				{
					value: 'all',
					label: t('cart.filters.frameType.all'),
				},
				...Array.from(frameTypeOptions).map((option) => ({
					value: option,
					label: t(`cart.filters.frameType.${option}`),
				})),
			],
			lensBaseOptions: [
				{
					value: 'all',
					label: t('cart.filters.lensBase.all'),
				},
				...Array.from(lensBaseOptions).map((option) => ({
					value: option,
					label: t(`cart.filters.lensBase.${option}`),
				})),
			],
			lensWidthOptions: [
				{
					value: 'all',
					label: t('cart.filters.lensWidth.all'),
				},
				...Array.from(lensWidthOptions)
					.map((option) => ({
						value: option,
						label: t(`cart.filters.lensWidth.size`, { size: option }),
					}))
					.sort((a, b) => Number(a.value) - Number(b.value)),
			],
			bridgeWidthOptions: [
				{
					value: 'all',
					label: t('cart.filters.bridgeWidth.all'),
				},
				...Array.from(bridgeWidthOptions)
					.map((option) => ({
						value: option,
						label: t(`cart.filters.bridgeWidth.size`, { size: option }),
					}))
					.sort((a, b) => Number(a.value) - Number(b.value)),
			],
			collectionOptions: [
				{
					value: 'all',
					label: t('cart.filters.collection.all'),
				},
				...Array.from(collectionOptions)
					.map((option) => ({
						value: option,
						label: t(`cart.filters.collection.${option}`),
					}))
					.sort((a, b) => Number(a.value) - Number(b.value)),
			],
		};
	}, [products, language, t]);

	const handleSearchChange = (search: string) => {
		setSearching(true);
		debounce(() => {
			setSearching(false);
			if (!products) return;
			if (search.length < 1) {
				setSearchedProducts(products);
				return;
			}
			setSearchedProducts(products.filter((product) => minify(product.name).includes(search.toLowerCase()) || Object.values(product.colorName!).some((value) => minify(value) === search.toLowerCase()) || minify(product.frameMaterial!).includes(search.toLowerCase())));
		}, 800);
	};

	useEffect(() => {
		handleSearchChange('');
		// eslint-disable-next-line
	}, [products]);

	useEffect(() => {
		if (!products) return;
		if (Object.values(filters).every((value) => value === 'all')) {
			setFilteredProducts(products);
			setShowReset(false);
			return;
		}

		setFilteredProducts(
			products.filter(
				(product) =>
					(filters.frameMaterial !== 'all' ? product.frameMaterial === filters.frameMaterial : true) &&
					(filters.frameType !== 'all' ? product.frameType === filters.frameType : true) &&
					(filters.lensBase !== 'all' ? product.lensBase === filters.lensBase : true) &&
					(filters.lensWidth !== 'all' ? product.size?.lens === filters.lensWidth : true) &&
					(filters.bridgeWidth !== 'all' ? product.size?.bridge === filters.bridgeWidth : true),
			),
		);
		setShowReset(true);
	}, [filters, products, language]);

	useEffect(() => {
		if (!currentCart || !user) return;
		if (JSON.stringify(cart) === JSON.stringify(currentCart)) return;
		debounce(() => {
			const savedForLater = Object.entries(cart || {})
				.filter(([key]) => !Object.keys(currentCart).includes(key))
				.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

			if (Object.keys(savedForLater).length > 0) {
				setSavedForLater({ clientId: user?.currentCompany?.id?.toString(), cart: savedForLater });
			}
			updateCart({ cart: currentCart, clientId: user?.currentCompany?.id?.toString() });
		}, 1000);
	}, [currentCart]);

	useEffect(() => {
		setCurrentCart(cart);
	}, [cart]);

	useEffect(() => {
		if (result.isError) {
			setCurrentCart(cart);
			toast({
				message: t('global.errors.updatingCart'),
				duration: 4000,
				closable: true,
			});
		}
	}, [result]);

	useEffect(() => {
		if (JSON.stringify(favoritesBuffer) === JSON.stringify(Object.keys(favorites || {}))) return;
		setFavoritesBuffer(Object.keys(favorites || {}));
	}, [favorites]);

	const handleQuantityChange = (productId: string, quantity: number) => {
		setCurrentCart((prevCart) => {
			const newCart = { ...prevCart };
			if (quantity > 0) {
				newCart[productId] = {
					addingDate: Date.now(),
					quantity: quantity,
				};
			} else {
				delete newCart[productId];
			}
			return newCart;
		});
	};

	const handleFavoriteClick = (productId: string) => {
		if (!user) return;
		if (!favoritesBuffer.includes(productId)) {
			// add to favorites
			setFavoritesBuffer([...favoritesBuffer, productId]);

			addFavorite({ clientId: user.currentCompany!.id.toString(), productId: productId });
			return;
		}
		// remove from favorites
		setFavoritesBuffer(favoritesBuffer.filter((id) => id !== productId));
		removeFavorite({ clientId: user.currentCompany!.id.toString(), productId: productId });
	};

	useEffect(() => {
		if (!removeFavoriteResult || !addFavoriteResult) return;
		if (removeFavoriteResult.isError || addFavoriteResult.isError) {
			toast({
				message: t('global.errors.updatingFavorites'),
				duration: 4000,
				closable: true,
			});
		}
	}, [removeFavoriteResult, addFavoriteResult]);

	return (
		<MainContainer>
			<Container direction='column' gap={16} width='100%' maxWidth='1200px' align='center' alignSelf='center'>
				<Container direction='column' width='100%' gap={16}>
					<SearchBar
						placeholder={t('cart.searchPlaceholder')}
						onChange={(value) => {
							handleSearchChange(minify(value));
						}}
						searching={searching}
					/>
					<Container gap={16} align='flex-start' justify='space-between' flexWrap='wrap'>
						<Container flex='1 1 170px'>
							<RegisterSelect
								options={filtersOptions.frameMaterialOptions}
								onChange={(option: any) => {
									setFilters({ ...filters, frameMaterial: option.value });
								}}
								defaultValue={filters.frameMaterial}
								showPlaceholder={filters.frameMaterial === 'all'}
								placeholder={filtersOptions.frameMaterialOptions[0]?.label}
							/>
						</Container>
						<Container flex='1 1 170px'>
							<RegisterSelect
								options={filtersOptions.frameTypeOptions}
								onChange={(option: any) => {
									setFilters({ ...filters, frameType: option.value });
								}}
								defaultValue={filters.frameType}
								showPlaceholder={filters.frameType === 'all'}
								placeholder={filtersOptions.frameTypeOptions[0]?.label}
							/>
						</Container>
						<Container flex='1 1 170px'>
							<RegisterSelect
								options={filtersOptions.lensBaseOptions}
								onChange={(option: any) => {
									setFilters({ ...filters, lensBase: option.value });
								}}
								defaultValue={filters.lensBase}
								showPlaceholder={filters.lensBase === 'all'}
								placeholder={filtersOptions.lensBaseOptions[0]?.label}
							/>
						</Container>
					</Container>
					<Container gap={16} align='flex-start' justify='space-between' flexWrap='wrap'>
						<Container flex='1 1 170px'>
							<RegisterSelect
								options={filtersOptions.lensWidthOptions}
								onChange={(option: any) => {
									setFilters({ ...filters, lensWidth: option.value });
								}}
								defaultValue={filters.lensWidth}
								showPlaceholder={filters.lensWidth === 'all'}
								placeholder={filtersOptions.lensWidthOptions[0]?.label}
							/>
						</Container>
						<Container flex='1 1 170px'>
							<RegisterSelect
								options={filtersOptions.bridgeWidthOptions}
								onChange={(option: any) => {
									setFilters({ ...filters, bridgeWidth: option.value });
								}}
								defaultValue={filters.bridgeWidth}
								showPlaceholder={filters.bridgeWidth === 'all'}
								placeholder={filtersOptions.bridgeWidthOptions[0]?.label}
							/>
						</Container>
						<Container flex='1 1 170px'>
							<RegisterSelect
								options={filtersOptions.collectionOptions}
								onChange={(option: any) => {
									setFilters({ ...filters, collection: option.value });
								}}
								defaultValue={filters.collection}
								showPlaceholder={filters.collection === 'all'}
								placeholder={filtersOptions.collectionOptions[0]?.label}
							/>
						</Container>
					</Container>
					<Container height='20px' width='100%' justify='flex-end'>
						{showReset && <ResetFiltersButton onClick={() => setFilters(Object.entries(filters).reduce((acc, [key]) => ({ ...acc, [key]: 'all' }), {} as Filters))}>{t('cart.filters.reset')}</ResetFiltersButton>}
					</Container>
				</Container>
				<GroupsContainer>
					{(isLoading || groupedProducts === null) && (
						<Container direction='column' width='100%' gap={16}>
							<SkeletonLine width='100px' />
							<ProductsContainer>
								{[...Array(12)].map((_, i) => (
									<GlassesTileSkeleton key={i} />
								))}
							</ProductsContainer>
						</Container>
					)}
					{isSuccess && (
						<AnimatePresence key={JSON.stringify(groupedProducts)}>
							{groupedProducts && Object.entries(groupedProducts).length === 0 && <NoResultsSpan>{t('products.noResults')}</NoResultsSpan>}
							{groupedProducts &&
								Object.entries(groupedProducts)
									.sort((a, b) => a[0].localeCompare(b[0]))
									.map(([group, products]) => (
										<Container key={group} direction='column' width='100%' gap={16}>
											<GroupTitle>{group}</GroupTitle>
											<ProductsContainer>
												{products
													.sort((a, b) => a.name.localeCompare(b.name))
													.map((product) => (
														<GlassesTile isFavorite={favoritesBuffer.includes(product.id)} onFavoriteClick={() => handleFavoriteClick(product.id)} glasses={product} key={product.id} quantity={currentCart?.[product.id]?.quantity || 0} onQuantityChange={(q) => handleQuantityChange(product.id, q)} />
													))}
											</ProductsContainer>
										</Container>
									))}
						</AnimatePresence>
					)}
				</GroupsContainer>
			</Container>
		</MainContainer>
	);
};

export default Products;
