export const LEGAL_STATUS = {
    FR: [
        "Entrepreneur individuel",
        "Entreprise unipersonnelle à responsabilité limité",
        "Société à responsabilité limité",
        "Société par actions simplifiée unipersonnelle",
        "Société par action simplifiée",
        "Société anonyme",
        "Société en nom collectif",
        "Société en commandite simple",
        "Société en commandite par actions",
        "Autre",
    ],
    US: [
        "Sole proprietorship",
        "General partnership",
        "Limited partnership",
        "Public company ",
        "Limited liability corporation(LLC)",
        "Other",
    ]
}