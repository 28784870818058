import CSS from 'csstype';

const Mille946 = () => {
	const ul: CSS.Properties = {
		display: 'flex',
		flexWrap: 'wrap',
		flexFlow: 'row wrap',
		margin: '0',
		padding: 0,
		paddingBottom: '2em',
	};

	const li: CSS.Properties = {
		height: '40vh',
		flexGrow: 1,
		listStyleType: 'none',
	};
	const img: CSS.Properties = {
		maxHeight: '100%',
		minWidth: '100%',
		objectFit: 'cover',
		verticalAlign: 'bottom',
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', maxWidth: '800px' }}>
			{/* <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '800px', color: '#ae141c' }}>
				<BackArrow color='#ae141c' title='1946' />
			</div> */}
			<ul style={ul}>
				{[...Array(32)].map((_, i) => (
					<li style={li}>
						<img style={img} src={`https://solamor.s3.amazonaws.com/prod-img/1946/${i}.jpg`} alt={`1946 Pub num ${i}`} />
					</li>
				))}
			</ul>
		</div>
	);
};
export default Mille946;
