import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Container } from '../../styles/containers';
import { useUserAuth } from '../../utils/hooks';
import BackArrow from '../other/BackArrow';
import Symbol from '../other/Symbol';
import { CartMenu } from './CartMenu';
import { ProfileMenu } from './ProfileMenu';
import { LogoLink, SolamorLogo, StyledNavbar } from './style';

const Navbar = () => {
	const location = useLocation();
	const { t } = useTranslation();
	const { user } = useUserAuth();
	const theme = useTheme();

	const reduceTitle = useCallback(
		(title: string, startFrom: number): string => {
			let splitTitle: string[] = title.split('.');

			if (splitTitle.length === 1) return t(`navbar.${splitTitle[0]}.title`, t('navbar.default'));
			let reducedTitle: string = splitTitle.slice(0, splitTitle.length - startFrom).join('.');

			return t(`navbar.${reducedTitle}.title`, reduceTitle(reducedTitle, startFrom + 1));
		},
		[t],
	);

	return (
		<StyledNavbar>
			<BackArrow color='#f7c200' titleColor='#000000' title={reduceTitle(location.pathname.slice(1).replace('/', '.'), 0)} />
			<LogoLink to={user === null ? '/login' : '/products'}>
				<SolamorLogo />
			</LogoLink>
			<Container gap={16} width='200px' justify='flex-end' align='center'>
				{user && (
					<>
						<NavLink to='/products/favorites' style={{ display: 'flex', alignItems: 'center', position: 'relative', top: '1px', textDecoration: 'none' }}>
							{({ isActive }) => <Symbol name='favorite' size={36} weight={300} fill={isActive} color={isActive ? theme.mainColor : theme.blackColor} />}
						</NavLink>
						<CartMenu />
					</>
				)}
				<ProfileMenu />
			</Container>
		</StyledNavbar>
	);
};

export default Navbar;
