import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Contact, CustomFieldItem } from '../../models';
import { Client, ClientCustomFields, ClientDTO, initClientFromSellsyData } from '../../models/client';
import { post } from '../../services/baseService';
import { useToast } from '../../utils/hooks/useToast';

interface SellsyContextProps {
	token?: string;
	createClient: (client: ClientDTO) => Promise<Response>;
	getContact: (id: string) => Promise<any>;
	getContactCompanies: (id: string) => Promise<any>;
	customFields?: { [key: string]: CustomFieldItem };
	updateUser: (user: Contact, uid: string) => Promise<any>;
	updateCompany: (company: ClientDTO) => Promise<any>;
	updateCompanyLogistic: (company: ClientDTO) => Promise<any>;
	updateCompanyCustomFields: (id: number, customFields: Partial<ClientCustomFields>) => Promise<any>;
}

export const SellsyContext = createContext<SellsyContextProps>(undefined!);

interface SellsyContextProviderProps {
	children: React.ReactNode;
}

export const SellsyContextProvider = ({ children }: SellsyContextProviderProps) => {
	const [token, setToken] = useState<string | undefined>();
	const [customFields, setCustomFields] = useState<{ [key: string]: CustomFieldItem } | undefined>();
	const { t } = useTranslation();
	const { toast } = useToast();

	const getToken = () => {
		return post('/api/sellsy/tokens', {
			grantType: 'client_credentials',
		})
			.then((res) => {
				if (!res.ok) {
					throw new Error('fetchingToken');
				}
				return res.json();
			})
			.then((data) => {
				localStorage.setItem('sellsyToken', data.access_token);
				setToken(data.access_token);
				return data.access_token;
			})
			.catch((error) => {
				toast({
					message: t(`global.errors.${error.message}`),
					duration: 5000,
					closable: true,
				});
			});
	};

	const getCompanyCustomFields = async () => {
		if (customFields) return customFields;
		return fetch('/api/customfields/client')
			.then((res) => res.json())
			.then((data) => {
				setCustomFields(data);
				return data;
			});
	};

	const queryWithTokenRefresh = async (query: (token: string) => any) => {
		let newToken: string = '';
		if (!token) {
			newToken = await getToken();
		} else {
			newToken = token;
		}

		const response = await query(newToken);
		if (response.status === 401) {
			newToken = await getToken();
			if (newToken) {
				return query(newToken);
			}
		}
		return response;
	};

	const createClient = async (client: ClientDTO) => {
		return queryWithTokenRefresh((token) => post('/api/client', { client: client, token: token }));
	};

	const getContact = (id: string) => {
		return queryWithTokenRefresh((token) => {
			return fetch(`https://api.sellsy.com/v2/contacts/${id}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			})
				.then((res) => {
					if (!res.ok) {
						throw new Error('fetchingContact');
					}
					return res.json();
				})
				.then((data) => {
					return data;
				});
		});
	};

	const getContactCompanies = async (id: string): Promise<Client[]> => {
		let companyCustomFields = customFields;
		if (!companyCustomFields) {
			companyCustomFields = await getCompanyCustomFields();
		}
		return queryWithTokenRefresh(async (token) => {
			const fieldsString = Object.values(companyCustomFields!)
				.map((field) => `&embed[]=cf.${field.id}`)
				.join('');

			return fetch(`https://api.sellsy.com/v2/companies/search?embed[]=invoicing_address&embed[]=delivery_address${fieldsString}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					filters: {
						type: 'client',
						contacts: [parseInt(id)],
					},
				}),
			})
				.then((res) => {
					if (!res.ok) {
						throw new Error('fetchingContact');
					}
					return res.json();
				})
				.then((data) => {
					return Promise.all(data.data.map((company: any) => initClientFromSellsyData(company, token)));
				});
		});
	};

	const updateUser = async (user: Contact, uid: string) => {
		return queryWithTokenRefresh(async (token) => {
			return fetch(`/api/user/${uid}`, {
				method: 'PUT',
				body: JSON.stringify({
					user: user,
					token: token,
				}),
			});
		});
	};

	const updateCompany = async (company: ClientDTO) => {
		return queryWithTokenRefresh(async (token) => {
			return fetch(`/api/client/${company.id}`, {
				method: 'PUT',
				body: JSON.stringify({
					client: company,
					token: token,
				}),
			});
		});
	};

	const updateCompanyLogistic = async (company: ClientDTO) => {
		return queryWithTokenRefresh(async (token) => {
			return fetch(`/api/client/${company.id}/logistic`, {
				method: 'PUT',
				body: JSON.stringify({
					client: company,
					token: token,
				}),
			});
		});
	};

	const updateCompanyCustomFields = async (id: number, customFields: Partial<ClientCustomFields>) => {
		return queryWithTokenRefresh(async (token) => {
			return fetch(`/api/client/${id}/customfields`, {
				method: 'PUT',
				body: JSON.stringify({
					customFields: customFields,
					token: token,
				}),
			});
		});
	};

	useEffect(() => {
		getCompanyCustomFields();
		if (!token) {
			const token = localStorage.getItem('sellsyToken');
			if (!token) {
				getToken();
				return;
			}
			setToken(token);
		}
	}, []);

	return <SellsyContext.Provider value={{ token, customFields, createClient, getContact, getContactCompanies, updateUser, updateCompany, updateCompanyLogistic, updateCompanyCustomFields }}>{children}</SellsyContext.Provider>;
};
