export const get = async (path: string): Promise<Response> => {
	return fetch(path, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		}
	});
};

export const post = async (path: string, body: any): Promise<Response> => {
	return fetch(path, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Host': 'api.sellsy.com',
		},	
		body: JSON.stringify(body),
	});
};


export const put = async (path: string, body: any): Promise<any> => {
	return fetch(path, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});
}
