import { getCurrency } from '../constants/currencySellsyIds';
import { Product, ProductCustomFields, ProductPrice } from '../models/product';
import { Translatable } from '../models/translatable';
import { getProductImage } from './getProductImage';

const manageCustomFields = (product: any): ProductCustomFields => {
	return Object.values(product.customfields).reduce((acc: ProductCustomFields, field: any) => {
		switch (field.code) {

			case 'product-tags':
				acc.tags = field.formatted_value.split(', ');
				break;
			case 'product-size':
				const [lens, bridge, temple] = field.formatted_value.split('-');
				acc.size = {
					lens,
					bridge,
					temple,
				}
				break;
			case 'product-production':
				acc.production = field.formatted_value;
				break;
			case 'product-origin':
				acc.origin = field.formatted_value;
				break;
			case 'product-model':
				acc.model = field.formatted_value;
				break;
			case 'product-lens-specificity':
				acc.lensSpecificity = field.formatted_value.split(', ').filter((item: string) => item !== '');
				break;
			case 'product-lens-material':
				acc.lensMaterial = field.formatted_value;
				break;
			case 'product-lens-color':
				acc.lensColor = field.formatted_value;
				break;
			case 'product-frame-type':
				acc.frameType = field.formatted_value;
				break;
			case 'product-frame-material':
				acc.frameMaterial = field.formatted_value;
				break;
			case 'product-color-description-lang-fr-fr':
				acc.colorName['fr-FR'] = field.formatted_value;
				break;
			case 'product-color-description-lang-en-us':
				acc.colorName['en-US'] = field.formatted_value;
				break;
			case 'product-color-reference':
				acc.colorReference = field.formatted_value;
				break;
			case 'product-category':
				acc.category = field.formatted_value;
				break;
			case 'product-lens-base':
				acc.lensBase = field.formatted_value;
				break;
			case 'product-collection':
				acc.collection = field.formatted_value;
				break;
		}
		return acc
	}, {
		model: '',
		tags: [],
		lensSpecificity: [],
		colorName: {},
	})
}

export const getProductFromData = async (product: any): Promise<Product> => {
	const price: Translatable<ProductPrice> = Object.values(product.prices).reduce((acc: Translatable<ProductPrice>, value: any) => {
		acc[value.name as string] = {
			id: value.id,
			exclTax: value.amount,
			inclTax: value.amount * (1 + value.taxrate / 100),
			currency: getCurrency(value.currencyid)
		}
		return acc
	}, {})

	const customFields: ProductCustomFields = manageCustomFields(product);

	return {
		...customFields,
		id: product.id,
		reference: product.name,
		name: product.tradename,
		price: price,
		description: {
			'fr-FR': product.notesSample,
			'en-US': product.notesSample,
		},
		descriptionHTML: {
			'fr-FR': product.notesSampleHTML,
			'en-US': product.notesSampleHTML,
		},
		defaultImage: getProductImage(product.defaultImage),

		images: product.galery.map((image: any) => getProductImage(image)),
		stock: product.stockItemAvailableQt,
	};
}

export const getProductsFromData = async (products: any[]): Promise<Product[]> => {
	return Promise.all(products.map((product) => getProductFromData(product)))
};