const THEME = {
	mainColor: '#F7C200',
	secondaryColor: '#c4c4c4',
	tertiaryColor: '#F5F5F5',
	warningColor: '#ffc107',
	dangerColor: '#dc3545',
	infoColor: '#17a2b8',
	successColor: '#28a745',
	darkColor: '#343a40',
	lightColor: '#f8f9fa',
	whiteColor: '#FFFFFF',
	blackColor: '#201014',
	grayColor: '#999999',
	lightGrayColor: '#e8e8e8',
	button: {
		backgroundColor: {
			primary: '#F7C200',
			secondary: '#6c757d',
			tertiary: '#F5F5F5',
			warning: '#ffc107',
			danger: '#dc3545',
			info: '#17a2b8',
			success: '#28a745',
			dark: '#343a40',
			light: '#f8f9fa',
			white: '#FFFFFF',
		},
		borderColor: {
			primary: '#F7C200',
			secondary: '#6c757d',
			warning: '#ffc107',
			danger: '#dc3545',
			info: '#17a2b8',
			success: '#28a745',
			dark: '#343a40',
			light: '#f8f9fa',
		},
		color: {
			primary: '#000000',
			secondary: '#ffffff',
			warning: '#000000',
			danger: '#000000',
			info: '#000000',
			success: '#000000',
			dark: '#ffffff',
			light: '#000000',
		},
	},
	sidebar: {
		backgroundColor: '#ffffff',
		color: '#000000',
	},
	sidebarItem: {
		backgroundColor: 'transparent',
		color: '#000000',
	},
	navbar: {
		backgroundColor: '#ffffff',
		color: '#000000',
	},
	navbarItem: {
		backgroundColor: '#transparent',
		color: '#000000',
	},
	container: {
		backgroundColor: 'transparent',
	},
	view: {
		backgroundColor: '#F7C200',
	},
	list: {
		backgroundColor: '#ffffff',
		borderColor: 'transparent',
		color: '#000000',
	},
	listItem: {
		backgroundColor: 'transparent',
		color: '#000000',
	},
	dialog: {
		backgroundColor: '#ffffff',
		color: '#000000',
	},
	dialogBackground: {
		backgroundColor: 'rgba(0, 0, 0, 0.2)',
	},
	card: {
		backgroundColor: '#ffffff',
	},
	toast: {
		backgroundColor: '#ffffff',
	},
	box: {
		backgroundColor: '#ffffff',
	},
	input: {
		backgroundColor: '#ffffff',
		color: '#000000',
	},
	icon: {
		backgroundColor: 'transparent',
		color: '#000000',
	},
	label: {
		backgroundColor: 'transparent',
		color: 'transparent',
	},
	fab: {
		backgroundColor: 'transparent',
		borderColor: 'transparent',
		color: 'transparent',
	},
	select: {
		backgroundColor: '#ffffff',
		color: '#ffffff',
	},
	option: {
		backgroundColor: '#ffffff',
		selectedBackgroundColor: '#ffffff',
		hoverBackgroundColor: '#ffffff',
		color: '#ffffff',
		selectedColor: '#ffffff',
		hoverColor: '#ffffff',
	},
} as const;

export default THEME;
