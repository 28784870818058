import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { Cart } from '../../../models/list';
import { OrderInfo } from '../../../models/orderInfo';
import { useUpdateCartMutation } from '../../../reducers/cartApi';
import { Container, StyledBox } from '../../../styles/containers';
import { H2, HTLabel, HTPrice, Muted, StyledSpan } from '../../../styles/texts';
import { SectionTitle } from '../../../styles/texts/register';
import { useSellsy, useToast, useUserAuth } from '../../../utils/hooks';
import { useSessionSelector } from '../../../utils/hooks/useSessionSelector';
import { GlassesRow } from '../../Cart/GlassesRow';
import WindowLoading from '../../other/WindowLoading';
import Payment from '../Payment';
import { AddressP, AddressSpan, LineSeparator } from '../style';

interface ConfirmationProps {
	orderInfo: OrderInfo;
	setOrderInfo: (orderInfo: OrderInfo) => void;
	cart: Cart;
}

const Confirmation = ({ orderInfo, setOrderInfo, cart }: ConfirmationProps) => {
	const { isMobile } = useSessionSelector();
	const { t, i18n } = useTranslation();
	const language = i18n.language;
	const { user } = useUserAuth();
	const { customFields } = useSellsy();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [docId, setDocId] = useState<string>('');
	const [updateCart] = useUpdateCartMutation();
	const { toast } = useToast();

	useEffect(() => {
		if (orderInfo.cartRows.length === 0) {
			navigate('/cart', { replace: true });
		}
	}, [orderInfo]);

	useEffect(() => {
		if (Object.keys(cart).length === 0) return;
		setIsLoading(true);

		fetch('/api/payment-intent', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				cart: cart,
				clientId: orderInfo.client.id,
			}),
		})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setDocId(data.docId);

				const cartRows: OrderInfo['cartRows'] = data.cartRows.reduce((acc: OrderInfo['cartRows'], row: any) => {
					const product = orderInfo.cartRows.find((cartRow) => cartRow.product.id === row.productId)?.product;
					if (!product) {
						return acc;
					}

					return [
						...acc,
						{
							product: product,
							quantity: row.quantity,
							unitPrice: row.unitPrice,
						},
					];
				}, []);
				setOrderInfo({
					...orderInfo,
					cartRows: cartRows,
					totalPrice: data.totalPrice,
				});
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [cart]);

	const handleConfirm = async () => {
		setIsLoading(true);
		const language = customFields?.['company-language'].values.find((field) => field.id.toString() === orderInfo.client.language.toString())?.value;
		return fetch('/api/order-send-email', {
			method: 'POST',
			body: JSON.stringify({
				email: user!.email || '',
				language: language,
				docId: docId,
			}),
		})
			.then((res) => {
				if (!res.ok) {
					throw new Error('Error while sending email');
				}
			})
			.catch(() => {
				toast({
					message: t('confirmation.emailError'),
					duration: 5000,
					closable: true,
				});
			})
			.finally(() => {
				setIsLoading(false);
				navigate(`../order/${docId}`);
				updateCart({ cart: {}, clientId: orderInfo.client.id.toString() });
			});
	};

	return (
		<>
			{(user === undefined || isLoading) && <WindowLoading />}
			{user === null && <Navigate replace to='/login' />}
			{user && user.currentCompany && (
				<>
					<Container width='100%' justify='space-between' align='center'>
						<H2>{t('confirmation.title')}</H2>
					</Container>
					<Container width='100%' flexWrap='wrap' gap={isMobile ? 16 : 60} justify='space-between'>
						<Container flex='0 1 500px' direction='column' gap={32}>
							<Container direction='column'>
								<SectionTitle>{t('delivery.summaryArticleCount', { count: orderInfo.totalQuantity })}</SectionTitle>
								{orderInfo.client.rateCategory && (
									<StyledBox vertical>
										<Container width='100%' justify='space-between' align='center'>
											<StyledSpan>{t('delivery.htAmount')}</StyledSpan>
											<Container align='baseline' gap={4} margin='8px 0'>
												<HTPrice>
													{t('global.price', {
														value: orderInfo.totalPrice,
														formatParams: {
															value: {
																currency: orderInfo.client.rateCategory.currency,
																locale: language,
															},
														},
													})}
												</HTPrice>
												{!user?.currentCompany?.rateCategory?.includesTaxes && <HTLabel>{t('global.ht')}</HTLabel>}
											</Container>
										</Container>
										<LineSeparator />
										{orderInfo.client.rateCategory.taxRate > 0 && (
											<Container width='100%' justify='space-between' align='center'>
												<StyledSpan>{t('cart.vat', { amount: 20 })}</StyledSpan>
												<Container align='baseline' gap={4} margin='8px 0'>
													<HTPrice>
														{t('global.price', {
															value: (orderInfo.client.rateCategory.taxRate / 100) * orderInfo.totalPrice,
															formatParams: {
																value: {
																	currency: orderInfo.client.rateCategory.currency,
																	locale: language,
																},
															},
														})}
													</HTPrice>
												</Container>
											</Container>
										)}

										<Container width='100%' justify='space-between' align='center'>
											<StyledSpan style={{ fontWeight: 600, fontSize: '1.1em' }}>{t('delivery.totalAmount')}</StyledSpan>
											<Container align='baseline' gap={4} margin='8px 0'>
												<HTPrice style={{ fontWeight: 600, fontSize: '1.1em' }}>
													{t('global.price', {
														value: orderInfo.totalPrice + (orderInfo.client.rateCategory.taxRate / 100) * orderInfo.totalPrice,
														formatParams: {
															value: {
																currency: orderInfo.client.rateCategory.currency,
																locale: language,
															},
														},
													})}
												</HTPrice>
											</Container>
										</Container>
									</StyledBox>
								)}
							</Container>
							{orderInfo.cartRows.map(({ product, quantity }) => (
								<Container gap={32}>
									<GlassesRow key={product.id} glasses={product} quantity={quantity} onQuantityChange={() => {}} />
								</Container>
							))}
						</Container>
						<Container flex='0 1 500px' direction='column' gap={32}>
							<Payment onPaymentConfirmed={handleConfirm} defaultPaymentMethod={orderInfo.client.customFields['company-payment-method'].value} />

							<Container direction='column'>
								<SectionTitle>{t('delivery.estimatedDeliveryDate')}</SectionTitle>
								<Muted>{t('confirmation.estimatedDeliveryLater')}</Muted>
							</Container>
							<Container direction='column'>
								<SectionTitle>{t('delivery.billingAddress')}</SectionTitle>

								<Container direction='column' gap={8}>
									<AddressSpan>{user.currentCompany.name}</AddressSpan>
									<AddressP>{t('global.formattedAddress', { street: user.currentCompany?.invoicingAddress.street, zip: user.currentCompany?.invoicingAddress.zip, city: user.currentCompany?.invoicingAddress.city })}</AddressP>
									<Container direction='column'>
										<AddressSpan>{user.currentCompany.country === 'FR' ? t('delivery.siren', { siren: user.currentCompany.siren }) : t('delivery.nationalId', { nationalId: user.currentCompany.customFields['company-national-id'].value })}</AddressSpan>
										<AddressSpan>{t('delivery.vat', { vat: user.currentCompany.vat })}</AddressSpan>
									</Container>
								</Container>
							</Container>
							<Container direction='column'>
								<SectionTitle>{t('delivery.shippingAddress')}</SectionTitle>
								<Container direction='column' gap={8}>
									<AddressSpan>{user.currentCompany.name}</AddressSpan>
									<AddressP>{t('global.formattedAddress', { street: orderInfo.deliveryAddress.street, zip: orderInfo.deliveryAddress.zip, city: orderInfo.deliveryAddress.city })}</AddressP>
									<Container direction='column'>
										<AddressSpan>{t('delivery.shippingContact', { firstname: user.firstName, lastname: user.lastName })}</AddressSpan>
										<AddressSpan>{t('delivery.shippingContactPhone', { phone: user.mobilePhone })}</AddressSpan>
									</Container>
								</Container>
							</Container>
						</Container>
					</Container>
				</>
			)}
		</>
	);
};

export default Confirmation;
