import styled from 'styled-components';
import { MainButton } from './../../../styles/buttons/index';

export const ConfirmPaymentButton = styled(MainButton)`
    margin:  0 16px 16px 16px;
    box-sizing: border-box;
    width: auto;

`;

export const PaymentForm = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    box-sizing: border-box;
    overflow: hidden;
    align-items: stretch;
`;

export const PaymentMethodRadio = styled.input.attrs(() => ({ type: 'radio' }))`
    cursor: pointer;
`;

export const PaymentEntryLabel = styled.label`
    cursor: pointer;
`;

export const PaymentEntryWrapper = styled.div`
    display: flex;
    align-items: baseline;
    padding: 12px;
    width: 100%;
    cursor: pointer;

    & input[type=radio] {
        display: none;
    }

    & ${PaymentEntryLabel}::before {
        content: "";
        display: inline-block;
        position: relative;
        margin: 0 5px 0 0;
        width: 8px;
        height: 8px;
        border-radius: 11px;
        right: 2px;
        border: 1px solid ${(props) => props.theme.blackColor};
        background-color: transparent;
        cursor: pointer;
    }

    & input[type=radio]:checked + ${PaymentEntryLabel}::after {
        content: "";
        position: absolute;
        display: inline-block;
        border: 1px solid ${(props) => props.theme.mainColor};
        background-color: ${(props) => props.theme.mainColor}10;
        width: 100%;
        height: calc(1em + 24px);
        border-radius: 4px;
        left: 0;
        transform: translateY(-8px);
        cursor: pointer;
    }

    & input[type=radio]:checked + ${PaymentEntryLabel}::before {
        background-color: ${(props) => props.theme.mainColor};
        border-color: ${(props) => props.theme.mainColor};
    }

`;



export const PaymentMethodForm = styled.form`
    width: 100%;
`;