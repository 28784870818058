import styled from 'styled-components';

export const ReferencePlaceholder = styled.span`
	color: rgba(0, 0, 0, 0.4);
	font-weight: 400;
	font-size: 0.8em;
`;

export const PricePlaceholder = styled.span`
	color: rgba(0, 0, 0, 0.4);
	font-weight: 400;
	font-size: 0.8em;
`;

export const Description = styled.p`
	margin: 16px 0;
	color: ${({ theme }) => theme.grayColor};
    font-size: 1em;
    font-weight: 400;
`;

export const CartTitle = styled.h1`
	margin: 0;
	display: flex;
	align-items: center;
`;

export const GroupTitle = styled.h2`
	color: rgba(0, 0, 0, 0.4);
	font-weight: 400;
	font-size: 1em;
	align-self: flex-start;
	margin: 0;
`;

export const TotalPriceValue = styled.span`
	font-size: 1.2em;
	font-weight: 600;
`;

export const TotalPriceLabel = styled.label`
	font-size: 1.2em;
	font-weight: 500;
	margin-right: 8px;
`;

export const TotalPriceWrapper = styled.div`
	padding-right: 16px;
	white-space: nowrap;
`;

export const QuantitySpan = styled.span`
	font-size: 1em;
	font-weight: 500;
	width: 48px;
	text-align: center;
`;

export const ClientPermissionMessage = styled.label`
	font-size: 1em;
	font-weight: 500;
	/* color: rgba(0, 0, 0, 1); */
	margin: 0;
	cursor: pointer;
`;

export const PriceFilterLabel = styled.label`
	max-width: 80px;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 0.9em;
	font-weight: 500;
` ;