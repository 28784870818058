import { useClickOutside } from 'aaastyle';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isUserAndCompany } from '../../../models/extUser';
import { useGetCartQuery, useUpdateCartMutation } from '../../../reducers/cartApi';
import { useGetProductsByIdsQuery } from '../../../reducers/productApi';
import { Container } from '../../../styles/containers';
import { LoadingSpinner } from '../../../styles/loading';
import { HTLabel, HTPrice, Muted, StyledSpan } from '../../../styles/texts';
import { useToast, useUserAuth } from '../../../utils/hooks';
import { useSessionSelector } from '../../../utils/hooks/useSessionSelector';
import Symbol from '../../other/Symbol';
import { DropdownMenu, ProfileButton, ProfileWrapper } from '../ProfileMenu/style';
import CartItemRow from './CartItemRow';
import { EmptyCardSpan, QuantityPin, SeeProductsButton } from './style';
import { useClientPricing } from '../../../utils/hooks/useClientPricing';
import { useTheme } from 'styled-components';

const CartMenu = () => {
	const [expand, setExpand] = useState(false);
	const { user } = useUserAuth();
	const { data: cart } = useGetCartQuery((user?.currentCompany?.id || '').toString());
	const { data: products, isLoading: isLoadingProducts, isSuccess: isSuccessProducts } = useGetProductsByIdsQuery(Object.keys(cart || {}));
	const navigate = useNavigate();
	const { isMobile } = useSessionSelector();
	const [updateCart, result] = useUpdateCartMutation();
	const { getProductPrice } = useClientPricing();
	const { toast } = useToast();
	const { t, i18n } = useTranslation();
	const language = i18n.language;
	const theme = useTheme();
	const dropdownRef = useRef<HTMLDivElement>(null);
	const totalQuantity = Object.values(cart || {}).reduce((acc, curr) => acc + curr.quantity, 0);
	useClickOutside(dropdownRef, () => setExpand(false));

	useEffect(() => {
		if (result.isError) {
			toast({
				message: t('global.errors.updatingCart'),
				duration: 4000,
				closable: true,
			});
		}
	}, [result]);

	const handleExpand = () => {
		if (isMobile) {
			navigate('/cart');
			return;
		}
		setExpand((expand) => !expand);
	};

	const handleHide = () => {
		setExpand(false);
	};

	const handleItemClick = (productId: string) => {
		navigate(`/products/${productId}`);
		handleHide();
	};

	const handleRemoveItem = (productId: string) => {
		let newCart = { ...cart };
		delete newCart[productId];
		updateCart({
			clientId: user!.currentCompany!.id.toString(),
			cart: newCart,
		});
	};

	return (
		<ProfileWrapper>
			<ProfileButton onClick={handleExpand}>
				<Symbol name='shopping_bag' size={36} color={theme.blackColor} weight={300} />

				{totalQuantity > 0 && <QuantityPin length={totalQuantity.toString().length}>{totalQuantity}</QuantityPin>}
			</ProfileButton>
			<AnimatePresence>
				{expand && (
					<DropdownMenu ref={dropdownRef}>
						{isLoadingProducts && <LoadingSpinner />}
						{isSuccessProducts &&
							(products && products.length > 0 ? (
								<Container direction='column' padding='16px' gap={16}>
									{cart &&
										[...products]
											.sort((a, b) => cart[b.id]?.quantity - cart[a.id]?.quantity)
											.slice(0, 5)
											.map((product) => <CartItemRow key={product.id} onRemove={() => handleRemoveItem(product.id)} onClick={() => handleItemClick(product.id)} product={product} quantity={cart?.[product.id]?.quantity || 0} />)}
									{products.length > 5 && (
										<Container justify='flex-start' align='center' padding='0 0 0 2px'>
											<HTPrice>{t('navbar.cartMenu.moreItems', { count: Object.keys(cart || {}).length - 5 })}</HTPrice>
										</Container>
									)}
									<Container width='100%' justify='space-between' align='center'>
										<StyledSpan>{t('navbar.cartMenu.total')}</StyledSpan>
										{isUserAndCompany(user) && (
											<Container align='baseline' gap={4} margin='8px 0'>
												<HTPrice>
													{t('global.price', {
														value: products.reduce((acc, p) => {
															const price = getProductPrice(p);
															return acc + (price?.exclTax || 0) * (cart?.[p.id]?.quantity || 0);
														}, 0),
														formatParams: {
															value: {
																currency: user?.currentCompany?.rateCategory?.currency || 'EUR',
																locale: language,
															},
														},
													})}
												</HTPrice>

												{!user?.currentCompany?.rateCategory?.includesTaxes && <HTLabel>{t('global.ht')}</HTLabel>}
											</Container>
										)}
									</Container>
									<SeeProductsButton to='/cart' onClick={handleHide}>
										{t('navbar.cartMenu.seeCart')}
									</SeeProductsButton>
								</Container>
							) : (
								<Container direction='column' justify='flex-start' align='center' gap={4} padding='16px'>
									<EmptyCardSpan>{t('navbar.cartMenu.emptyCart')}</EmptyCardSpan>
									<Muted style={{ whiteSpace: 'nowrap' }}>{t('navbar.cartMenu.emptyCartMuted')}</Muted>
									<SeeProductsButton to='/products' onClick={handleHide}>
										{t('navbar.cartMenu.seeProducts')}
									</SeeProductsButton>
								</Container>
							))}
					</DropdownMenu>
				)}
			</AnimatePresence>
		</ProfileWrapper>
	);
};

export default CartMenu;
