import { User } from "firebase/auth";
import { Contact } from "./user";
import { Client } from "./client";

export interface ExtUser extends User, Omit<Contact, 'email'> {

}

export const isUserAndCompany = (user: ExtUser | null | undefined): user is ExtUser & { currentCompany: Client } => {
    return user?.currentCompany !== undefined;
}

export const isUser = (user: ExtUser | null | undefined): user is ExtUser => {
    return user !== null && user !== undefined;
}