import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import logo from '../../../assets/images/solamor.png';
import { ReducerState } from '../../../models';
import { Product } from '../../../models/product';
import { useGetProductsByIdsQuery } from '../../../reducers/productApi';
import { getOrder } from '../../../services/orderService';
import { DownloadButton } from '../../../styles/buttons/orderConfirmation';
import { Container } from '../../../styles/containers';
import { LogoImg } from '../../../styles/containers/cart';
import { ConfirmationBox, ConfirmationBoxHeader, SummaryContainer } from '../../../styles/containers/orderConfirmation';
import { StepCounter, StepperItem, StepperWrapper } from '../../../styles/containers/stepper';
import { StyledSpan } from '../../../styles/texts';
import { MailingMessage, OrderSummaryTitle, ThanksMessage } from '../../../styles/texts/orderConfirmation';
import { GlassesRow } from '../../Cart/GlassesRow';
import { Icon } from '../../other';
import NotFound from '../../other/NotFound';
import WindowLoading from '../../other/WindowLoading';

const OrderConfirmation = () => {
	const { id: orderId } = useParams();
	const { t } = useTranslation();
	const [products, setProducts] = useState<any[]>([]);
	const [address, setAddress] = useState<any>({});
	const { isMobile } = useSelector((state: ReducerState) => state.session);
	const [notFound, setNotFound] = useState<boolean>(false);
	const [loaded, setLoaded] = useState(false);
	const [downloadLink, setDownloadLink] = useState('');
	const productReferences = useMemo(() => products.map((product) => product.linkedid), [products]);
	const [cartRows, setCartRows] = useState<any>([]);

	const { data, isLoading: isLoadingProducts } = useGetProductsByIdsQuery(productReferences);

	useEffect(() => {
		setCartRows(
			(data || []).map((product: Product) => {
				const { qt, unitAmount } = products.find((p) => p.linkedid === product.id) || { qt: 0, unitAmount: 0 };
				return {
					product,
					quantity: parseInt(qt) || 0,
					unitPrice: unitAmount || 0,
				};
			}),
		);
	}, [data]);

	useEffect(() => {
		if (orderId) {
			getOrder(orderId)
				.then((res: any) => {
					setLoaded(true);
					// setCurrency(res.response.currencies[res.response.currency].name);
					setAddress(res.response.shipAddress);
					setProducts(Object.values(res.response.map.rows).filter((row: any) => row.type === 'item'));
					setDownloadLink(res.publicLink);
				})
				.catch((err: any) => {
					console.log(err);

					setLoaded(true);
					setNotFound(true);
				});
		}
	}, [orderId]);

	return (
		<>
			{loaded || isLoadingProducts ? (
				notFound ? (
					<NotFound />
				) : (
					<Container width='100%' padding={isMobile ? '0' : '30px 0'} justify='center'>
						<ConfirmationBox>
							<ConfirmationBoxHeader>
								{/* <div style={{ position: 'absolute', left: '0', top: '8px' }}>
									<BackArrow icon='home' path='/' />
								</div> */}
								<Icon style={{ margin: '24px 0' }} name='check-circle' size={96} color='#f7c200' />
								<ThanksMessage>{t('orderConfirmation.thanksMessage')}</ThanksMessage>
							</ConfirmationBoxHeader>

							<Container margin='24px 0' gap={24} direction='column' width='100%'>
								<SummaryContainer>
									<OrderSummaryTitle>{t('orderConfirmation.orderSummary')}</OrderSummaryTitle>

									<Container direction='column' width='100%' gap={32}>
										{cartRows.map((row: any) => (
											<GlassesRow glasses={row.product} quantity={row.quantity} onQuantityChange={() => {}} />
										))}
									</Container>
								</SummaryContainer>
								<Container margin='24px 0 0 0' gap={24} flexWrap='wrap' justify='space-between'>
									<Container direction='column' flex='0 1 300px'>
										<OrderSummaryTitle>{t('orderConfirmation.address', 'Adresse de livraison')}</OrderSummaryTitle>
										<Container direction='column'>
											{Object.values(address?.partsToDisplay || {}).map((part: any, index: number) => (
												<StyledSpan key={index}>{part.txt}</StyledSpan>
											))}
										</Container>
									</Container>
									<Container justify='flex-start' flex='0 1 350px'>
										<StepperWrapper>
											<StepperItem complete>
												<StepCounter>
													<Icon name='receipt' size={24} />
												</StepCounter>
												<label>{t('orderConfirmation.ordered')}</label>
											</StepperItem>
											<StepperItem>
												<StepCounter>
													<Icon name='paid' size={24} />
												</StepCounter>
												<label>{t('orderConfirmation.paid')}</label>
											</StepperItem>
											<StepperItem>
												<StepCounter>
													<Icon name='shipping' size={24} />
												</StepCounter>
												<label>{t('orderConfirmation.shipping')}</label>
											</StepperItem>
										</StepperWrapper>
									</Container>
								</Container>
							</Container>
							<MailingMessage>{t('orderConfirmation.mailingMessage')}</MailingMessage>
							<DownloadButton href={downloadLink} target='_blank'>
								<Icon name='file-download' size={24} color='#F7C200' />
								{t('orderConfirmation.downloadLink')}
							</DownloadButton>
							<LogoImg style={{ alignSelf: 'center', width: '200px', margin: '24px' }} src={logo} />
						</ConfirmationBox>
					</Container>
				)
			) : (
				<WindowLoading />
			)}
		</>
	);
};

export default OrderConfirmation;
