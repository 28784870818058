import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledSpan = styled.span`
	font-size: 1em;
	font-weight: 500;
	color: ${({ theme }) => theme.blackColor};
`;

export const Muted = styled.span<{ size?: string }>`
	color: ${({ theme }) => theme.grayColor};
	font-size: ${({ size }) => size || '0.875em'};
`;

export const Tag = styled.label<{ color?: string }>`
	display: flex;
	background-color: ${({ color }) => color};
	font-size: 0.8em;
	font-weight: 400;
	margin: 0;
	text-transform: uppercase;
	border-radius: 24px;
	padding: 2px 12px;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
`;

export const StyledLink = styled(Link)`
	text-decoration: none;
	color: rgba(0, 0, 0, 0.9);
	/* background-color: rgba(247, 194, 0, 1); */
	/* padding: 12px 24px; */
	font-size: 1em;
	font-weight: 600;
	/* border-radius: 8px; */
	cursor: pointer;
	height: 20px;

	&:hover {
		/* background-color: rgba(247, 194, 0, 0.8); */
	}
`;

export const StyledButton = styled.button`
	color: rgba(0, 0, 0, 0.9);
	background-color: transparent;
	/* padding: 12px 24px; */
	font-size: 1em;
	font-weight: 600;
	/* border-radius: 8px; */
	cursor: pointer;
	border: none;
	outline: none;
	height: 20px;
	padding: 0;

	&:hover {
		/* background-color: rgba(247, 194, 0, 0.8); */
	}
`;

export const H1 = styled.h1`
	font-size: 1.5em;
    font-weight: 700;
    color: ${({ theme }) => theme.blackColor};
    margin: 12px 0;
    padding: 0;
    text-transform: uppercase;
`;

export const H2 = styled.h2`
	font-size: 1.6em;
	font-weight: 600;
	color: ${({ theme }) => theme.blackColor};
	margin: 12px 0;
`;

export const NoResultsSpan = styled(StyledSpan)`
	font-size: 1em;
	font-weight: 600;
	color: ${(props) => props.theme.mainColor};
	text-decoration: underline;
	margin: 32px;
`;

export const InputError = styled.span`
	font-size: 1em;
    font-weight: 400;
    color: #e34850;
    margin: 0;
    padding: 0;
`;

export const HTPrice = styled.span`
	font-size: 1em;
	font-weight: 500;
	color: ${({ theme }) => theme.blackColor};
`;

export const HTLabel = styled.span`
	font-size: 0.7em;
	font-weight: 300;
	color: ${({ theme }) => theme.blackColor};
`;

export const RPPPrice = styled.span`
	font-size: 0.875em;
	font-weight: 500;
	color: ${({ theme }) => theme.mainColor};
`;

export const RPPLabel = styled.span`
	font-size: 0.6em;
	font-weight: 300;
	color: ${({ theme }) => theme.mainColor};
`;