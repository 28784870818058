import { ComponentPropsWithoutRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ClientDTO } from '../../../models/client';
import { Container, FormField, FormGroup, FormRow } from '../../../styles/containers';
import { PhoneTextInput, RegisterForm, RegisterTextInput, RequiredPlaceholder } from '../../../styles/inputs/register';
import { InputError, Muted, StyledSpan } from '../../../styles/texts';
import { ErrorTitle, InputLabel, SectionTitle } from '../../../styles/texts/register';
import { validateAndFormatClientField } from '../../../utils/validateAndFormatClientField';
import DialCodeSelect from '../../other/DialCodeSelect';
import { ButtonBar, NextButton, PrevButton } from '../Register/style';

export interface UserProps extends ComponentPropsWithoutRef<'form'> {
	updating?: boolean;
	onSubmit?: (data: any) => void;
	client: ClientDTO;
	setClient: (client: ClientDTO) => void;
}

const User = ({ updating, client, setClient, onSubmit, ...rest }: UserProps) => {
	const formRef = useRef<HTMLFormElement>(null);
	const errorTitleRef = useRef<HTMLHeadingElement>(null);
	const [messages, setMessages] = useState<{ [name: string]: string | undefined }>({});
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [homeDialCode, setHomeDialCode] = useState<string>(client.contact?.mobilePhone?.split(' ')[0] || '+33');
	const [mobileDialCode, setMobileDialCode] = useState<string>(client.contact?.homePhone?.split(' ')[0] || '+33');
	const handleSubmit = () => {
		setMessages({});
		if (formRef.current) {
			let isFormValid: boolean = true;
			let errorMessages: any = {};
			const body: any = {};

			Array.from(formRef.current.elements).forEach((element: any) => {
				const { isValid, message, value } = validateAndFormatClientField({ name: element.name, value: element.value });
				if (!isValid) {
					isFormValid = false;
					errorMessages = { ...errorMessages, [element.name]: message };
				}

				body[element.name] = value;
			});

			if (!isFormValid) {
				setMessages(errorMessages);
				errorTitleRef.current?.scrollIntoView({ behavior: 'smooth' });
				return;
			}
			setClient({
				...client,
				contact: {
					role: 'contact',
					id: -1,
					...client.contact,
					firstName: body.contactFirstName,
					lastName: body.contactLastName,
					function: body.contactFunction,
					email: body.contactEmail,
					homePhone: `${homeDialCode} ${body.contactHomePhone}`,
					mobilePhone: `${mobileDialCode} ${body.contactMobilePhone}`,
					password: body.password,
				} as any,
			});
			onSubmit && onSubmit({});
			navigate('../logistics');
		}
	};

	const handleUpdate = () => {
		setMessages({});
		if (formRef.current) {
			let isFormValid: boolean = true;
			let errorMessages: any = {};
			const body: any = {};

			Array.from(formRef.current.elements).forEach((element: any) => {
				const { isValid, message, value } = validateAndFormatClientField({ name: element.name, value: element.value });
				if (!isValid) {
					isFormValid = false;
					errorMessages = { ...errorMessages, [element.name]: message };
				}

				body[element.name] = value;
			});

			if (!isFormValid) {
				setMessages(errorMessages);
				errorTitleRef.current?.scrollIntoView({ behavior: 'smooth' });
				return;
			}
			onSubmit &&
				onSubmit({
					...client.contact!,
					function: body.contactFunction,
					email: body.contactEmail,
					homePhone: `${homeDialCode} ${body.contactHomePhone}`,
					mobilePhone: `${mobileDialCode} ${body.contactMobilePhone}`,
				});
		}
	};

	return (
		<>
			<RegisterForm noValidate {...rest} ref={formRef}>
				{Object.entries(messages).length > 0 && <ErrorTitle ref={errorTitleRef}>{t('clientRegister.invalidFormTitle')}</ErrorTitle>}
				<SectionTitle>{t('clientRegister.section.personalInformation')}</SectionTitle>
				<FormGroup>
					<FormRow>
						<FormField>
							<InputLabel>{t('clientRegister.contact.email')}</InputLabel>
							<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
							<RegisterTextInput
								autoComplete='off'
								autoCorrect='off'
								spellCheck='false'
								// value={companyInfos.contactEmail}
								// onChange={(e) => handleContactMailChange(e.target.value)}
								invalid={'contactEmail' in messages}
								type='email'
								defaultValue={client.contact?.email || client.email}
								name='contactEmail'
								placeholder={t('clientRegister.contact.email')}
							/>
							{'email' in messages && <InputError>{t('clientRegister.errors.email')}</InputError>}
						</FormField>
						{updating || (
							<FormField>
								<InputLabel>{t('clientRegister.contact.password')}</InputLabel>
								<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
								<RegisterTextInput autoComplete='off' autoCorrect='off' spellCheck='false' invalid={'password' in messages} type='password' defaultValue={(client as any).contact?.password} name='password' placeholder={t('clientRegister.contact.password')} />
								{'password' in messages && <InputError>{t('clientRegister.errors.password')}</InputError>}
							</FormField>
						)}
					</FormRow>
					<FormRow>
						{updating ? (
							<FormField>
								<InputLabel>{t('clientRegister.contact.firstName')}</InputLabel>
								<StyledSpan>{client.contact?.firstName}</StyledSpan>
							</FormField>
						) : (
							<FormField>
								<InputLabel>{t('clientRegister.contact.firstName')}</InputLabel>
								<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
								<RegisterTextInput autoComplete='off' autoCorrect='off' spellCheck='false' invalid={'contactFirstName' in messages} type='text' defaultValue={client.contact?.firstName} name='contactFirstName' placeholder={t('clientRegister.contact.firstName')} />
								{'contactFirstName' in messages && <InputError>{t('clientRegister.errors.required')}</InputError>}
							</FormField>
						)}

						{updating ? (
							<FormField>
								<InputLabel>{t('clientRegister.contact.lastName')}</InputLabel>
								<StyledSpan>{client.contact?.lastName}</StyledSpan>
							</FormField>
						) : (
							<FormField>
								<InputLabel>{t('clientRegister.contact.lastName')}</InputLabel>
								<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
								<RegisterTextInput autoComplete='off' autoCorrect='off' spellCheck='false' invalid={'contactLastName' in messages} type='text' defaultValue={client.contact?.lastName} name='contactLastName' placeholder={t('clientRegister.contact.lastName')} />
								{'contactLastName' in messages && <InputError>{t('clientRegister.errors.required')}</InputError>}
							</FormField>
						)}
					</FormRow>
					<FormRow>
						<FormField>
							<InputLabel>{t('clientRegister.contact.function')}</InputLabel>
							<RegisterTextInput autoComplete='off' autoCorrect='off' spellCheck='false' type='text' defaultValue={client.contact?.function} name='contactFunction' placeholder={t('clientRegister.contact.function')} />
						</FormField>
					</FormRow>
					<FormRow>
						<FormField>
							<InputLabel>{t('clientRegister.contact.homePhone')}</InputLabel>
							<Container>
								<DialCodeSelect
									onChange={(value) => {
										setHomeDialCode(value);
									}}
									defaultValue={homeDialCode}
								/>
								<PhoneTextInput invalid={'contactHomePhone' in messages} type='number' name='contactHomePhone' defaultValue={client.contact?.homePhone?.split(' ')[1]} placeholder={t('clientRegister.contact.homePhone')} />
							</Container>
							{'contactHomePhone' in messages && <InputError>{t('clientRegister.errors.phone')}</InputError>}
						</FormField>
						<FormField>
							<InputLabel>{t('clientRegister.contact.mobilePhone')}</InputLabel>
							<RequiredPlaceholder>{t('clientRegister.required')}</RequiredPlaceholder>
							<Container>
								<DialCodeSelect
									onChange={(value) => {
										setMobileDialCode(value);
									}}
									defaultValue={mobileDialCode}
								/>
								<PhoneTextInput invalid={'contactMobilePhone' in messages} type='number' name='contactMobilePhone' defaultValue={client.contact?.mobilePhone?.split(' ')[1]} placeholder={t('clientRegister.contact.mobilePhone')} />
							</Container>
							{'contactMobilePhone' in messages && <InputError>{t('clientRegister.errors.phone')}</InputError>}
						</FormField>
					</FormRow>
				</FormGroup>
			</RegisterForm>
			{updating && (
				<Container margin='0 0 16px 0'>
					<Muted>{t('profile.uneditableFieldsMessage')}</Muted>
				</Container>
			)}
			<ButtonBar>
				{updating ? (
					<NextButton onClick={handleUpdate} type='submit'>
						{t('global.save')}
					</NextButton>
				) : (
					<>
						<PrevButton type='button' onClick={() => navigate(-1)}>
							{t('global.previous')}
						</PrevButton>
						<NextButton onClick={handleSubmit} type='submit'>
							{t('global.next')}
						</NextButton>
					</>
				)}
			</ButtonBar>
		</>
	);
};

export default User;
