import { useTranslation } from 'react-i18next';
import { useGetClientOrdersQuery } from '../../../../reducers/orderApi';
import { Container } from '../../../../styles/containers';
import { SectionTitle } from '../../../../styles/texts/register';
import { useUserAuth } from '../../../../utils/hooks';
import WindowLoading from '../../../other/WindowLoading';
import OrderRow from './OrderRow/OrderRow';

const Orders = () => {
	const { t } = useTranslation();
	const { user } = useUserAuth();
	const { data: orders = [], isLoading, isSuccess } = useGetClientOrdersQuery((user?.currentCompany?.id || '0').toString());

	return (
		<Container width='100%' direction='column'>
			<SectionTitle>{t('orders.title')}</SectionTitle>
			{isLoading && <WindowLoading />}
			{isSuccess && (
				<Container direction='column' width='100%' gap={32}>
					{orders.map((order) => (
						<OrderRow key={order.id} order={order} />
					))}
				</Container>
			)}
		</Container>
	);
};

export default Orders;
