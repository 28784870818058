import { forwardRef } from 'react';
import { Container } from '../../styles/containers';
import { SearchInput } from '../../styles/inputs';
import { LoadingSpinner } from '../../styles/loading';
import Icon from './Icon';

interface SearchBarProps extends Omit<React.ComponentPropsWithRef<'input'>, 'onChange'> {
	onChange?(value: string): void;
	placeholder?: string;
	searching?: boolean;
}

const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>((props: SearchBarProps, ref) => {
	return (
		<Container margin='-8px 0' width='100%' gap={12}>
			{props.searching ? <LoadingSpinner style={{ position: 'absolute', top: '12px', right: '8px' }} size='18px' borderWidth='3px' /> : <Icon style={{ position: 'absolute', top: '12px', right: '8px' }} name='search' size={28} color='rgba(0, 0, 0, 0.7)' />}
			<SearchInput ref={ref} {...props} onChange={(e) => (props.onChange ? props.onChange(e.target.value) : null)} type='search' />
		</Container>
	);
});

export default SearchBar;
