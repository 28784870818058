import { Toast } from 'aaastyle';
import { createContext, ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';

interface ToastProps {
	message: ReactNode;
	duration: number;
	closable?: boolean;
	onClose?: Function;
}

interface ToastContextProps {
	toast: (props: ToastProps) => void;
}

export const ToastContext = createContext<ToastContextProps>(undefined!);

interface ToastContextProviderProps {
	children: React.ReactNode;
}

const ToastContextProvider = ({ children }: ToastContextProviderProps) => {
	const [message, setMessage] = useState<ReactNode>(null);
	const [duration, setDuration] = useState<number>(0);
	const [closable, setClosable] = useState<boolean>(false);
	const [, setOnClose] = useState<Function>(() => () => {});
	const toastRef = useRef<any>(null);

	const toast = (props: ToastProps) => {
		setMessage('');
		setMessage(props.message);
		setDuration(props.duration);
		setClosable(props.closable || false);
		setOnClose(props.onClose || (() => () => {}));
		toastRef.current.toast();
		setTimeout(() => {
			setMessage(null);
		}, props.duration + 400);
	};

	return (
		<ToastContext.Provider value={{ toast }}>
			<StyledToast role='alert' aria-live='assertive' ref={toastRef} duration={duration} closable={closable}>
				{message}
			</StyledToast>
			{children}
		</ToastContext.Provider>
	);
};

const StyledToast = styled(Toast)`
	z-index: 100000;
	width: auto;
	max-width: 80vw;
	padding: 8px 24px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	white-space: nowrap;
`;

export default ToastContextProvider;
