import styled from "styled-components";

export const FullSceenContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100svh;
    background-color: #fff;
    z-index: 300;
`;

export const FullScreenImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

export const ImageRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    overflow-x: auto;
    width: 100%;
    position: absolute;
    z-index: 100;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 32px 16px;
    box-sizing: border-box;
    overflow-x: auto;

`;