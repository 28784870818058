import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en-US.json';
import fr from './locales/fr-FR.json';

const langDetectorOptions = {
	// order and from where user language should be detected
	order: ['querystring', 'cookie', 'localStorage', 'navigator'],

	// keys or params to lookup language from
	lookupQuerystring: 'lang',
	lookupCookie: 'locale',
	lookupLocalStorage: 'locale',

	// cache user language on
	caches: ['localStorage', 'cookie'],
	excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

	// only detect languages that are in the whitelist
	checkWhitelist: true,
};

i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		resources: {
			en: {
				translation: en,
			},
			fr: {
				translation: fr,
			},
		},

		nonExplicitSupportedLngs: true,
		fallbackLng: 'en',
		debug: false, //process.env.NODE_ENV === 'development',
		whitelist: ['en', 'fr', 'fr-FR', 'en-US'], // available languages for browser dector to pick from
		detection: langDetectorOptions,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	});

export default i18n;
