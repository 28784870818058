import styled from "styled-components";
import { devices } from "../../constants/mediaQueries";

export const ThanksMessage = styled.h1`
	font-size: 1.3em;
	font-weight: 700;
	color: rgba(0, 0, 0, 0.9);
	margin: 24px 0 0 0;
	text-align: center;

    @media ${devices.mobileS} {
        font-size: 1.4em;
    }

	@media ${devices.tablet} {
		font-size: 1.5em;
		align-self: flex-start;
	}
`;

export const OrderSummaryTitle = styled.h2`
    font-size: 1em;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
    margin: 0 0 18px 0;
    text-transform: uppercase;
    white-space: nowrap;
`;

export const SummaryPlaceholder = styled.span`
    color: rgba(0, 0, 0, 0.7);
    font-weight: 400;
    font-size: 0.9em;
    margin: 0;
`;

export const MailingMessage = styled.p`
    font-size: 0.9em;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
    margin: 12px 0;
    align-self: center;
    text-align: center;
`;

export const OrderSummarySpan = styled.span`
    font-size: 1em;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
    margin: 0;
`;