import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '../../../models/product';
import { IconButton } from '../../../styles/buttons';
import { CartIncrementButton } from '../../../styles/buttons/cart';
import { Container } from '../../../styles/containers';
import { CartIncrementWrapper } from '../../../styles/containers/cart';
import { QuantityInput } from '../../../styles/inputs/cart';
import { HTLabel, HTPrice, StyledSpan, Tag } from '../../../styles/texts';
import { useLanguage, useUserAuth } from '../../../utils/hooks';
import { useClientPricing } from '../../../utils/hooks/useClientPricing';
import { Icon } from '../../other';
import Symbol from '../../other/Symbol';
import { FullScreenImage } from '../../ProductDetails.tsx/FullScreenImage';
import { GlassesTileContainer, TileProductImage, TileProductImageWrapper } from './style';
import { useTheme } from 'styled-components';

interface GlassesTileProps {
	glasses: Product;
	quantity: number;
	onQuantityChange: (quantity: number) => void;
	isFavorite: boolean;
	onFavoriteClick: () => void;
}

const GlassesTile = ({ glasses, quantity, onQuantityChange, isFavorite, onFavoriteClick }: GlassesTileProps) => {
	const { user } = useUserAuth();
	const { t, i18n } = useTranslation();
	const { getTranslation } = useLanguage();
	const { getProductPrice } = useClientPricing();
	const language = i18n.language;
	const [showFullScreen, setShowFullScreen] = useState<boolean>(false);
	const theme = useTheme();

	const price = getProductPrice(glasses);
	const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
		let quantity = parseInt(e.target.value) || undefined;
		if (quantity) {
			if (quantity < 1) {
				quantity = 0;
			} else if (quantity > 99) {
				quantity = 99;
			}
		}
		onQuantityChange(quantity || 0);
	};

	const handleQuantityButtonClick = (add: boolean) => {
		if (quantity <= 0 && !add) return;
		if (quantity >= 99 && add) return;
		onQuantityChange(add ? quantity + 1 : quantity - 1);
	};

	const handleOpenClick = () => {
		// navigate(`/products/${glasses.id}`);
		if (glasses.images.length === 0) {
			return;
		}
		setShowFullScreen(true);
	};

	return (
		<>
			{showFullScreen && (
				<FullScreenImage
					images={glasses.images.map((image) => image.url)}
					defaultImage={0}
					onClose={() => {
						setShowFullScreen(false);
					}}
				/>
			)}
			<GlassesTileContainer key={`glasses-tile-${glasses.id}`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.3 }}>
				<TileProductImageWrapper>
					<TileProductImage $isZoomable={glasses.images.length > 0} src={glasses.defaultImage.url} alt={glasses.reference} onClick={handleOpenClick} />
				</TileProductImageWrapper>
				<Container direction='column' justify='space-between' flex='1 1 170px' padding='8px 0'>
					<Container direction='column' gap={8} flex='1'>
						<StyledSpan style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '1.2em', width: 'fit-content' }}>{glasses.name}</StyledSpan>

						<Container flexWrap='wrap' gap={8}>
							<Tag color={theme.mainColor}>{t(`products.collection.${glasses.collection}`, glasses.collection)}</Tag>

							<Tag color={theme.mainColor}>
								{glasses.size?.lens} □ {glasses.size?.bridge} - {glasses.size?.temple}
							</Tag>

							<Tag color={theme.mainColor}>{t(`products.glassesType.${glasses.frameType}`, glasses.frameType)}</Tag>

							<Tag color={theme.mainColor}>{t(`products.frameMaterial.${glasses.frameMaterial}`, glasses.frameMaterial)}</Tag>

							{glasses.frameType === 'sun' && (
								<>
									<Tag color={theme.mainColor}>{t(`products.lensColor.${glasses.lensColor}`, glasses.lensColor)}</Tag>
									{glasses.lensSpecificity.length > 0 &&
										glasses.lensSpecificity.map((specificity) => (
											<Tag key={specificity} color={theme.mainColor}>
												{t(`products.lensSpecificity.${specificity}`, specificity)}
											</Tag>
										))}
								</>
							)}

							<Tag color={theme.mainColor}>{getTranslation(glasses.colorName)}</Tag>

							<Tag color={theme.mainColor}>{t(`products.lensBase.${glasses.lensBase}`, glasses.lensBase)}</Tag>
						</Container>

						{price && (
							<Container align='baseline' gap={4} margin='8px 0 16px 0'>
								<HTPrice style={{ fontSize: '1.2em' }} onClick={handleOpenClick}>
									{t('global.price', {
										value: price.exclTax,
										formatParams: {
											value: {
												currency: price.currency,
												locale: language,
											},
										},
									})}
								</HTPrice>
								<HTLabel onClick={handleOpenClick}>{t('global.ht')}</HTLabel>
							</Container>
						)}
					</Container>

					{user && (
						<Container width='100%' justify='flex-start' align='center' gap={16}>
							<IconButton onClick={onFavoriteClick}>
								<Symbol color={theme.mainColor} size={42} fill={isFavorite} name='favorite' />
							</IconButton>
							<CartIncrementWrapper onClick={(e) => e.stopPropagation()}>
								<CartIncrementButton justify='start' onClick={() => handleQuantityButtonClick(false)} variant='text'>
									<Icon name='minus' color={theme.mainColor} size={26} />
								</CartIncrementButton>
								<QuantityInput type='text' value={quantity} onChange={handleQuantityChange} />
								<CartIncrementButton justify='end' onClick={() => handleQuantityButtonClick(true)} variant='text'>
									<Icon name='plus' color={theme.mainColor} size={26} />
								</CartIncrementButton>
							</CartIncrementWrapper>
							{/* <Container width='42px'></Container> */}
						</Container>
					)}
				</Container>
			</GlassesTileContainer>
		</>
	);
};

export default GlassesTile;
