import { useTranslation } from 'react-i18next';
import { OrderDTO } from '../../../../../models/order';
import { MainButtonLink } from '../../../../../styles/buttons';
import { Container } from '../../../../../styles/containers';
import { Muted, StyledSpan } from '../../../../../styles/texts';
import { MutedUpper } from '../../../../ProductDetails.tsx/style';

interface OrderRowProps {
	order: OrderDTO;
}

const OrderRow = ({ order }: OrderRowProps) => {
	const { t } = useTranslation();

	return (
		<Container justify='space-between' gap={32} width='100%'>
			<Container direction='column'>
				<StyledSpan style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '1em', width: 'fit-content', whiteSpace: 'nowrap' }}>{order.ident}</StyledSpan>
				<Container flexWrap='wrap' align='baseline'>
					<StyledSpan style={{ whiteSpace: 'nowrap', marginRight: '16px' }}>
						{t('orders.totalAmount', { amount: order.formatted_totalAmount })}
					</StyledSpan>
					<Muted style={{ whiteSpace: 'nowrap' }}>{t('orders.orderDate', { date: new Date(order.created).toLocaleDateString() })}</Muted>
				</Container>
				<MutedUpper style={{ marginTop: '8px' }}>{t('orders.receiver', { name: order.contactName })}</MutedUpper>
			</Container>
			<Container direction='column' flex='0 0 auto'>
				<MainButtonLink to={`/checkout/order/${order.id}`}>{t('orders.seeOrder')}</MainButtonLink>
			</Container>
		</Container>
	);
};

export default OrderRow;
