import { devices } from './../../constants/mediaQueries';
import { Box } from 'aaastyle';
import styled from 'styled-components';

export const ConfirmationBox = styled(Box)`
	height: fit-content;
	flex: 0 1 800px;
	padding: 8px 16px;
	flex-direction: column;
	margin: 0;
	border-radius: 0;

	@media ${devices.tablet} {
		border-radius: 12px;
		padding: 16px 36px;
	}
`;

export const ConfirmationBoxHeader = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
`;

export const SummaryContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
`;
