import { Button } from "aaastyle";
import styled from "styled-components";
import { MainButtonLink } from '../../styles/buttons';
import { RPPLabel, RPPPrice } from '../../styles/texts/index';
import { ProductImage } from "../Navbar/CartMenu/style";
import { devices } from './../../constants/mediaQueries';

export const StyledGlassesRow = styled.div`
   display: flex;
    flex-direction: row;
    box-sizing: border-box;
    gap: 8px;
    cursor: pointer;
	flex: 1 1 400px;
	max-height: 140px;
`;

export const CartIncrementWrapper = styled.div`
	display: flex;
	align-items: center;
	border-radius: 25px;
	background-color: rgba(0, 0, 0, 0.06);
	color: #f7c200;
	justify-self: flex-end;
	/* justify-content: center; */
	align-self: flex-end;
	padding: 0;
	font-size: 1em;
	overflow: hidden;
`;

export const CartIncrementButton = styled(Button)`
	padding: 12px;
	margin: 0;
	border-radius: 0;
`;

export const QuantityInput = styled.input`
	color: #F7C200;
	outline: none;
	border: none;
	width: 60px;
	text-align: center;
	font-size: 1em;
	font-weight: 500;
	background-color: transparent;
	text-decoration: none;
	padding: 0;
	text-overflow: ellipsis;

	&:-webkit-outer-spin-button, &:-webkit-inner-spin-button {
		margin: 0;
	}

	&[type=number]{
		-moz-appearance: textfield;
	}
`;

export const GlassesImage = styled(ProductImage)`
	height: 110px;
	flex: 0 0 110px;
	box-sizing: border-box;

	@media ${devices.tablet} {
		height: 140px;
		flex: 0 0 140px;
	}
`;

export const ProductTotalPrice = styled(RPPPrice)`
	font-size: 1.2em;
`;

export const ProductTotalLabel = styled(RPPLabel)`
	font-size: 0.875em;
`;

export const HeaderSpan = styled.span<{ flex?: string }>`
	font-size: 1em;
	font-weight: 500;
	color: ${({ theme }) => theme.blackColor};
	flex: ${({ flex }) => flex || '1'};
`;

export const RecapTitle = styled.h3`
	font-size: 1.4em;
`

export const SubTotalSpan = styled.span`
	font-size: 1em;
	font-weight: 400;
`;

export const TotalSpan = styled.span`
	font-size: 1.1em;
	font-weight: 600;
`;

export const TotalPrice = styled.span`
	font-size: 1.1em;
	font-weight: 600;
`;

export const TotalContainer = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: column;
	box-sizing: border-box;

	height: fit-content;
	align-self: flex-start;
	justify-self: flex-start;

	@media ${devices.tablet} {
		box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
    	border-radius: 8px;
		/* border-left: 2px solid ${({ theme }) => theme.mainColor}; */
		padding: 0 16px 16px 16px;
	}
`;

export const BorderBottom = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.lightGrayColor};
	margin: 8px 0;
`;

export const OrderButton = styled(MainButtonLink)`
	margin: 16px;
	text-transform: uppercase;
	letter-spacing: 0.4px;
	align-self: center;
	box-sizing: border-box;
`