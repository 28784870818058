import { useTranslation } from 'react-i18next';
import { COUNTRIES } from '../../constants/countries';
import { DialCodeCountry, DialCodeNumber, StyledDialCodeSelect } from '../../styles/inputs/register';
import { Container } from '../../styles/containers';
import ReactCountryFlag from 'react-country-flag';
import { OptionWithSearchProps } from './SelectWithSearch/SelectWithSearch';

interface DialCodeSelectProps {
	onChange: (value: string) => void;
	defaultValue?: string;
}

const DialCodeSelect = ({ onChange, defaultValue }: DialCodeSelectProps) => {
	const { t } = useTranslation();
	const options: OptionWithSearchProps[] = COUNTRIES.map((country) => {
		return {
			value: country.dialCode,
			label: (
				<Container width='100%' align='center' justify='space-between' gap={8}>
					<Container align='center' gap={8}>
						<ReactCountryFlag key={`dialCode-${country.code}`} countryCode={country.code} title='name' />
						<DialCodeCountry>{country.name}</DialCodeCountry>
					</Container>
					<DialCodeNumber>{country.dialCode}</DialCodeNumber>
				</Container>
			),
			searchLabel: country.name,
		};
	});

	return (
		<StyledDialCodeSelect
			onSelect={(option) => {
				onChange(option.value.toString());
			}}
			showPlaceholder={!defaultValue}
			defaultValue={defaultValue}
			placeholder={t('clientRegister.placeholders.dialCode')}
			options={options}
			search={{
				placeholder: 'Search',
			}}
		/>
	);
};

export default DialCodeSelect;
