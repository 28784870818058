import { FieldValidation } from '../models/fieldValidation';

type ClientField = {
    name: string,
    value: any,
}

export const validateAndFormatClientField = (field: ClientField): FieldValidation => {
    switch (field.name) {
        case 'name':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length > 0,
                message: 'required',
            };
        case 'legalStatus':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length > 0,
                message: 'required',
            };
        case 'ape':           
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length === 5,
                message: 'required',
            };
        case 'tradeType':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.toString().length > 0,
                message: 'required',
            };
        case 'company-buying-group-membership-number':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length > 0,
                message: 'required',
            };
        case 'creationDate':
            return {
                name: field.name,
                value: Date.parse(field.value),
                isValid: true,
            };

        case 'currency':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.toString().length > 0,
                message: 'required',
            };
        case 'language':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.toString().length > 0,
                message: 'required',
            };
        case 'siren':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length === 9,
                message: 'required',
            };
        case 'siret':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length > 0,
                message: 'required',
            };
        case 'company-national-id':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length > 0,
                message: 'required',
            };
        case 'company-sign':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length > 0,
                message: 'required',
            };


        case 'vat':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length > 2 && field.value.length < 16,
                message: 'required',
            };

        case 'email':
            if (field.value.length === 0) {
                return {
                    name: field.name,
                    value: field.value,
                    isValid: false,
                    message: 'required',
                }
            }
            return {
                name: field.name,
                value: field.value,
                // eslint-disable-next-line
                isValid: field.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
                message: 'required',
            };
        case 'invoicingAddress':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length > 0,
                message: 'required',
            };
        case 'invoicingCity':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length > 0,
                message: 'required',
            };
        case 'invoicingZip':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length > 0,
                message: 'required',
            };
        case 'shippingAddress':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length > 0,
                message: 'required',
            };
        case 'shippingCity':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length > 0,
                message: 'required',
            };
        case 'shippingZip':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length > 0,
                message: 'required',
            };
        case 'country':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length > 0,
                message: 'required',
            };
        case 'contactFirstName':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length > 0,
                message: 'required',
            };
        case 'contactLastName':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length > 0,
                message: 'required',
            };
        case 'contactEmail':
            if (field.value.length === 0) {
                return {
                    name: field.name,
                    value: field.value,
                    isValid: false,
                    message: 'required',
                }
            }
            return {
                name: field.name,
                value: field.value,
                // eslint-disable-next-line
                isValid: field.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
                message: 'invalidEmail',
            };
        case 'password':
            return {
                name: field.name,
                value: field.value,
                isValid: field.value.length > 6,
                message: 'required',
            }
        case 'contactHomePhone':
            return {
                name: field.name,
                value: field.value,
                // eslint-disable-next-line
                isValid: field.value.length === 0 || field.value.match(new RegExp('^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2,9}$')),
                message: 'invalid',
            };
        case 'contactMobilePhone':
            if (field.value.length === 0) {
                return {
                    name: field.name,
                    value: field.value,
                    isValid: false,
                    message: 'required',
                }
            }
            return {
                name: field.name,
                value: field.value,
                // eslint-disable-next-line
                isValid: field.value.match(new RegExp('^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2,9}$')),
                message: 'invalid',
            };

        default:            
            return {
                name: field.name,
                value: field.value,
                isValid: true,
                message: '',
            };
    }
}